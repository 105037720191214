import Cognito from './Services/Cognito';
import {bugsnagClient} from "../../services/bugSnag";

export const tokenIsValid = async () => {

    try{
        const cognito = new Cognito();
        return await cognito.checkCurrentSession();
    }catch(error){
        bugsnagClient.notify(error);
        return false;
    }
};
