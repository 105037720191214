import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReduxBlockUi from "react-block-ui/redux";
import "react-block-ui/style.css";
import { FormattedMessage } from "react-intl";

import { Base64 } from "js-base64";

//Lodash
import _head from "lodash/head";
import _has from "lodash/has";
import _get from "lodash/get";
import _map from "lodash/map";
import _find from "lodash/find";
import _split from "lodash/split";
import _startsWith from "lodash/startsWith";
//High Order Objects
import { connect } from "react-redux";
import withWidth from "material-ui/utils/withWidth";
import { withRouter } from "react-router";
import muiThemeable from "material-ui/styles/muiThemeable";

//Actions
import {
  APP_LOAD,
  REDIRECT,
  CART_ADD,
  CART_ADD_ERROR,
  CART_CLEAN,
  PERSONAL_SET_COUNTRY,
  PERSONAL_SET_COUNTRY_ERROR,
  PERSONAL_SET_CURRENCY,
  PERSONAL_UPDATE_INFO,
  CARD_UPDATE_INFO,
  CART_SHOULD_UPDATE_TOTALS,
  CART_CHECK_TYPE,
  VENDOR_ADD,
  UPDATE_STEP,
  CHANGE_STEP,
  CART_CAN_SUBMIT,
  CART_PAY,
  CART_PAID,
  CARD_ADD_ERROR,
  CART_PAY_ERROR,
  ALERT_OPEN,
  ALERT_CLOSE,
  CART_PAY_WAITING_ERROR,
  UPDATE_CHANNEL_ID,
  ADDRESS_OPTIONS_CLEAN,
  ADDRESS_AVAILABLE_OPTIONS_SET,
} from "../../actions/actionTypes";

//Error strings
import { API_VARIANTS_NOT_FOUND, API_VARIANTS_UNDEFINED, API_VARIANTS_NO_STOCK } from "../../lang/alerts";

//Services
import GetProduct from "../../services/getProduct";
import Currencies from "../../services/currencies";
import getV3EncodedJSON from "../../utils/getV3EncodedJSON";
import validateShippingOptions from "../../utils/validateShippingOptions";

//Containers
import PreviewSidebar from "../presentational/infoSidebar";
import Products from "../containers/products";
import Coupon from "../containers/coupons";
import Totals from "../presentational/totals";
import Shipping from "./shipping";
import Billing from "./billing";
import Payment from "../../Modules/payment/components/Payment";
import { AuthLayout } from "../../Modules/auth";

//Presentational components
import { Step, Stepper, StepLabel, StepContent, StepButton } from "material-ui/Stepper";
import RaisedButton from "material-ui/RaisedButton";
import Alert from "../presentational/elements/alert";
import ArrowForwardIcon from "material-ui/svg-icons/navigation/arrow-forward";
import { bugsnagClient } from "../../services/bugSnag";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, token) => dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
  onSetCountry: (payload) => dispatch({ type: PERSONAL_SET_COUNTRY, payload }),
  logCountryError: () => dispatch({ type: PERSONAL_SET_COUNTRY_ERROR }),
  logProductError: (product) => dispatch({ type: CART_ADD_ERROR, product: product }),
  onSetCurrency: (currency_code, forced = true) => dispatch({ type: PERSONAL_SET_CURRENCY, currency_code, forced }),
  onUpdateVendorInfo: (payload) => dispatch({ type: VENDOR_ADD, payload }),
  onUpdatePersonalInfo: (key, value) => dispatch({ type: PERSONAL_UPDATE_INFO, key, value }),
  onUpdateCardInfo: (key, value) => dispatch({ type: CARD_UPDATE_INFO, key, value }),
  onCartAdd: (data) => dispatch({ type: CART_ADD, id: data.id, payload: data }),
  onCartSetTotals: () => dispatch({ type: CART_SHOULD_UPDATE_TOTALS }),
  onUpdateStep: (key, value) => dispatch({ type: UPDATE_STEP, key, value }),
  onChangeStep: (payload) => dispatch({ type: CHANGE_STEP, payload }),
  onOpenAlert: (value) => dispatch({ type: ALERT_OPEN, message: value }),
  onCloseAlert: () => dispatch({ type: ALERT_CLOSE }),
  onCartCanSubmit: () => dispatch({ type: CART_CAN_SUBMIT }),
  onCheckCartType: () => dispatch({ type: CART_CHECK_TYPE }),
  onClearCart: () => dispatch({ type: CART_CLEAN }),
  updateChannelId: (channelId) => dispatch({ type: UPDATE_CHANNEL_ID, channelId }),
  updateShippingOptions: (shippingZones, state) => {
    const shippingCountry = _find(shippingZones.countries, (o) => o.code == state.personal.country);
    const validOptions = shippingCountry ? validateShippingOptions(shippingCountry, shippingZones.options, state) : [];
    dispatch({ type: ADDRESS_OPTIONS_CLEAN });
    dispatch({
      type: ADDRESS_AVAILABLE_OPTIONS_SET,
      payload: {
        fetchedShippingZones: shippingZones,
        availableOptions: validOptions,
      },
    });
  },
});

class CartManager extends Component {
  componentWillReceiveProps() {
    if (this.props.card.token && this.props.common.canSubmit === false) {
      this.props.onCartCanSubmit();
    }
  }

  componentDidMount() {
    //Set country and currency
    this.props.onClearCart();

    let checkout = null;

    if (this.props.match.params.hasOwnProperty("id")) {
      const items = [
        {
          id: this.props.match.params.id,
          qty: 1,
          type: "variant",
        },
      ];
      checkout = this.buildCheckout(items);
    }

    if (this.props.match.params.hasOwnProperty("bundleId")) {
      const items = [
        {
          id: this.props.match.params.bundleId,
          qty: 1,
          variants: _split(this.props.match.params.variantIds, "-"),
          type: "bundle",
        },
      ];
      checkout = this.buildCheckout(items);
    }

    if (this.props.match.params.hasOwnProperty("cartIds")) {
      const checkoutString = this.props.match.params.cartIds.match(/^[0-9a-zA-Z+\/]+[=]{0,3}$/)
        ? Base64.decode(this.props.match.params.cartIds)
        : this.props.match.params.cartIds;

      const ids = _split(decodeURIComponent(checkoutString), "|");
      const items = _map(ids, (value) => {
        const params = _split(value, ":");
        const qty = params.length === 2 ? params[1] : 1;
        const components = _split(params[0], "=");
        switch (components.length) {
          case 1:
            return {
              id: components[0],
              qty: qty,
              type: "variant",
            };
          case 2:
            return {
              id: components[0],
              qty: qty,
              variants: _split(components[1], "-"),
              type: "bundle",
            };
        }
      });
      checkout = this.buildCheckout(items);
    }

    if (null === checkout) {
      checkout = getV3EncodedJSON();
    }

    GetProduct.requestCheckout(checkout)
      .then((data) => {
        const country = data.country;
        this.props.onSetCountry(country);

        if (!data.currency) {
          throw "The Seller currency configuration is not valid";
        }

        const currency = data.currency;

        this.props.onSetCurrency(currency, true);

        data.items.forEach((cartItem) => {
          this.props.onCartAdd(cartItem);
        });
        this.props.onCartSetTotals();
        this.props.updateChannelId(data.channelId);
        this.props.onUpdateVendorInfo(data.seller);
        this.props.updateShippingOptions(data.shippingZones, this.props);

        try {
          if (window.tap) {
            let tapfiliateCode = _get(data, "seller.tapfiliateId", null);

            if (tapfiliateCode) {
              // tapfiliate
              window.tap("create", tapfiliateCode, { integration: "javascript" });
              window.tap("detect");
            }
          }
        } catch (error) {
          bugsnagClient.notify(error, { context: "Tapfiliate error" });
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
        this.handleProductErrors(error);
      });

    this.props.onCheckCartType();
  }

  buildCheckout = (items) => {
    try {
      const currency = new URL(window.location).searchParams.get("currency") || null;
      const vendorUUID = new URL(window.location).searchParams.get("vld") || null;

      const transformedCartJSON = _map(items, (item) => {
        if (item.type === "bundle") {
          return {
            i: item.id,
            q: item.qty,
            b: item.variants,
          };
        } else {
          return {
            i: item.id,
            q: item.qty,
            b: null,
          };
        }
      });

      return Base64.encode(
        JSON.stringify({
          v: vendorUUID,
          c: currency,
          l: null,
          it: transformedCartJSON,
        })
      );
    } catch (error) {
      console.log("Checkout URL Error");
      bugsnagClient.notify(error);
      console.log(error);

      // Clearing cart of old schema items or any of the sort causing problems
    }
  };

  getStyles = () => this.props.muiTheme;

  handleStep = (key, value) => {
    this.props.onUpdateStep(key, value);
  };

  handleNextStep = () => {
    if (this.props.common.stepIndex < 2) {
      const newStep = this.props.common.stepIndex + 1;
      this.props.onChangeStep(newStep);
    }
  };

  handlePrevStep = () => {
    if (this.props.common.stepIndex > 1) {
      const newStep = this.props.common.stepIndex - 1;
      this.props.onChangeStep(newStep);
    }
  };

  handleChangeStep = (step) => {
    this.props.onChangeStep(step);
  };

  handleCloseAlert = () => {
    this.props.onCloseAlert();
  };

  handleProductErrors = (error) => {
    if (_has(error, "[0]")) {
      const message = error.map((e) => {
        switch (e.code) {
          case "ProductNotFound":
            return `${API_VARIANTS_NOT_FOUND}\n`;
          case "NoStockProduct":
            return `${e.message}\n`;
          default:
            bugsnagClient.notify(new Error(e.message));
            return `${e.message}\n`;
        }
      });

      this.props.onOpenAlert(message);
      this.props.logProductError(error.response);
    } else if (error.request) {
      this.props.onOpenAlert(API_VARIANTS_UNDEFINED);
      this.props.logProductError("Undefined error adding a product");
    } else {
      // Something happened in setting up the request that triggered an Error
      this.props.onOpenAlert(API_VARIANTS_UNDEFINED);
      this.props.logProductError("Undefined error adding a product");
    }
  };

  isDisabled(step) {
    const { isDigital, stepReady, needs_billing } = this.props.common;
    const needsTaxes = _get(this.props.vendor.taxes, "module", false);
    switch (step) {
      case 1: //Shipping
        return !stepReady.personal;
      case 2: //Payment
        return !(
          stepReady.personal &&
          ((!isDigital && stepReady.shippingAddress && stepReady.shippingOptions) ||
            (isDigital && stepReady.billingAddress)) &&
          (!needs_billing || (needs_billing && stepReady.billingAddress)) &&
          (!needsTaxes || (needsTaxes && stepReady.taxTotal))
        );
      default:
        return true;
    }
  }

  getStepContent(stepIndex) {
    const { stepReady } = this.props.common;
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <AuthLayout />
            {stepReady.personal && (
              <RaisedButton
                label={<FormattedMessage id="checkout.next" defaultMessage="Next" />}
                primary
                onClick={this.handleNextStep}
                className="nextBtn spacing"
              />
            )}
          </div>
        );
      case 1:
        return (
          <div>
            {!this.props.common.isDigital && <Shipping />}
            <Billing />
            <RaisedButton
              label={<FormattedMessage id="checkout.next" defaultMessage="Next" />}
              primary
              disabled={this.isDisabled(2)}
              onClick={this.handleNextStep}
              className="nextBtn spacing"
            />
          </div>
        );
      case 2:
        return (
          <div>
            <div className="clear">
              <br />
              <p className="subtitle">
                <FormattedMessage id="checkout.reviewYourOrder" defaultMessage="Review your order & Make Payment" />
              </p>
              <Totals {...this.props.cart.totals} currency={this.props.personal.currency_code} />
            </div>
            <Payment />
            <div className="termsLine">
              <small className="termsText">
                <FormattedMessage id="checkout.termsAndServices" defaultMessage="By purchasing you agree to our" />
                <a
                  href={_get(this.props, "vendor.routes.termsAndConditions", "/terms")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="checkout.termsLink" defaultMessage=" terms and conditions." />
                </a>
              </small>
            </div>
          </div>
        );
      default:
        return false;
    }
  }

  getStepper() {
    const { stepIndex, visited, stepReady } = this.props.common;
    const styles = this.getStyles();
    return (
      <Stepper
        linear={false}
        orientation="horizontal"
        style={styles.stepper}
        className="stepperr"
        connector={<ArrowForwardIcon />}
      >
        <Step completed={stepReady.personal === true} active={stepIndex === 0}>
          <StepButton onClick={() => this.handleChangeStep(0)}>
            <FormattedMessage id="checkout.signupOrLogin" defaultMessage="Signup or Login" />
          </StepButton>
        </Step>
        <Step completed={!this.isDisabled(2)} active={stepIndex === 1}>
          <StepButton onClick={() => this.handleChangeStep(1)} disabled={this.isDisabled(1)}>
            <FormattedMessage
              id="checkout.billingAddress"
              defaultMessage={this.props.common.isDigital ? "Billing address" : "Address and Shipping"}
            />
          </StepButton>
        </Step>
        <Step completed={visited.indexOf(2) !== -1} active={stepIndex === 2}>
          <StepButton onClick={() => this.handleChangeStep(2)} disabled={this.isDisabled(2)}>
            <FormattedMessage id="checkout.reviewYourOrder" defaultMessage="Review your order & Make Payment" />
          </StepButton>
        </Step>
      </Stepper>
    );
  }

  getMobileStepper() {
    const { stepIndex, visited, stepReady } = this.props.common;
    return (
      <Stepper orientation="vertical" connector={null}>
        <Step completed={stepReady.personal === true} active={stepIndex === 0}>
          <StepButton onClick={() => this.handleChangeStep(0)}>
            <StepLabel className="step_label">
              <FormattedMessage id="checkout.signupOrLogin" defaultMessage="Signup or Login" />
            </StepLabel>
          </StepButton>
          <StepContent className="step_content">{this.getStepContent(0)}</StepContent>
        </Step>
        <Step completed={!this.isDisabled(2)} active={stepIndex === 1}>
          <StepButton onClick={() => this.handleChangeStep(1)} disabled={this.isDisabled(1)}>
            <StepLabel className="step_label">
              <FormattedMessage
                id="checkout.billingAddress"
                defaultMessage={this.props.common.isDigital ? "Billing address" : "Address and Shipping"}
              />
            </StepLabel>
          </StepButton>
          <StepContent className="step_content">{this.getStepContent(1)}</StepContent>
        </Step>
        <Step completed={visited.indexOf(2) !== -1} active={stepIndex === 2}>
          <StepButton onClick={() => this.handleChangeStep(2)} disabled={this.isDisabled(2)}>
            <StepLabel className="step_label">
              <FormattedMessage id="checkout.reviewYourOrder" defaultMessage="Review your order & Make Payment" />
            </StepLabel>
          </StepButton>
          <StepContent className="step_content">{this.getStepContent(2)}</StepContent>
        </Step>
      </Stepper>
    );
  }

  render() {
    const { stepIndex } = this.props.common;
    const styles = this.getStyles();

    if (this.props.common.submitted) {
      console.log("you should be redirected now");
      let thankYouPage = _get(this.props, "vendor.routes.thankYou", null);

      if (_startsWith(thankYouPage, "https://")) {
        window.location = thankYouPage.replace("{{channelId}}", this.props.common.channelId);
        return <div />;
      } else {
        return (
          <Redirect
            push
            to={{
              pathname: "/thank-you",
            }}
          />
        );
      }
    }

    switch (this.props.width) {
      case 1: //Small
      case 2: //Medium
        return (
          <div className="wrapper">
            <div
              className="topBanner"
              {...(_has(this.props, "vendor.theme.primaryColour") && {
                style: {
                  background: this.props.vendor.theme.primaryColour,
                },
              })}
            >
              <img src={this.props.vendor.logo} className="vendor-logo" />
              <h1>Checkout</h1>
              {_get(this.props, "vendor.cognitoId") !== "8e4ca2bd-9a60-4308-bd69-1665e5009727" && (
                <img src="https://assets.aflete.com/checkout/powered.png" alt="" className="powered-by-genflow-logo" />
              )}
            </div>
            <div className="mobile-container">
              <h3 className="thin-h3">Order Summary</h3>
              <hr />
              <Products />
              <Coupon />
              <Totals {...this.props.cart.totals} currency={this.props.personal.currency_code} />
              <hr />
              <p className="mobile-heading">Complete Your Order</p>

              <div className="mainMobile">
                <ReduxBlockUi
                  tag="div"
                  block={CART_PAY}
                  unblock={[CART_PAID, CARD_ADD_ERROR, CART_PAY_ERROR, CART_PAY_WAITING_ERROR, /fail/i]}
                  message="Your order is being processed, please do not refresh (It may take some time)"
                >
                  {this.getMobileStepper()}
                </ReduxBlockUi>
                <Alert
                  content={this.props.common.alertMessage}
                  handleClose={this.handleCloseAlert}
                  openForm={this.props.common.alertOpen}
                />
              </div>
            </div>
          </div>
        );
      case 3: //Large
        return (
          <div className="wrapper">
            <div
              className="topBanner"
              {...(_has(this.props, "vendor.theme.primaryColour") && {
                style: { background: this.props.vendor.theme.primaryColour },
              })}
            >
              <img src={this.props.vendor.logo} className="vendor-logo" />
              <h1>Checkout</h1>
              {_get(this.props, "vendor.cognitoId") !== "8e4ca2bd-9a60-4308-bd69-1665e5009727" && (
                <img src="https://assets.aflete.com/checkout/powered.png" alt="" className="powered-by-genflow-logo" />
              )}
            </div>
            <div className="mainDesktop">
              <ReduxBlockUi
                tag="div"
                block={CART_PAY}
                unblock={[CART_PAID, CARD_ADD_ERROR, CART_PAY_ERROR, /fail/i]}
                message="Your order is being processed, please do not refresh (It may take some time)"
              >
                <div className="mainDesktop__container">
                  {this.getStepper()}
                  <div style={styles.content}>
                    <div>{this.getStepContent(stepIndex)}</div>
                  </div>
                </div>
              </ReduxBlockUi>
              <Alert
                content={this.props.common.alertMessage}
                handleClose={this.handleCloseAlert}
                openForm={this.props.common.alertOpen}
              />
            </div>
            <PreviewSidebar style={styles.preview} {...this.props.cart} currency={this.props.personal.currency_code} />
          </div>
        );
      default:
        throw new Error("Unknown width");
    }
  }
}

export default withRouter(muiThemeable()(connect(mapStateToProps, mapDispatchToProps)(withWidth()(CartManager))));
