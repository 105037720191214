import {
    CART_CLEAN,
    VENDOR_ADD
} from '../actions/actionTypes';

const defaultState = {};

export default (state = defaultState,  action = {}) => {
    switch (action.type) {
        case VENDOR_ADD:
            return { ...state, ...action.payload };
        case CART_CLEAN:
        {
            return {
                ...state,
                ...defaultState,
            };
        }
        default:
            return state;
    }
};
