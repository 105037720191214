import React from 'react';
import {FormattedMessage} from 'react-intl';

const KosmoFitMethodThankYou = ({styles}) => (
    <div className="thank-you thank-you-grace">{``}
        <div className="mainDesktop__container">
            <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png"
                 alt=""/>
            <h1>謝謝您的購買！</h1>
            <p><strong>您已完成付款程序
                您將會收到確認信，信件裡將提供所有的相關資訊。如果訂單是電子書的話將會附上電子書的下載連結。
            </strong></p>
            <p>若未收到信，請記得確認您的垃圾信件夾</p>
            <br/>
            <p><strong>運動指南，課表和計畫</strong></p>
            <p>購買後請到APP商店下載AFLETE APP，並以您剛剛已經註冊好的帳號登入APP即可取得完整的運動課表</p>
            <p className="download-btn">
                <a href="https://ad.apps.fm/C_3Cp5A6vOTfPzkCqbV8BfE7og6fuV2oOMeOQdRqrE2SqQj2mi01hG-wmUMDFadTiZifbdzPixPWLIc5IjGwLEkmlJMaGSIl3pdXa_4waD0">
                    <img src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/apple-badge.png"
                         alt=""/></a>
                <a href="https://ad.apps.fm/wgTNScPYjOB6UfctBtbeK15KLoEjTszcQMJsV6-2VnHFDLXitVHB6BlL95nuoNYf-XtGAYT-wjXD3OMs344Pbz4e4LysVtcrAMDcjw5TZ40EmEHTgDKO8HFEabO5ZwtT">
                    <img src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/android-badge.png" alt=""/></a>
            </p>

            <p className="problem-line">
                <FormattedMessage
                    id="checkout.needHelp"
                    defaultMessage="Need Help? Email at "
                />
                <a href="mailto:kosmofitmethod@gmail.com">
                    <FormattedMessage
                        id="checkout.needHelpEmail"
                        defaultMessage="kosmofitmethod@gmail.com"
                    />
                </a>
            </p>

            <p className="genflow-badge"><img
                src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/powered.png" alt=""/></p>
        </div>
    </div>
);

export default KosmoFitMethodThankYou;
