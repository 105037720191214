import React, {Component} from 'react';
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField';
import {FormattedMessage} from "react-intl";

class ShippingNameForm extends Component {
    render() {
        return (
            <div>
                <TextField
                    name="first_name"
                    ref="first_name"
                    id="shipping_first_name"
                    defaultValue={this.props.first_name}
                    hintText={
                        <FormattedMessage
                            id="checkout.firstName"
                            defaultMessage="First name"
                        />
                    }
                    errorText={this.props.errors.first_name}
                    onChange={this.props.updateField}
                />
                <TextField
                    name="last_name"
                    ref="last_name"
                    id="shipping_last_name"
                    defaultValue={this.props.last_name}
                    hintText={
                        <FormattedMessage
                            id="checkout.lastName"
                            defaultMessage="Last name"
                        />
                    }
                    errorText={this.props.errors.last_name}
                    onChange={this.props.updateField}
                />
            </div>
        )
    }
}

ShippingNameForm.propTypes = {
    updateField: PropTypes.func.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    errors: PropTypes.object
};

export default ShippingNameForm
