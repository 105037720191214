import React from 'react'
import PropTypes from 'prop-types'

const ProductImage = ({image}) => (
    <div className="product__img" >
        <div className="img" style={{backgroundImage: "url(" + image + ")"}}>

        </div>
    </div>
);

ProductImage.propTypes = {
    image: PropTypes.string.isRequired
};

export default ProductImage