import _get from 'lodash/get';

import {BRAINTREE_PAYPAL, STRIPE_CARD, STRIPE_PAYMENT_REQUEST, STRIPE_INTENT} from "./constants/paymentTypes";

export const getToken = (card) => {

    const paymentType = _get(card, 'paymentType', 'none');

    const token = {
        [BRAINTREE_PAYPAL]: 'token.nonce',
        [STRIPE_CARD]: 'token.id',
        [STRIPE_PAYMENT_REQUEST]: 'token.id',
        [STRIPE_INTENT]: 'token',
        'none': () => { return 'none'; }
    };

    return {
        token: _get(card, token[paymentType], ''),
        type: paymentType
    }
};
