import {
    CART_ADD,
    CART_UPDATE,
    CART_QTY_UPDATE,
    CART_REMOVE,
    CART_CLEAN,
    CART_SET_TOTALS,
} from '../actions/actionTypes';

import version from '../AppVersion';

import _uniq from 'lodash/uniq';
import _get from 'lodash/get';
import {types as AuthTypes} from "../Modules/auth";

const defaultState = {
        byId: [],
        byHash: {},
        qtyByHash: {},
        version: version,
        totals: {
            weight: 0,
            subtotal: 0,
            discount: 0,
            shipping: 0,
            bundle: 0,
            tax: null,
            showTax: false,
            total: 0
        }
    };

export default (state = defaultState,  action = {}) => {
    switch (action.type) {
        case CART_ADD:
            let newState = {
                ...state,
                byId: _uniq([ ...state.byId, action.id]),
                byHash: {
                    ...state.byHash,
                    [action.id]: action.payload
                }
            };

            if (!state.byId.includes(action.id))
                newState = {
                    ...newState,
                    qtyByHash: {
                        ...state.qtyByHash,
                        [action.id]: _get(action, 'payload.qty', 1)
                    }
                };

            return newState;
        case CART_UPDATE: {
            return { ...state, byHash: {...state.byHash, [action.id]: {...state.byHash[action.id], [action.key]: action.value }}}
        }
        case CART_QTY_UPDATE: {
            return { ...state, qtyByHash: {...state.qtyByHash, [action.id]: action.value }}
        }
        case CART_REMOVE: {
            const prunedIds = state.byId.filter(item => {
                return item !== action.id // return all the items not matching the action.id
            });
            delete state.byHash[action.id]; // delete the hash associated with the action.id
            delete state.qtyByHash[action.id]; // delete the qty associated with the action.id

            return {
                ...state,
                byId: prunedIds,
                byHash: state.byHash
            }
        }
        case CART_CLEAN: {
            return {
                ...state,
                ...defaultState
            }
        }
        case CART_SET_TOTALS:
            return {
                ...state,
                totals: action.payload
            };
        default:
            return state;
    }
};
