import React, {Component, Fragment} from 'react';
import { bugsnagClient } from "../../services/bugSnag";

//High Order Objects
import {connect} from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';

//Lodash
import _conformsTo from 'lodash/conformsTo';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _every from 'lodash/every';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _has from 'lodash/has';

//Components
import {List} from 'material-ui/List';
import Product from '../presentational/product';

//Actions
import {
    CART_REMOVE,
    CART_SHOULD_UPDATE_TOTALS,
    CART_QTY_UPDATE,
    PERSONAL_SET_CURRENCY
} from '../../actions/actionTypes';

const mapStateToProps = state => {
    return {
        cart: state.cart,
        currency: state.personal.currency_code,
        type: state.common.isDigital,
        bundle: state.bundles.selectedBundle,
    }
};

const mapDispatchToProps = dispatch => ({
    onCartRemove: data => {
        dispatch({type: CART_REMOVE, id: data});
        dispatch({type: CART_SHOULD_UPDATE_TOTALS});
    },
    forceChangeCurrency: () => {
        dispatch({type: PERSONAL_SET_CURRENCY, currency_code: 'GBP', forced: true});
    },
    onProductQuantityChange: (id, value) => {
        dispatch({type: CART_QTY_UPDATE, id: id, value: value});
        dispatch({type: CART_SHOULD_UPDATE_TOTALS});
    }
});

class Products extends Component {
    constructor(props, context) {
        super(props, context);
        this.getStyles = () => {
            return this.props.muiTheme.product;
        };

        //this.enableButton = this.enableButton.bind(this);
        //this.disableButton = this.disableButton.bind(this);
        //this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        //Move or remove this. it should be in validator if it's not there yet
        nextProps.cart.byId.forEach((item) => {
            if (_has(nextProps.cart.byHash[item], 'content')) {
                const content = nextProps.cart.byHash[item].content;
                const isValid = _conformsTo(content, {
                    'name': (v) => !_isEmpty(v),
                    'image': (v) => !_isEmpty(v),
                    'currencies': (v) => _isArray(v),
                    'version': (v) => v >= nextProps.cart.version //If product was loaded in a previous version if the cart, we remove it
                });
                if (!isValid) {
                    this.handleRemove(item);
                }
            } else {
                this.handleRemove(item);
                bugsnagClient.notify(new Error('Content failed to load'), { severity: 'info' });
            }
        })
    }

    shouldComponentUpdate(props, state) {
        const supportCurrency = _every(props.cart.byHash, (o) => {
            let CurrencyIndex = _findIndex(o.content.currencies, ['currency_code', props.currency]);
            return CurrencyIndex >= 0;
        });
        if (!supportCurrency) {
            return false;
        }
        return true;
    }

    getPrice = (product, qty) => {
        let CurrencyIndex = _findIndex(product.content.currencies, ['currency_code', this.props.currency]);
        return (product.content.currencies[CurrencyIndex].price / 100) * qty;
    };

    handleQtyChange = (id, value) => {
        this.props.onProductQuantityChange(id, value)
    };

    handleRemove = item => this.props.onCartRemove(item);

    products = (cart) => {
        const styles = this.getStyles();

        return cart.byId.map((item, key) => {
            const product = cart.byHash[item];
            const qty = _get(cart, 'qtyByHash['+ item +']', 1);
            return (product &&
                <Product
                    key={item}
                    {...product.content}
                    onRemove={() => this.handleRemove(item)}
                    onQtyChange={(event, index, value) => this.handleQtyChange(item, value)}
                    price={this.getPrice(product, qty)}
                    qty={qty}
                    currency={this.props.currency}
                    styles={styles}
                />
            )
        })
    };

    render() {
        return <Fragment>
            <List>
            {this.products(this.props.cart)}
            </List>
            {(!this.props.cart.byId.length) &&
            <p>It appears that your cart is currently empty!</p>
            }
        </Fragment>
    }
}

export default muiThemeable()(connect(mapStateToProps, mapDispatchToProps)(Products));
