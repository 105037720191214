export const STRIPE_CARD = 'stripe_card';
export const STRIPE_APPLE = 'stripe_apple';
export const STRIPE_ANDROID = 'stripe_android';
export const STRIPE_PAYMENT_REQUEST = 'stripe_payment_request';
export const STRIPE_INTENT = 'stripe_intent';
export const BRAINTREE_PAYPAL = 'braintree_paypal';

export default {
    STRIPE_CARD,
    STRIPE_APPLE,
    STRIPE_ANDROID,
    STRIPE_PAYMENT_REQUEST,
    BRAINTREE_PAYPAL,
}
