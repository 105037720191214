
import _concat from 'lodash/concat';
import _uniq from 'lodash/uniq';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import {bugsnagClient} from "./bugSnag";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        bugsnagClient.notify(error, { context: "Error loading localstorage" });
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (error) {
        bugsnagClient.notify(error, { context: "Error saving local state" });
        // Ignore write errors.
    }
};

export default class localStorageService {
    static getProducts() {
        let products = localStorage.getItem('products');
        return products ? JSON.parse(localStorage.products) : null;
    }

    static storeProduct(product) {
        let products = this.getProducts();
        let data = _concat(products, product);
        localStorage.setItem('products', JSON.stringify(data))
    }

    static getProductStore() {
        let products = this.getProducts();

        let ids = _uniq(_map(products, 'id'));

        function listByHash(memo, value) {
            memo[value.id] = value;
            return memo;
        }

        let hashes = _reduce(products, listByHash, {});
        return {
            byId: ids,
            byHash: hashes
        };
    }

    static getDirectPurchase() {
        const directPurchase = localStorage.getItem('directPurchase');
        return directPurchase ? JSON.parse(localStorage.directPurchase) : null
    }

    static getCurrency() {
        const currency = localStorage.getItem('currency');
        return currency ? currency : null
    }

    static getCountry() {
        const country = localStorage.getItem('country');
        return country ? country : null
    }
}
