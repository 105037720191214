import { types as AuthTypes } from '../Modules/auth';
import { UPDATE_STEP, CHANGE_STEP, CART_SHOULD_UPDATE_TOTALS } from '../actions/actionTypes';

const stepsMiddleware = store => next => (action) => {
    next(action);

    if (
        action.type === AuthTypes.LOGGED
        || action.type === AuthTypes.ALREADY_LOGGED_IN
    ) {
        store.dispatch({ type: UPDATE_STEP, key: 'personal', value: true });
    }

    if ( action.type === AuthTypes.LOGGED ) {
        const state = store.getState();
        const newStep = state.common.stepIndex + 1;
        store.dispatch({ type: CHANGE_STEP, payload: newStep });
    }

    if ( action.type === AuthTypes.LOGOUT ) {
        store.dispatch({ type: UPDATE_STEP, key: 'personal', value: false });
    }

    if ( action.type === UPDATE_STEP
        &&
        !(action.key === 'taxTotal')
    ){

        store.dispatch({type: CART_SHOULD_UPDATE_TOTALS})
    }
};

export default stepsMiddleware;
