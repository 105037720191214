
import Cognito from './Services/Cognito';
import reducer from './reducer';
import middleware from './middleware';
import types from './types';

import AuthLayout from './components/layout';

export {
    AuthLayout,
    types,
};

export default () => {
    const auth = new Cognito();

    return ({
        reducer,
        middleware: middleware({auth}),
    });
}
