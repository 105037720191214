import {
    CART_ADD,
} from '../actions/actionTypes';

const cartVersionMiddleware = store => next => action => {

    if (action.type === CART_ADD) {
        action.payload.content.version = store.getState().cart.version;
    }


    next(action);
};

export default cartVersionMiddleware