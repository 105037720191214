import ReactGA from 'react-ga';

import { CHANGE_STEP, CART_ADD, CART_REMOVE, CART_PAY, CART_PAID, COUPON_ADD, CART_NEEDS_BILLING } from "../actions/actionTypes";

const stepsMiddleware = store => next => action => {

    next(action);

    let gaState = store.getState();
    // if product exists - return name
    let productName = gaState.cart.byHash['1'] ? gaState.cart.byHash['1'].content.name : null;
    let stage = gaState.common.stepIndex + 1;
    let totalPrice = gaState.cart.totals.subtotal;
    let stageDesc = {
        1: "Signup / Login",
        2: "Billing Address",
        3: "Payment"
    }

    // send ga-analytics events data as actions are dispatched
    switch(action.type) {
        // case LOGIN_USERNAME_PASSWORD:
        //     ReactGA.event({
        //         category: 'User login',
        //         action: ``
        //     });
        //     break;
        // case SIGNUP:
        //     ReactGA.event({
        //         category: 'User signed up',
        //         action: ``
        //     });
        //     break;
        // case CHANGE_STEP:
        //     ReactGA.event({
        //         category: `Stage ${stage} - ${stageDesc[stage]}`,
        //         action: `Moved to stage ${stage}`
        //     });
        //     break;
        // case CART_ADD:
        //     ReactGA.event({
        //         category: 'Product Added',
        //         action: `Added ${productName}`
        //     });
        //     break;
        // case CART_REMOVE:
        //     ReactGA.event({
        //         category: 'Product Removed',
        //         action: 'Removed Product'
        //     });
        //     break;
        case CART_NEEDS_BILLING:
            let address_obj = gaState.cart.byHash
            let address_items_in_cart = []
            for (const property in address_obj) {
                let address_itemId = address_obj[property].product_id;
                let address_item_name = address_obj[property].content.name.split(" ").join("")
                let address_is_digital = address_obj[property].content.is_digital
                let address_price = address_obj[property].content.currencies[0].price
                let address_quantity = address_obj[property].content.qty
                let address_full_item = {id: address_itemId, name: address_item_name, digital: address_is_digital, price: address_price, quantity: address_quantity}
                address_items_in_cart.push(address_full_item)
            }
            ReactGA.event({
                category: 'Shop-Checkout',
                action: `Checkout Address input`,
                value: gaState.cart.totals.total,
                currency: gaState.personal.currency_code,
                contents: address_items_in_cart
            });
            break;
        case CART_PAY:
            ReactGA.event({
                category: 'Pay',
                action: `User clicked pay for ${productName}`
            });
            break;
        case CART_PAID:
            let obj = gaState.cart.byHash
            let items_in_cart = []
            for (const property in obj) {
                let itemId = obj[property].product_id;
                let item_name = obj[property].content.name.split(" ").join("")
                let is_digital = obj[property].content.is_digital
                let price = obj[property].content.currencies[0].price
                let quantity = obj[property].content.qty
                let full_item = {id: itemId, name: item_name, digital: is_digital, price: price, quantity: quantity}
                items_in_cart.push(full_item)
            }
            ReactGA.event({
                category: 'Shop-Checkout',
                action: `Order Completed`,
                value: gaState.cart.totals.total,
                currency: gaState.personal.currency_code,
                contents: items_in_cart
            });
            break;
        case COUPON_ADD:
            ReactGA.event({
                category: 'Added Coupon',
                action: `User added coupon`
            });
            break;
        case CHANGE_STEP:

            //ReactGA.pageview(window.location.pathname + window.location.search);
            if (stageDesc[stage] == "Billing Address") {
                let address_obj = gaState.cart.byHash
                let address_items_in_cart = []
                for (const property in address_obj) {
                    let address_itemId = address_obj[property].product_id;
                    let address_item_name = address_obj[property].content.name.split(" ").join("")
                    let address_is_digital = address_obj[property].content.is_digital
                    let address_price = address_obj[property].content.currencies[0].price
                    let address_quantity = address_obj[property].content.qty
                    let address_full_item = {id: address_itemId, name: address_item_name, digital: address_is_digital, price: address_price, quantity: address_quantity}
                    address_items_in_cart.push(address_full_item)
                }
                ReactGA.event({
                    category: 'Shop-Checkout',
                    action: `Checkout User Registered`,
                    value: gaState.cart.totals.total,
                    currency: gaState.personal.currency_code,
                    contents: address_items_in_cart
                });
            }
            break;
        default:
            return;
    }
};

export default stepsMiddleware
