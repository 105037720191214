import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';

import {STRIPE_INTENT} from "../constants/paymentTypes";

//Lodash
import _assign from 'lodash/assign';
import _get from 'lodash/get';

import {
    CardElement,
    injectStripe,
} from 'react-stripe-elements';

import RaisedButton from 'material-ui/RaisedButton';
import muiThemeable from "material-ui/styles/muiThemeable";

class _StripeCardForm extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            disabled: this.props.isPaying
        };

        this.getStyles = () => {
            return {
                'buyButton': this.props.muiTheme.buyButton,
                'buyButtonInside': this.props.muiTheme.buyButtonInside,
            }
        };
    }

    componentDidUpdate(prevProps) {


        if (this.props.requiresAction && !prevProps.requiresAction) {
            this.props.stripe.handleCardAction(this.props.paymentIntentSecret)
                .then(payload => {
                    this.props.handleConfirmPayment();
                }, error => {
                    this.props.handleError(error);
                    this.setState({disabled: false});
                });
        }

        if (prevProps.isPaying && !this.props.isPaying){
            this.setState({disabled: false});
        }
    }

    handleCreateToken = () => {

        this.setState({disabled: true});

        //We send the name if it's there
        let data = {};
        if (this.props.nameOnCard) {
            data = _assign(data, {'billing_details': {'name': this.props.nameOnCard}})
        }

        this.props.stripe.createPaymentMethod('card', data)
            .then(payload => {

                    if (_get(payload, 'paymentMethod.id')) {
                        this.props.handleToken(payload.paymentMethod.id, STRIPE_INTENT)
                    } else {
                        this.props.handleError(payload.error);
                        this.setState({disabled: false});
                    }
                }
            );
    };

    render() {

        const styles = this.getStyles();

        return (
            <div className="clear">
                <CardElement className="cardElement"/>
                <RaisedButton
                    type="submit"
                    label={
                        <FormattedMessage
                            id="checkout.payNow"
                            defaultMessage="Pay"
                        />
                    }
                    className="buyBtn"
                    backgroundColor="#808080"
                    disabled={this.state.disabled}
                    style={styles.buyButton}
                    buttonStyle={styles.buyButtonInside}
                    onClick={this.handleCreateToken}
                    primary
                />
            </div>
        )
    }
}

export const
    StripeCardForm = muiThemeable()(injectStripe(_StripeCardForm));
