// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';

import {loginUsernamePassword, recoverPassword, resetPassword} from '../../actions';

import {LoginProps, LoginState} from "../../TypeDefinitions";

import {validateField, validateForm} from "../../../../services/validate";

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import muiThemeable from "material-ui/styles/muiThemeable";
import {ALERT_OPEN} from "../../../../actions/actionTypes";
import {REGISTER_CHANGE_PASSWORD_INVALID_EMAIL, REGISTER_CHANGE_PASSWORD_VALIDATION_ERROR} from "../../../../lang/alerts";

import {FormattedMessage} from 'react-intl';

class Login extends Component <LoginProps, LoginState> {

    state = {
        values: {
            email: '',
            password: '',
            forgotYourPasswordCode: '',
            newPassword: ''
        },
        recoverPasswordFormActive: false,
        errors: {
            email: null,
            password: null
        },
        rules: {
            email: {
                required: 'Email is required',
                isEmail: 'Email is not valid'
            },
            password: {
                required: 'Password is required',
                validPassword: 'Password should have more than 7 characters'
            },
            newPassword: {
                required: 'Password is required',
                validPassword: 'Password should have more than 7 characters'
            }
        }
    };

    updateField = (e, value) => {
        const name = e.target.name;

        const rules = this.state.rules[name];

        let is_valid = validateField(name, rules, value);

        this.setState(prevState => ({
            values: {...prevState.values, [name]: value},
            errors: {...prevState.errors, [name]: is_valid}
        }));
    };

    loginAction = (e) => {
        e.preventDefault();

        if (!validateForm(this.state.errors)) return false;

        const {email, password} = this.state.values;

        if (email && password) {
            this.props.login({username: email, password, options: {vendor: this.props.vendor}});
        }
    };

    recoverPasswordAction = (e) => {
        e.preventDefault();

        const {email} = this.state.values;

        const rules = this.state.rules['email'];

        let has_errors = validateField('email', rules, email);

        if (!has_errors) {
                this.setState(prevState => ({
                    ...prevState,
                    recoverPasswordFormActive: true
                }));

            this.props.recoverPassword(email, this.props.vendor);
        } else {
            this.props.recoverPasswordEmailNotValid();
        }
    };

    resetPasswordAction = (e) => {
        e.preventDefault();

        const {email, forgotYourPasswordCode, newPassword} = this.state.values;

        const rules = this.state.rules['newPassword'];

        let has_errors = validateField('newPassword', rules, newPassword);

        if (!has_errors){
        this.props.resetPassword(email, forgotYourPasswordCode, newPassword, this.props.vendor)
        }else{
            this.props.resetPasswordNotValid();
        }



    };


    render() {
        return (
            <div className='spacing-bottom'>
                <TextField
                    name="email"
                    ref="email"
                    id="personal_email"
                    style={this.props.muiTheme.personal.textField}
                    defaultValue={this.state.values.email}
                    hintText={
                        <FormattedMessage
                            id="checkout.email"
                            defaultMessage="Email"
                        />
                    }
                    type="email"
                    errorText={this.state.errors.email}
                    onChange={this.updateField}
                />
                <TextField
                    name="password"
                    ref="password"
                    id="personal_password"
                    style={this.props.muiTheme.personal.textField}
                    defaultValue={this.state.values.password}
                    hintText={
                        <FormattedMessage
                            id="checkout.password"
                            defaultMessage="Password"
                        />
                    }
                    type="password"
                    errorText={this.state.errors.password}
                    onChange={this.updateField}
                />
                <RaisedButton
                    label="Login"
                    className="loginBtn-2"
                    primary={true}
                    style={this.props.muiTheme.personal.buttons.yes}
                    onClick={this.loginAction}
                    secondary={true}
                />
                {!this.state.recoverPasswordFormActive && (
                <span className="text--main">
                    {'Forgot your password? '}

                    <RaisedButton
                        label={(
                            <FormattedMessage
                                id="checkout.reset-password"
                                defaultMessage="Reset Password"
                            />
                        )}
                        primary
                        className="loginBtn"
                        onClick={this.recoverPasswordAction}
                    />

                </span>
                )}
                {this.state.recoverPasswordFormActive && (
                    <div>
                        <strong className="text--main">
                            <br/>
                            A code has been emailed to you, please enter it below along with your new password
                            The email can be delayed up to 5 minutes in some cases. PLease, don't refresh the browser.
                        </strong>
                        <TextField
                            name="forgotYourPasswordCode"
                            id="forgotYourPasswordCode"
                            style={this.props.muiTheme.personal.textField}
                            value={this.state.values.forgotYourPasswordCode}
                            placeholder="Code received"
                            type="text"
                            errorText={this.state.errors.forgotYourPasswordCode}
                            onChange={this.updateField}
                        />
                        <TextField
                            name="newPassword"
                            id="newPassword"
                            style={this.props.muiTheme.personal.textField}
                            value={this.state.values.newPassword}
                            hintText={(
                                <FormattedMessage
                                    id="checkout.new-password"
                                    defaultMessage="New Password"
                                />
                            )}
                            type="password"
                            errorText={this.state.errors.newPassword}
                            onChange={this.updateField}
                        />

                        <RaisedButton
                            label="Reset Password"
                            className="loginBtn-2"
                            primary
                            style={this.props.muiTheme.personal.buttons.yes}
                            onClick={this.resetPasswordAction}
                            secondary
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    login: ({username, password, options}) => {
        dispatch(loginUsernamePassword({
            username, password, options,
        }))
    },
    recoverPassword: (email, vendor) => dispatch(recoverPassword({email, vendor})),
    resetPassword: (email, code, newPassword, vendor) => dispatch(resetPassword({email, code, newPassword, vendor})),
    recoverPasswordEmailNotValid: () => dispatch({ type: ALERT_OPEN, message: REGISTER_CHANGE_PASSWORD_INVALID_EMAIL }),
    resetPasswordNotValid: () => dispatch({ type: ALERT_OPEN, message: REGISTER_CHANGE_PASSWORD_VALIDATION_ERROR })
});

const mapStateToProps = (state) => ({
        vendor: state.vendor,
        auth: state.auth
    });

export default muiThemeable()(connect(mapStateToProps, mapDispatchToProps)(Login));
