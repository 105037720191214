const supportedLanguages = {
    "zh-TW": {
        locale: "zh-TW",
        messages: {
            // checkout page 1
            "checkout.signupOrLogin": "註冊或登入",
            "checkout.billingAddress": "帳單地址",
            "checkout.reviewYourOrder": "訂單瀏覽和付款",
            "checkout.alreadyUser": "我已經是GENFLOW用戶",
            "checkout.login": "在此登入",
            "checkout.firstName": "名",
            "checkout.lastName": "姓",
            "checkout.email": "電子信箱",
            "checkout.emailConfirm": "電子信箱確認",
            "checkout.password": "密碼",
            "checkout.passwordConfirm": "密碼確認",
            "checkout.signup": "註冊",
            // checkout page 2
            "checkout.addressLine1": "地址",
            "checkout.addressLine2": "N/A",
            "checkout.city": "縣市",
            "checkout.postalCode": "郵遞區號",
            "checkout.state": "省",
            "checkout.chooseCountry": "國家",
            "checkout.next": "下一步",
            // checkout page 3
            "checkout.subtotal": "小計",
            "checkout.total": "總金額",
            "checkout.cardOptions": "信用卡, PayPal, Apple Pay, Google Pay",
            "checkout.payNow": "立即付款",
            "checkout.payByCard": "信用卡付款",
            "checkout.nameOnCard": "信用卡持有人姓名",
            "checkout.cardNumber": "卡號",
            "checkout.cardDate": "月份/年份  驗證碼",
            "checkout.termsAndServices": "透過付款，您將同意我們提供的",
            "checkout.termsLink": "條款和服務",
            // thank you page
            "checkout.thankYou": "謝謝您的購買！",
            "checkout.paymentProcessed": "您已完成付款程序",
            "checkout.receiveConfirmationEmail": "您將會收到確認信，信件裡將提供所有的相關資訊。如果訂單是電子書的話將會附上電子書的下載連結。",
            "checkout.checkSpam": "若未收到信，請記得確認您的垃圾信件夾",
            "checkout.workoutGuides": "運動指南，課表和計畫",
            "checkout.purchasedAccessApp": "購買後請到APP商店下載AFLETE APP，並以您剛剛已經註冊好的帳號登入APP即可取得完整的運動課表",
            "checkout.courses": "線上課程",
            "checkout.purchasedCourse": "如果您希望透過網站取得完整課表，請回到網站並以您剛剛已經註冊好的帳號登入後即可取得完整的運動課表",
            "checkout.merchandise": " ",
            "checkout.purchasedMerch": " ",
            "checkout.appDownloadLinks": "App下載連結",
            "checkout.needHelp": "需要幫忙？請至",
            "checkout.needHelpLink": "客服支援",
            // countries
            "countries.albania": "阿爾巴尼亞",
            "countries.algeria": "阿爾及利亞",
            "countries.americanSamoa": "美屬薩摩亞",
            "countries.andorra": "安道爾",
            "countries.angola": "安哥拉",
            "countries.anguilla": "安圭拉",
            "countries.antiguaAndBarbuda": "安提瓜和巴布達",
            "countries.argentina": "阿根廷",
            "countries.armenia": "亞美尼亞",
            "countries.aruba": "阿魯巴",
            "countries.australia": "澳大利亞",
            "countries.austria": "奧地利",
            "countries.azerbaijan": "阿塞拜疆",
            "countries.bahamas": "巴哈馬",
            "countries.bahrain": "巴林",
            "countries.bangladesh": "孟加拉國",
            "countries.barbados": "巴巴多斯",
            "countries.belgium": "比利時",
            "countries.belize": "伯利茲",
            "countries.benin": "貝寧",
            "countries.bermuda": "百慕大",
            "countries.bhutan": "不丹",
            "countries.bolivia": "玻利維亞",
            "countries.bosniaAndHerzegovina": "波斯尼亞和黑塞哥維那",
            "countries.botswana": "博茨瓦納",
            "countries.brazil": "巴西",
            "countries.britishIndianOceanTerritory": "英屬印度洋領地",
            "countries.bruneiDarussalam": "文萊達魯薩蘭國",
            "countries.bulgaria": "保加利亞",
            "countries.burkinaFaso": "布基納法索",
            "countries.burundi": "布隆迪",
            "countries.cambodia": "柬埔寨",
            "countries.cameroon": "喀麥隆",
            "countries.canada": "加拿大",
            "countries.capeVerde": "綠色斗篷",
            "countries.caymanIslands": "開曼群島",
            "countries.centralAfricanRepublic": "中非共和國",
            "countries.chad": "乍得",
            "countries.chile": "智利",
            "countries.china": "中國",
            "countries.christmasIsland": "聖誕島",
            "countries.colombia": "哥倫比亞",
            "countries.comoros": "科摩羅",
            "countries.cookIslands": "庫克群島",
            "countries.costaRica": "哥斯達黎加",
            "countries.croatia": "克羅地亞",
            "countries.cyprus": "塞浦路斯",
            "countries.czechRepublic": "捷克共和國",
            "countries.denmark": "丹麥",
            "countries.djibouti": "吉布提",
            "countries.dominica": "多米尼加",
            "countries.dominicanRepublic": "多明尼加共和國",
            "countries.ecuador": "厄瓜多爾",
            "countries.egypt": "埃及",
            "countries.elSalvador": "薩爾瓦多",
            "countries.equatorialGuinea": "赤道幾內亞",
            "countries.estonia": "愛沙尼亞",
            "countries.ethiopia": "埃塞俄比亞",
            "countries.falklandIslands": "福克蘭群島",
            "countries.faroeIslands": "法羅群島",
            "countries.finland": "芬蘭",
            "countries.france": "法國",
            "countries.frenchGuiana": "法屬圭亞那",
            "countries.frenchPolynesia": "法屬波利尼西亞",
            "countries.gabon": "加蓬",
            "countries.gambia": "岡比亞",
            "countries.georgia": "格魯吉亞",
            "countries.germany": "德國",
            "countries.ghana": "加納",
            "countries.gibraltar": "直布羅陀",
            "countries.guineaBissau": "幾內亞比紹",
            "countries.greece": "希臘",
            "countries.greenland": "格陵蘭",
            "countries.grenada": "格林納達",
            "countries.guadeloupe": "瓜德羅普島",
            "countries.guatemala": "危地馬拉",
            "countries.guernsey": "根西島",
            "countries.guinea": "幾內亞",
            "countries.guyana": "圭亞那",
            "countries.honduras": "洪都拉斯",
            "countries.hongKong": "香港",
            "countries.hungary": "匈牙利",
            "countries.iceland": "冰島",
            "countries.india": "印度",
            "countries.indonesia": "印度尼西亞",
            "countries.ireland": "愛爾蘭",
            "countries.isleOfMan": "馬恩島",
            "countries.israel": "以色列",
            "countries.italy": "意大利",
            "countries.jamaica": "牙買加",
            "countries.japan": "日本",
            "countries.jersey": "新澤西",
            "countries.jordan": "約旦",
            "countries.kazakhstan": "哈薩克斯坦",
            "countries.kenya": "肯尼亞",
            "countries.kiribati": "基里巴斯",
            "countries.korea": "韓國",
            "countries.kuwait": "科威特",
            "countries.kyrgyzstan": "吉爾吉斯斯坦",
            "countries.laos": "老撾",
            "countries.latvia": "拉脫維亞",
            "countries.lebanon": "黎巴嫩",
            "countries.lesotho": "萊索托",
            "countries.liechtenstein": "列支敦士登",
            "countries.lithuania": "立陶宛",
            "countries.luxembourg": "盧森堡",
            "countries.macao": "澳門",
            "countries.macedonia": "馬其頓",
            "countries.madagascar": "馬達加斯加",
            "countries.malawi": "馬拉維",
            "countries.malaysia": "馬來西亞",
            "countries.maldives": "馬爾代夫",
            "countries.mali": "馬里",
            "countries.malta": "馬耳他",
            "countries.marshallIslands": "馬紹爾群島",
            "countries.mauritania": "毛里塔尼亞",
            "countries.mauritius": "毛里求斯",
            "countries.mayotte": "馬約特",
            "countries.mexico": "墨西哥",
            "countries.micronesia": "密克羅尼西亞",
            "countries.moldova": "摩爾多瓦",
            "countries.monaco": "摩納哥",
            "countries.mongolia": "蒙古",
            "countries.montenegro": "黑山",
            "countries.montserrat": "蒙特塞拉特",
            "countries.morocco": "摩洛哥",
            "countries.mozambique": "莫桑比克",
            "countries.namibia": "納米比亞",
            "countries.nauru": "瑙魯",
            "countries.nepal": "尼泊爾",
            "countries.netherlands": "荷蘭",
            "countries.newCaledonia": "新喀裡多尼亞",
            "countries.newZealand": "新西蘭",
            "countries.nicaragua": "尼加拉瓜",
            "countries.niger": "尼日爾",
            "countries.nigeria": "尼日利亞",
            "countries.niue": "紐埃",
            "countries.norway": "挪威",
            "countries.oman": "阿曼",
            "countries.pakistan": "巴基斯坦",
            "countries.palau": "帕勞",
            "countries.palestine": "巴勒斯坦",
            "countries.panama": "巴拿馬",
            "countries.papuaNewGuinea": "巴布亞新幾內亞",
            "countries.paraguay": "巴拉圭",
            "countries.peru": "秘魯",
            "countries.philippines": "菲律賓",
            "countries.poland": "波蘭",
            "countries.portugal": "葡萄牙",
            "countries.puertoRico": "波多黎各",
            "countries.qatar": "卡塔爾",
            "countries.reunion": "團圓",
            "countries.romania": "羅馬尼亞",
            "countries.russianFederation": "俄羅斯聯邦",
            "countries.saintHelenaAscensionAndTristanDaCunha": "聖赫勒拿，阿森松島和特里斯坦達庫尼亞",
            "countries.saintKittsAndNevis": "聖基茨和尼維斯",
            "countries.saintLucia": "聖盧西亞",
            "countries.saintPierreAndMiquelon": "聖皮埃爾和密克隆",
            "countries.saintVincentAndTheGrenadines": "聖文森特和格林納丁斯",
            "countries.samoa": "薩摩亞",
            "countries.sanMarino": "聖馬力諾",
            "countries.saoTomeAndPrincipe": "聖多美和普林西比",
            "countries.saudiArabia": "沙特阿拉伯",
            "countries.senegal": "塞內加爾",
            "countries.serbia": "塞爾維亞",
            "countries.seychelles": "塞舌爾",
            "countries.sierraLeone": "塞拉利昂",
            "countries.singapore": "新加坡",
            "countries.slovakia": "斯洛伐克",
            "countries.slovenia": "斯洛文尼亞",
            "countries.solomonIslands": "所羅門群島",
            "countries.southAfrica": "南非",
            "countries.southSudan": "南蘇丹",
            "countries.spain": "西班牙",
            "countries.suriname": "蘇里南",
            "countries.swaziland": "斯威士蘭",
            "countries.sweden": "瑞典",
            "countries.switzerland": "瑞士",
            "countries.taiwan": "台灣",
            "countries.tajikistan": "塔吉克斯坦",
            "countries.tanzania": "坦桑尼亞",
            "countries.thailand": "泰國",
            "countries.togo": "多哥",
            "countries.tokelau": "托克勞",
            "countries.tonga": "湯加",
            "countries.trinidadAndTobago": "特立尼達和多巴哥",
            "countries.tunisia": "突尼斯",
            "countries.turkey": "土耳其",
            "countries.turkmenistan": "土庫曼斯坦",
            "countries.turksAndCaicosIslands": "特克斯和凱科斯群島",
            "countries.tuvalu": "圖瓦盧",
            "countries.uganda": "烏干達",
            "countries.ukraine": "烏克蘭",
            "countries.unitedArabEmirates": "阿拉伯聯合酋長國",
            "countries.unitedKingdom": "英國",
            "countries.unitedStates": "美國",
            "countries.uruguay": "烏拉圭",
            "countries.uzbekistan": "烏茲別克斯坦",
            "countries.vanuatu": "瓦努阿圖",
            "countries.wallisAndFutuna": "瓦利斯和富圖納群島",
            "countries.zambia": "贊比亞"
        }
    }
};

export default supportedLanguages;
