import {
    AUTH0_STORE,
    LOGGED,
    ERROR_LOGIN,
    ERROR_SIGNUP,
    LOGOUT,
    RECOVER_PASSWORD_FORM,
    ALREADY_LOGGED_IN
} from './types';

const INITIAL_STATE = {
  AUTH0_STORE,
  accessToken: null,
  idToken: null,
  expiresAt: null,
  error: null,
};

export default (state = INITIAL_STATE, { type, ...props }) => {
  switch (type) {
    case LOGGED:
        case ALREADY_LOGGED_IN:
      return ({ ...state, ...props, error: null });
    case ERROR_LOGIN:
    case ERROR_SIGNUP:
      return ({ ...state, ...props });
    case RECOVER_PASSWORD_FORM:
      return ({ ...state, ...props });
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
