export default {
    fontFamily: 'Open Sans, sans-serif',
    borderRadius: 2,
    palette: {
        primary1Color: "#0c1635",
        primary2Color: "#279b3e",
        primary3Color: "#279b3e",
        accent1Color: "#279b3e",
        accent2Color: "#279b3e",
        accent3Color: "#279b3e",
        // secondaryColor: "#b90017",
        // textColor: "#FFFFFF",
        // secondaryTextColor: "#FFFFFF",
        // alternateTextColor: "#FFFFFF",
        // canvasColor: "#FFFFFF",
        // borderColor: "#FFFFFF",
        // disabledColor: "#FFFFFF",
        // pickerHeaderColor: "#FFFFFF",
        // clockCircleColor: "#FFFFFF",
        // shadowColor: "#FFFFFF"
    },
    buyButton: {
        width:'100%',
        height:'45px',
        lineHeight:'45px',
        margin: "20px auto"
    },
    buyButtonInside: {
        height:'45px',
        lineHeight:'45px'
    },
    personal: {
        toggle: {
            marginBottom: 16,
        },
        buttons: {
            yes: {
                marginBottom: 16,
            },
            no: {
                marginLeft: 12,
                marginBottom: 16,
            }
        },
        textField: {
            width: '100%'
        }
    },
    address: {
        toggle: {
            marginBottom: 16,
        },
        textField: {
            width: '100%'
        }
    },
    card: {
        button: {
            width: 175,
            margin: "20px auto"
        }
    },
    product: {
        quantity: {
            width: 50
        },
        remove: {
            FlatButtonLabel: {
                textTransform: "capitalize",
            }
        }
    },
    stepper: {
        justifyContent: "center"
    },
    ActionCheckCircle: {
        completed: {
            color: "#109300"
        }
    }
};
