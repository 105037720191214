import _has from 'lodash/has';

import Order from '../services/createOrder';

import Pusher from 'pusher-js';

import {
    CARD_ADD_ERROR,
    CART_PAY,
    CART_CLEAN,
    CART_PAID,
    CART_PAY_WAITING_START,
    CART_PAY_WAITING_ERROR,
    CART_PAY_WAITING_END,
    CART_PAY_ERROR,
    ALERT_OPEN,
    CARD_REMOVE,
    UPDATE_STEP,
    STRIPE_REQUIRES_ACTION,
    CART_CONFIRM_PAYMENT
} from '../actions/actionTypes';

//Error strings
import {
    STRIPE_CARD_INVALID,
    STRIPE_CARD_DECLINED,
    STRIPE_CARD_UNDEFINED,
    API_ORDER_NOT_COMPLETED,
    API_ORDER_TIMEOUT,
    API_ORDER_ERROR_WAITING,
    API_ORDER_NOT_CREATED,
    API_VARIANTS_NO_STOCK,
    API_ORDER_INVALID,
    BRAINTREE_DECLINED,
    BRAINTREE_UNDEFINED
} from '../lang/alerts';

let pusherClient = null;

let timeout = null;

const paymentMiddleware = store => next => action => {

    next(action);

    if (action.type === CART_PAY) {
        if (!pusherClient) {
            pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
                cluster: 'eu',
            });
        }

        const state = store.getState();

        bindPusherEvent(state.common.channelId, 'create-transaction', store);

        store.dispatch(
            createOrder(state)
        )
    }

    if (action.type === CART_PAY_WAITING_START) {
        store.dispatch(
            (dispatch) => {
                timeout = setTimeout(() => {
                    dispatch({type: CART_PAY_WAITING_ERROR});
                }, process.env.REACT_APP_TIMEOUT_TIME);
            }
        )
    }

    if (action.type === CART_PAY_WAITING_ERROR) {
        clearTimeout(timeout);
        const state = store.getState();
        if (state.common.payWaiting)
            Order.checkStatus(state).then(
                (response) => {
                    if (response) {
                        store.dispatch({type: CART_PAY_WAITING_END, payload: false});
                        store.dispatch({type: CART_PAID});
                    } else {
                        store.dispatch({
                            type: ALERT_OPEN,
                            message: API_ORDER_ERROR_WAITING
                        });
                    }
                },
                (error) => {
                    store.dispatch({
                        type: ALERT_OPEN,
                        message: API_ORDER_ERROR_WAITING
                    });
                }
            );
    }

    if (action.type === CART_PAID) {
        pusherClient.disconnect();
        store.dispatch({type: CART_PAY_WAITING_END, payload: false});
        store.dispatch({type: CART_CLEAN})
    }

    if (action.type === CARD_ADD_ERROR) {
        if (_has(action.error, 'type')) {
            store.dispatch({
                type: ALERT_OPEN,
                message: STRIPE_CARD_INVALID
            })
        } else if (action.error.request) {
            store.dispatch({
                type: ALERT_OPEN,
                message: STRIPE_CARD_UNDEFINED
            })
        } else {
            // Something happened in setting up the request that triggered an Error
            store.dispatch({
                type: ALERT_OPEN,
                message: STRIPE_CARD_UNDEFINED
            })
        }
    }

    if (action.type === CART_PAY_ERROR) {
        clearTimeout(timeout);
        if (_has(action.data, 'error')) {
            if (action.data.error === 'StripeRequiresAction') {
                const message = JSON.parse(action.data.message);
                store.dispatch({
                    type: STRIPE_REQUIRES_ACTION,
                    paymentIntentSecret: message.clientSecret,
                    paymentIntentId: message.id
                });
                return;
            }

            let message;
            switch (action.data.error) {
                case 'ValidationError':
                    message = Object.keys(action.data.message).map(
                        errorKey => action.data.message[errorKey]
                    ).join('\n\n');
                    break;
                case 'StripeCard_invalid_number' :
                case 'StripeCard_invalid_expiry_month' :
                case 'StripeCard_invalid_expiry_year' :
                case 'StripeCard_invalid_cvc' :
                case 'StripeCard_invalid_swipe_data' :
                case 'StripeCard_incorrect_number' :
                case 'StripeCard_incorrect_cvc' :
                case 'StripeCard_incorrect_zip' :
                    message = STRIPE_CARD_INVALID;
                    break;
                case 'StripeCard_card_declined' :
                case 'StripeCard_expired_card' :
                case 'StripeCard_missing' :
                case 'StripeCard_processing_error' :
                    message = STRIPE_CARD_DECLINED;
                    break;
                case 'StripeMissingParameter' :
                case 'StripeBadRequest' :
                case 'StripeInvalidRequest' :
                case 'StripeNotFound' :
                case 'StripeServerError' :
                case 'StripeUnauthorized' :
                case 'StripeApiLimitExceeded' :
                    message = STRIPE_CARD_UNDEFINED;
                    break;
                case 'BrainTreeNotAuthorised' :
                    message = BRAINTREE_DECLINED;
                    break;
                case 'BrainTreeUndefined' :
                    message = BRAINTREE_UNDEFINED;
                    break;
                case 'OrderCanNotBeCreated':
                    message = API_ORDER_NOT_CREATED;
                    break;
                case 'OrderCanNotBeCompleted':
                    message = API_ORDER_NOT_COMPLETED;
                    break;
                case 'NoStockProduct':
                    message = API_VARIANTS_NO_STOCK;
                    break;
                case 'UserNotAuthorised':
                case 'MaintenanceError':
                case 'PaymentNotValid':
                    message = action.data.message;
                    break;
                default:
                    message = API_ORDER_INVALID;
            }
            store.dispatch({
                type: ALERT_OPEN,
                message: message
            })
        } else if (action.error.request) {
            store.dispatch({
                type: ALERT_OPEN,
                message: API_ORDER_TIMEOUT
            })
        } else {
            // Something happened in setting up the request that triggered an Error
            store.dispatch({
                type: ALERT_OPEN,
                message: API_ORDER_TIMEOUT
            })
        }
        //We remove card data because we need a new token
        store.dispatch({type: CARD_REMOVE});
        store.dispatch({type: UPDATE_STEP, key: 'card', value: false});
    }

    if (action.type === CART_CONFIRM_PAYMENT) {
        store.dispatch(
            confirmPayment(store.getState())
        )
    }
};

function createOrder(state) {
    return function (dispatch) {
        return Order.create(state).then(
            message => dispatch({type: CART_PAY_WAITING_START}),
            error => dispatch({type: CART_PAY_ERROR, error})
        );
    };
}

function confirmPayment(state) {
    return function (dispatch) {
        return Order.confirmPayment(state).then(
            data => {
                if (_has(data, 'error')) {
                    dispatch({type: CART_PAY_ERROR, data})
                } else {
                    let message = data.message;
                    dispatch({type: CART_PAID, message})
                }
            },
            error => dispatch({type: CART_PAY_ERROR, error})
        );
    };
}

function bindPusherEvent(channel, event, store) {
    const pusherChannel = pusherClient.subscribe(channel);
    pusherChannel.bind(event, (data) => {
        if (_has(data, 'error')) {
            store.dispatch({type: CART_PAY_ERROR, data})
        } else {
            let message = data.message;
            store.dispatch({type: CART_PAID, payload: data.payload});
        }
    });
}

export default paymentMiddleware
