import types from './types';

export const loginUsernamePassword = ({username, password, options}) => ({
    type: types.LOGIN_USERNAME_PASSWORD,
    username,
    password,
    options
});
export const handleLogin = ({accessToken, idToken, expiresAt}) => ({
    type: types.LOGGED,
    accessToken,
    idToken,
    expiresAt,
});
export const alreadyLogged = ({accessToken, idToken, expiresAt}) => ({
    type: types.ALREADY_LOGGED_IN,
    accessToken,
    idToken,
    expiresAt,
});
export const loginError = (error) => ({type: types.ERROR_LOGIN, error});
export const signUp = ({firstName, lastName, username, password, options}) => ({
    type: types.SIGNUP,
    firstName,
    lastName,
    username,
    password,
    options
});
export const signUpData = ({email, first_name, last_name}) => ({
    type: types.SIGNUP_DATA,
    payload: {email, first_name, last_name}
});
export const signUpError = (error) => ({type: types.ERROR_SIGNUP, error});

export const logout = () => ({type: types.LOGOUT});
export const logoutError = (error) => ({type: types.ERROR_LOGOUT, error});

export const recoverPassword = ({email, ...options}) => ({type: types.RECOVER_PASSWORD, email, options});

export const resetPassword = ({email, code, newPassword, vendor}) => ({type: types.RESET_PASSWORD, email, code, newPassword, vendor});
