export const AUTH0_STORE = '@Auth_Store';
export const LOGIN = '@Auth_Login';
export const LOGIN_USERNAME_PASSWORD = '@Auth_Login_username_password';
export const SOCIAL_CONNECTION = '@Auth_Social_connection';
export const ERROR_LOGIN = '@Auth_Login_Error';

export const LOGGED = '@Auth_Logged';
export const ALREADY_LOGGED_IN = '@Auth_Already_logged_in';

export const LOGOUT = '@Auth_Logout';
export const ERROR_LOGOUT = '@Auth_Logout_Error';

export const SIGNUP = '@Auth_Signup';
export const SIGNUP_DATA = '@Auth_Signup_Data';
export const ERROR_SIGNUP = '@Auth_Signup_Error';

export const RECOVER_PASSWORD = '@Auth_Recover_Password';
export const RECOVER_PASSWORD_FORM = '@Auth_Recover_Password_Form';
export const RESET_PASSWORD = '@Auth_Reset_Password';

export default {
    LOGIN,
    LOGIN_USERNAME_PASSWORD,
    LOGGED,
    ALREADY_LOGGED_IN,
    ERROR_LOGIN,
    LOGOUT,
    ERROR_LOGOUT,
    SIGNUP,
    SIGNUP_DATA,
    ERROR_SIGNUP,
    SOCIAL_CONNECTION,
    RECOVER_PASSWORD,
    RESET_PASSWORD
};
