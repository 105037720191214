import React, {Fragment} from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import {FormattedMessage} from 'react-intl';

const thrstThankYou = () => (
    <div className="thank-you">{``}
        <div className="mainDesktop__container">
            <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png"
                 alt=""/>
            <div><h1>
                <FormattedMessage
                    id="checkout.thankYou"
                    defaultMessage="Thank you!"
                />
            </h1></div>
            <p><strong>
                <FormattedMessage
                    id="checkout.paymentProcessed"
                    defaultMessage="Your payment has been processed successfully."
                />
            </strong></p>
            <p>
                <FormattedMessage
                    id="checkout.checkSpam"
                    defaultMessage="Please, check your spam or junk folders if it's not in your inbox."
                />
            </p>
            <br/>
            <Fragment>
            <div>
                <img className="thrst-logo" src="https://thrstwear.com/img/black-logo.png" alt=""/>
            </div>
                <h3>
                    <FormattedMessage
                        id="checkout.merchandise"
                        defaultMessage="Merchandise"
                    />
                </h3>
                <p>
                    <FormattedMessage
                        id="checkout.purchasedMerch"
                        defaultMessage="If you purchased some merch, please check your email for your order confirmation and shipping times."
                    />
                </p>
                <br/>
            </Fragment>
            <p className="problem-line">
                <FormattedMessage
                    id="checkout.needHelp"
                    defaultMessage="Need Help? Visit > "
                />
                <a href="https://support.thrstwear.com/hc/en-us/requests/new">
                    <FormattedMessage
                        id="checkout.needHelpLink"
                        defaultMessage="Customer Support"
                    />
                </a>
            </p>
            <p className="genflow-badge"><img
                src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/powered.png" alt=""/></p>
        </div>
    </div>
);

export default thrstThankYou;
