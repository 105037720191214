import React, { Fragment } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import {FormattedMessage} from 'react-intl';

const SxyfitThankYou = ({styles}) => (
    <div className="thank-you">{``}
        <div className="mainDesktop__container">
            <div>
                <img className="sxyfit-logo" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/sxyfit-logo.png" alt=""/>
            </div>
            <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png" alt=""/>
            <p><strong>Your payment has been processed successfully and you will receive an order confirmation email shortly.</strong></p>
            <p>To access the members area just login below using the same email and password you just created.</p>
        
            <a href="https://members.stephaniemariefit.com/">
                <RaisedButton
                    type="submit"
                    label="Login"
                    style={styles.button}
                    primary
                />
            </a>
            <p className="problem-line">
                <FormattedMessage
                    id="checkout.needHelp"
                    defaultMessage="Need Help? Visit > "
                />
                <a href="https://support.stephaniemariefit.com/hc/en-us">
                    <FormattedMessage
                        id="checkout.needHelpLink"
                        defaultMessage="Customer Support"
                    />
                </a>
            </p>
            <p className="genflow-logo"><img
                src="https://assets.aflete.com/checkout/genflow-powered-black.png" alt=""/></p>
        </div>
    </div>
);

export default SxyfitThankYou;
