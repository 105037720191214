import React from 'react';
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

const Coupon = ({handleSendCode, handleRemoveCode, handleCode, coupon, error, styles}) =>(
            <div>
                {(coupon) &&
                <div className="coupon">
                    <p>Coupon enabled: {coupon.code} - {coupon.percentage}% - <FlatButton label="Remove" secondary={true} onClick={handleRemoveCode} className="removebtn" style={styles.remove}/></p>
                </div>
                }
                {(!coupon) &&
                <div className="coupon">

                    <TextField
                        name="coupon"
                        id="coupon"
                        hintText="Coupon code"
                        errorText={error}
                        onChange={handleCode}
                    />
                    <RaisedButton
                        type="submit"
                        label="Apply"
                        id="coupon_submit"
                        style={styles.button}
                        primary
                        onClick={handleSendCode}
                    />

                </div>
                }
            </div>
        );

Coupon.propTypes = {
    handleSendCode: PropTypes.func.isRequired,
    handleRemoveCode: PropTypes.func.isRequired,
    handleCode: PropTypes.func.isRequired,
    coupon: PropTypes.object,
    styles: PropTypes.object.isRequired,
};


export default Coupon