import React, {Component, Fragment} from 'react';

import {STRIPE_PAYMENT_REQUEST} from "../constants/paymentTypes";

import {
    PaymentRequestButtonElement,
    injectStripe,
} from 'react-stripe-elements';

class _StripePaymentRequestButton extends Component {

    paymentRequest = null;

    constructor(props) {
        super(props);

        this.state = {
            canMakePayment: false,
            paymentRequest: null
        };
    }

    componentDidMount(){
        this.createPaymentRequest(
            this.props.country,
            this.props.currency,
            this.props.amount
        );
    }

    createPaymentRequest = (country, currency, amount) => {
        this.setState({canMakePayment: false});

        this.paymentRequest = this.props.stripe.paymentRequest({
            country: country,
            currency: currency.toLowerCase(),
            requestShipping: false,
            total: {
                label: 'Genflow Order Total',
                amount: amount,
            },
        });

        this.paymentRequest.on('token', ({complete, token, ...data}) => {
            this.props.handleToken(token, STRIPE_PAYMENT_REQUEST);
            complete('success');
        });

        this.paymentRequest.canMakePayment().then(result => {
            this.setState({canMakePayment: !!result});
        });
    };

    render() {
        return this.state.canMakePayment ? (
            <Fragment>
                <PaymentRequestButtonElement
                    className="PaymentRequestButton"
                    paymentRequest={this.paymentRequest}
                    style={{
                        paymentRequestButton: {
                            theme: 'dark',
                            height: '45px',
                            type: 'default',
                        },
                    }}
                />
            </Fragment>
        ) : null;
    }
}

const StripePaymentRequestButton = injectStripe(_StripePaymentRequestButton);

export class StripePaymentRequest extends Component {
    render() {
        //Unique key each time any of these values change so paymentRequest can be regenerated with the correct props
        const key = this.props.currency + this.props.country + this.props.amount;

        return (
            <StripePaymentRequestButton key={key} { ...this.props } />
        );
    }
}
