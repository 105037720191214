
import _ from 'lodash';
import _omit from 'lodash/omit';
import _findIndex from 'lodash/findIndex';

import { bugsnagClient } from "../services/bugSnag";

//import agent from './agent';
import {
    PERSONAL_SET_COUNTRY_ERROR,
    CART_SET_CURRENCY_ERROR,
    CART_ADD_ERROR,
    CARD_ADD_ERROR,
    CART_PAY_ERROR,
    COUPON_ADD_ERROR,
    LOGIN_ERROR,
    UNDEFINED_ERROR
} from '../actions/actionTypes';

import * as actionType from '../actions/actionTypes';

import Logger from '../services/logger';
const sendLog = (type, message) => {
    //call the approriate type's method (error, warn, log, info)
    Logger[type](message);
};

const filterState = (state) => {
    return _omit(state, ['card', 'auth', 'common.profile', 'personal.password', 'personal.password_confirm', 'cart.byHash']);
};

const selectState = (action, state) => {
    const actions = {
        [actionType.APP_LOAD]: () => {return {value: null}},
        [actionType.REDIRECT]: () => {return {value: null}},
        [actionType.CART_ADD]: () => {return {value: action.id}},
        [actionType.CART_UPDATE]: () => {return {value: null}},
        [actionType.CART_REMOVE]: () => {return {value: action.id}},
        [actionType.CART_CLEAN]: () => {return {value: null}},
        [actionType.CART_SHOULD_UPDATE_TOTALS]: () => {return {value: null}},
        [actionType.CART_CHECK_TYPE]: () => {return {value: null}},
        [actionType.CART_SET_TYPE]: () => {return {value: action.value}},
        [actionType.CART_NEEDS_BILLING]: () => {return {value: action.value}},
        [actionType.CART_SET_TOTALS]: () => {return {value: action.payload}},
        [actionType.CART_CAN_SUBMIT]: () => {return {value: null}},
        [actionType.CART_PAY]: () => {return {value: null}},
        [actionType.CART_PAID]: () => {return {value: action.message}},
        [actionType.PERSONAL_SET_COUNTRY]: () => {return {value: action.payload}},
        [actionType.PERSONAL_SET_CURRENCY]: () => {return {value: action.currency_code}},
        [actionType.ADDRESS_OPTIONS_CREATE]: () => {return {value: action.payload}},
        [actionType.ADDRESS_AVAILABLE_OPTIONS_SET]: () => {return {value: null}},
        [actionType.ADDRESS_SHOULD_UPDATE_AVAILABLE_OPTIONS]: () => {return {value: null}},
        [actionType.LOGIN]: () => {return {value: null}},
        [actionType.SIGNIN]: () => {return {value: null}},
        [actionType.UPDATE_STEP]: () => {return {key: action.key, value: action.value}},
        [actionType.CHANGE_STEP]: () => {return {value: action.payload}},
        [actionType.COUPON_GET]: () => {return {value: action.code}},
        [actionType.COUPON_ADD]: () => {return {value: action.id}},
        [actionType.COUPON_REMOVE]: () => {return {value: null}},
        [actionType.ALERT_OPEN]: () => {return {value: action.message}},
        [actionType.ALERT_CLOSE]: () => {return {value: null}},
        'default': () => {return {value: null}},
    };
    return (actions[action.type] || actions['default'])();
};

const sendBugSnagError = (name, message, metadata, severity) => bugsnagClient.notify(new Error(name), {name, message, metadata, severity});

const sendBugSnagBreadcrumb = (name, metadata) => bugsnagClient.leaveBreadcrumb(name, metadata);


const sendBugSnagBreadCrumbs = (action, state) => {

    const metadata = selectState(action, state);
    sendBugSnagBreadcrumb(action.type, metadata);
};



const loggerMiddleware = store => next => action => {
    let loggedActions = [
        PERSONAL_SET_COUNTRY_ERROR,
        CART_SET_CURRENCY_ERROR,
        CART_ADD_ERROR,
        CARD_ADD_ERROR,
        CART_PAY_ERROR,
        COUPON_ADD_ERROR,
        LOGIN_ERROR,
        UNDEFINED_ERROR
    ];

    sendBugSnagBreadCrumbs(action, filterState(store.getState()));

    let shouldLog = _findIndex(loggedActions, function(o) { return o === action.type; });

    if (shouldLog >= 0)
    {
        sendLog('error', action.type);

        switch(action.type) {
            case PERSONAL_SET_COUNTRY_ERROR:
            case COUPON_ADD_ERROR:
                sendBugSnagError(action.type, action.type, action, 'info');
                break;
            case CART_ADD_ERROR:
                sendBugSnagError(action.type, action.message, action.metadata, 'info');
                break;
            default:
                sendBugSnagError(action.type, action.type, action, 'error');
        }

        if (action.type === CARD_ADD_ERROR) {
            if (!_.has(action.error, 'response.data.data.error')) {
                sendLog('info', action.error.response);
            }
        }

        next(action);

        sendLog('info', filterState(store.getState()));

        return true;

    }else{
        next(action);
    }
};

export default loggerMiddleware

