import 'core-js/modules/es.object.assign';
import 'core-js/modules/web.url';
import 'core-js/features/promise';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { ErrorBoundary } from './services/bugSnag';


ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
    document.getElementById('root'),
);
