import React, { Component } from "react";
import { connect } from "react-redux";
import muiThemeable from "material-ui/styles/muiThemeable";

import ErrorBoundary from "react-error-boundary";

import { validateField, validateForm } from "../../services/validate";

import AddressForm from "../presentational/addressForm";
import ShippingOption from "../presentational/shippingOption";

//Actions
import {
  ADDRESS_UPDATE_INFO,
  ADDRESS_OPTIONS_CREATE,
  ADDRESS_OPTIONS_TRACKING,
  UPDATE_STEP,
  CART_SHOULD_UPDATE_TOTALS,
  PERSONAL_SET_CURRENCY,
} from "../../actions/actionTypes";
import PhoneTrackingForm from "../presentational/phoneTrackingForm";
import ShippingNameForm from "../presentational/shippingNameForm";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  onUpdateAddressInfo: (key, value) => dispatch({ type: ADDRESS_UPDATE_INFO, address_type: "shipping", key, value }),
  onUpdateStep: (key, value) => dispatch({ type: UPDATE_STEP, key, value }),
  onChangeShippingOption: (option) => {
    dispatch({ type: ADDRESS_OPTIONS_CREATE, payload: option });
    dispatch({ type: CART_SHOULD_UPDATE_TOTALS });
  },
  onChangeTrackingNumber: (key, value) => {
    dispatch({ type: ADDRESS_OPTIONS_TRACKING, key, value });
  },
  forceChangeCurrency: () => {
    dispatch({ type: PERSONAL_SET_CURRENCY, currency_code: "GBP", forced: true });
  },
});

const MyFallbackComponent = ({ componentStack, error }) => (
  <p>There are no valid shipping options for your Country / Currency</p>
);

class Shipping extends Component {
  state = {
    countryName: "",
    needs_billing: false,
    errors: {
      first_name: false,
      last_name: false,
      line_1: false,
      city: false,
      zip: false,
      country: false,
      tracking_number: false,
    },
    rules: {
      first_name: {
        required: "First name is required",
      },
      last_name: {
        required: "Last name is required",
      },
      line_1: {
        required: "Address is required",
        maxCharacters: "Line 1 cannot have more than 35 characters",
      },
      city: {
        required: "City is required",
      },
      zip: {
        required: "ZIP / Postal code is required",
      },
      country: {
        required: "Country is required",
      },
    },
  };

  componentWillReceiveProps(nextProps) {
    let equal = JSON.stringify(nextProps.address.shipping) === JSON.stringify(this.props.address.shipping);
    if (!equal) {
      this.validateShipping(nextProps);
    }
    let equal2 = JSON.stringify(nextProps.shipping) === JSON.stringify(this.props.shipping);
    if (!equal2) {
      this.validateShippingOptions(nextProps);
    }
  }

  getStyles = () => this.props.muiTheme.address;

  handleAddressUpdate = (e, value) => {
    const name = e.target.name;
    this.handleFieldUpdate(name, value);
  };

  handleFieldUpdate = (name, value) => {
    const rules = this.state.rules[name];

    this.props.onUpdateAddressInfo(name, value);
    let is_valid = validateField(name, rules, value, null);
    this.setState((prevState) => ({
      errors: { ...prevState.errors, [name]: is_valid },
    }));
  };

  handleTrackingNumberUpdate = (name, value) => {
    this.props.onChangeTrackingNumber("number", value);
  };

  handleShippingOptionChange = (seller, option) => {
    this.props.onChangeShippingOption(seller, option);
  };

  validateShipping = (nextProps) => {
    const isValid = validateForm(this.state.errors);

    const { first_name, last_name, line_1, city, zip, country } = nextProps.address.shipping;

    if (isValid && first_name && last_name && line_1 && city && zip && country) {
      this.props.onUpdateStep("shippingAddress", true);
    } else if (nextProps.common.stepReady.shippingAddress === true) {
      this.props.onUpdateStep("shippingAddress", false);
    }
  };

  validateShippingOptions = (props) => {
    const is_valid = props.shipping.selectedOption > 0;

    if (is_valid && !props.common.stepReady.shippingOptions) {
      props.onUpdateStep("shippingOptions", true);
    } else if (!is_valid && props.common.stepReady.shippingOptions) {
      props.onUpdateStep("shippingOptions", false);
    }
  };

  render() {
    const styles = this.getStyles();
    return (
      <div>
        <p className="subtitle">Shipping Address</p>
        <p className="red">Please double check your address to ensure you receive the products</p>
        <ShippingNameForm
          updateField={this.handleAddressUpdate}
          {...this.props.address.shipping}
          errors={this.state.errors}
        />
        <AddressForm
          handleAddressUpdate={this.handleAddressUpdate}
          handleFieldUpdate={this.handleFieldUpdate}
          {...this.props.address.shipping}
          styles={styles}
          errors={this.state.errors}
        />
        <p className="subtitle">Choose your shipping option</p>
        <ErrorBoundary FallbackComponent={MyFallbackComponent}>
          <ShippingOption
            shippingOptions={{
              availableOptions: this.props.shipping.availableOptions,
              fetchedShippingZones: this.props.shipping.fetchedShippingZones,
            }}
            selected={this.props.shipping.selectedOption}
            onSelect={this.handleShippingOptionChange}
            currency={this.props.personal.currency_code}
            cart={this.props.cart}
          />
        </ErrorBoundary>
        <p className="subtitle">Mobile number for dispatch SMS</p>
        <PhoneTrackingForm
          handleTrackingNumberUpdate={this.handleTrackingNumberUpdate}
          tracking_number={this.props.shipping.trackingPhone.number}
          styles={styles}
          errors={this.state.errors}
        />
      </div>
    );
  }
}

export default muiThemeable()(connect(mapStateToProps, mapDispatchToProps)(Shipping));
