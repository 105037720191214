import React, {Component} from 'react';

import Products from '../containers/products';
import Coupon from '../containers/coupons';
import Totals from './totals';

class PreviewSidebar extends Component {

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }


    render() {
        if (!(this.props.byId)) {
            return (
                <div className="sidebarRight">
                    <div className="sidebarRight__container">
                        <h1>Preparing order...</h1>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="sidebarRight">
                    <div className="sidebarRight__container">
                        <Products/>
                        <hr/>
                        <Coupon/>
                        <Totals {...this.props.totals} currency={this.props.currency}/>
                    </div>
                </div>

            )
        }

    }
}

export default PreviewSidebar;