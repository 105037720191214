import React, {Component} from 'react';
import { FormattedMessage } from 'react-intl';

//Lodash
import _map from 'lodash/map';

import Currency from 'react-currency-formatter';

const shippingLines = (shipping_lines, currency) => {
    return _map(shipping_lines, (line, key) => (
            <div key={key} className="totals__subtotal">
                <span>Shipping - {line.name}</span>
                <span className="totals__price">
                       <Currency quantity={line.price / 100} currency={currency} />
                   </span>
            </div>
        )
    )
};

class Totals extends Component {

    render() {
        return (
            <div className="totals">
                <div className="totals__subtotal">
                    <FormattedMessage
                        id="checkout.subtotal"
                        defaultMessage="Subtotal"
                    />
                    <span className="totals__price">
                       <Currency quantity={this.props.subtotal / 100} currency={this.props.currency}/>
                   </span>
                </div>
                {shippingLines(this.props.shipping_lines, this.props.currency)}
                {(this.props.discount > 0) &&
                <div className="totals__subtotal">
                    Coupon discount
                    <span className="totals__price">
                       - <Currency quantity={this.props.discount / 100} currency={this.props.currency}/>
                   </span>
                </div>
                }
                {(!!this.props.showTax && this.props.tax === null) &&
                <div className="totals__tax">
                    Tax
                    <span className="totals__price">
                       TBD
                   </span>
                </div>
                }
                {(!!this.props.showTax && this.props.tax !== null) &&
                <div className="totals__tax">
                    Tax
                    <span className="totals__price">
                       <Currency quantity={this.props.tax / 100} currency={this.props.currency}/>
                   </span>
                </div>
                }
                <hr/>
                <div className="totals__total">
                    <FormattedMessage
                        id="checkout.total"
                        defaultMessage="Order Total"
                    />
                    <span className="totals__price">
                       <Currency quantity={this.props.total / 100} currency={this.props.currency}/>
                   </span>
                </div>
            </div>
        )

    }
}

export default Totals;
