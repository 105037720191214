import React from 'react';
import { FormattedMessage } from 'react-intl';

const GadzhiThankYou = () => (
    <div className="thank-you">{``}
        <div className="mainDesktop__container">
            <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png" alt=""/>
                <div><h1>
                    <FormattedMessage
                        id="checkout.thankYou"
                        defaultMessage="Thank you!"
                    />
                </h1>
                <img className="logo-top" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/gadzhi-black.png" alt=""/>
                </div>
                <br />
                <p><strong>
                    <FormattedMessage
                        id="checkout.paymentProcessed"
                        defaultMessage="Your payment has been processed successfully."
                    />
                </strong></p>
                <p><strong>
                    <FormattedMessage
                        id="checkout.receiveConfirmationEmail"
                        defaultMessage="You will receive an order confirmation email with all the details included in your order."
                    />
                </strong></p>
                <p>
                    <FormattedMessage
                        id="checkout.checkSpam"
                        defaultMessage="Please, check your spam or junk folders if it's not in your inbox."
                    />
                </p>
                <br />
                <p className="problem-line">
                    <FormattedMessage
                        id="checkout.needHelp"
                        defaultMessage="Need Help? Visit > "
                    />
                    <a href="https://support.gadzhi.com/hc">
                        <FormattedMessage
                            id="checkout.needHelpLink"
                            defaultMessage="Customer Support"
                        />
                    </a>
                </p>
                {/* <p className="aflete-badge"><img src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/powered-black.png" alt=""/></p>     */}
        </div>
    </div>
);

export default GadzhiThankYou;
