import React, {Component} from 'react';
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField';

class PhoneTrackingForm extends Component {
    render() {
        return (
            <div>
                <TextField
                    name="tracking_number"
                    ref="tracking_number"
                    id="tracking_number"
                    className="tracking_number"
                    defaultValue={this.props.tracking_number}
                    hintText="+44 123 456 7891"
                    type="text"
                    autoComplete="off"
                    errorText={this.props.errors.tracking_number}
                    onChange={this.props.handleTrackingNumberUpdate}
                />
                <small className='tracking_number_small_text'>Please enter your full phone number including country code</small>
            </div>
        )
    }
}

PhoneTrackingForm.propTypes = {
    handleTrackingNumberUpdate: PropTypes.func.isRequired,
    tracking_number: PropTypes.string.isRequired,
    styles: PropTypes.object,
    errors: PropTypes.object
};

export default PhoneTrackingForm