import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import { FormattedMessage } from 'react-intl';


import {validateField, validateForm} from "../../services/validate";

import AddressForm from '../presentational/addressForm';
import Checkbox from 'material-ui/Checkbox';

//Actions
import {
    ADDRESS_UPDATE_INFO,
    CART_NEEDS_BILLING,
    UPDATE_STEP
} from '../../actions/actionTypes';

const mapStateToProps = state => (state);

const mapDispatchToProps = dispatch => ({
    onUpdateAddressInfo: (key, value) =>
        dispatch({type: ADDRESS_UPDATE_INFO, address_type:"billing" ,key, value}),
    onUpdateStep: (key, value) =>
        dispatch({type: UPDATE_STEP, key, value}),
    onSwitchBilling: (value) =>
        dispatch({type: CART_NEEDS_BILLING, value: value}),
});

class Billing extends Component {

    state = {
        needs_billing: false,
        errors: {
            line_1: false,
            city: false,
            zip: false,
            country: false,
        },
        rules: {
            line_1: {
                required: 'Address is required'
            },
            city: {
                required: 'City is required'
            },
            zip: {
                required: 'ZIP / Postal code is required',
            },
            country: {
                required: 'Country is required',
            },
        }
    };

    componentWillReceiveProps(nextProps) {
        let equal = JSON.stringify(nextProps.address.billing) === JSON.stringify(this.props.address.billing);
        if (!equal) {
            this.validateBilling(nextProps);
        }
    }

    getStyles = () => this.props.muiTheme.address;

    switchBilling = (event, isInputChecked) => this.props.onSwitchBilling(isInputChecked);

    handleAddressUpdate = (e, value) => {
        const name = e.target.name;
        this.handleFieldUpdate(name, value);
    };

    handleFieldUpdate = (name, value) => {
        const rules = this.state.rules[name];

        this.props.onUpdateAddressInfo(name, value);
        let is_valid = validateField(name, rules, value, null);
        this.setState(prevState => ({
            errors: {...prevState.errors, [name]: is_valid}
        }));
    };

    validateBilling = (nextProps) => {
        const isValid = validateForm(this.state.errors);

        const { line_1, city, zip, country } = nextProps.address.billing;

        if (isValid && line_1 && city && zip && country) {
            this.props.onUpdateStep('billingAddress', true);
        } else if (nextProps.common.stepReady.billingAddress === true) {
            this.props.onUpdateStep('billingAddress', false);
        }
    };

    render() {

        const styles = this.getStyles();

        return (
            <div className="spacing">
                {!this.props.common.isDigital &&
                    <Checkbox
                        label="My billing address is different"
                        checked={this.props.common.needs_billing}
                        onCheck={this.switchBilling}
                        style={styles.checkbox}
                    />
                }
                {this.props.common.needs_billing &&
                    <Fragment>
                        <p className="subtitle">
                            <FormattedMessage
                                id="checkout.billingAddress"
                                defaultMessage="Billing Address"
                            />
                        </p>
                        <AddressForm
                            handleAddressUpdate={this.handleAddressUpdate}
                            handleFieldUpdate={this.handleFieldUpdate}
                            {...this.props.address.billing}
                            styles={styles}
                            errors={this.state.errors}
                        />
                    </Fragment>
                }
            </div>

        )
    }
}

export default muiThemeable()(connect(mapStateToProps, mapDispatchToProps)(Billing));
