import {
    ADDRESS_UPDATE_INFO
} from '../actions/actionTypes';

const defaultState = {
    shipping:{
        first_name: "",
        last_name: "",
        line_1: "",
        line_2: "",
        state: "",
        city: "",
        zip: "",
        country: ""
    },
    billing:{
        line_1: "",
        line_2: "",
        state: "",
        city: "",
        zip: "",
        country: ""
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ADDRESS_UPDATE_INFO:
            return { ...state, [action.address_type]: {...state[action.address_type], [action.key]: action.value }};
        default:
            return state;
    }
};
