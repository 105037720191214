import axios from "axios";

import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';

export default {
    getClientCountry: function () {
        const instance = axios.create({
            timeout: 1000
        });
        return instance.get('https://ipinfo.io/json?token=5def939413eff3')
            .then(
                response => {
                    return {country: response.data.country}
                },
                error => {
                    return instance.get('https://freegeoip.net/json/')
                        .then(response => {
                            return {country: response.data.country_code}
                        })
                }
            );
    },
    getCountryByCountryCode: function (countryCode) {
        if (countryCode) {
            return _find(this.countries, function (o) {
                return o.code === countryCode
            }).name;
        }
    },
    getPhoneCodeByCountryCode: function (countryCode) {
        if (countryCode){
            return _find(this.countries, function(o) { return o.code === countryCode}).phoneCode;
        }
    },
    listCountries: function (transformer, includePrimary = null) {

        let countries = this.countries;
        if (includePrimary === true)
            countries = _filter(this.countries, (country) => {
                return !_includes(this.primaryCountries, country.code)
            });
        if (includePrimary === false)
            countries = _filter(this.countries, (country) => {
                return _includes(this.primaryCountries, country.code)
            });
        return countries.map(transformer);
    },
    defaultCurrencies: ['GBP', 'EUR', 'USD', 'TWD', 'CAD'],
    primaryCountries: ['GB', 'US'],

    countries : [
        {
            "code": "AL",
            "name": {
                "id":"countries.albania",
                "defaultMessage":"Albania"
            },
            "phoneCode": "355"
        },
        {
            "code": "DZ",
            "name": {
                "id":"countries.algeria",
                "defaultMessage":"Algeria"
            },
            "phoneCode": "213"
        },
        {
            "code": "DS",
            "name": {
                "id":"countries.americanSamoa",
                "defaultMessage":"American Samoa"
            },
            "phoneCode": "1"
        },
        {
            "code": "AD",
            "name": {
                "id":"countries.andorra",
                "defaultMessage":"Andorra"
            },
            "phoneCode": "376"
        },
        {
            "code": "AO",
            "name": {
                "id":"countries.angola",
                "defaultMessage":"Angola"
            },
            "phoneCode": "244"
        },
        {
            "code": "AI",
            "name": {
                "id":"countries.anguilla",
                "defaultMessage":"Anguilla"
            },
            "phoneCode": "264"
        },
        {
            "code": "AG",
            "name": {
                "id":"countries.antiguaAndBarbuda",
                "defaultMessage":"Antigua and Barbuda"
            },
            "phoneCode": "268"
        },
        {
            "code": "AR",
            "name": {
                "id":"countries.argentina",
                "defaultMessage":"Argentina"
            },
            "phoneCode": "54"
        },
        {
            "code": "AM",
            "name": {
                "id":"countries.armenia",
                "defaultMessage":"Armenia"
            },
            "phoneCode": "374"
        },
        {
            "code": "AW",
            "name": {
                "id":"countries.aruba",
                "defaultMessage":"Aruba"
            },
            "phoneCode": "297"
        },
        {
            "code": "AU",
            "name": {
                "id":"countries.australia",
                "defaultMessage":"Australia"
            },
            "phoneCode": "61"
        },
        {
            "code": "AT",
            "name": {
                "id":"countries.austria",
                "defaultMessage":"Austria"
            },
            "phoneCode": "43"
        },
        {
            "code": "AZ",
            "name": {
                "id":"countries.azerbaijan",
                "defaultMessage":"Azerbaijan"
            },
            "phoneCode": "994"
        },
        {
            "code": "BS",
            "name": {
                "id":"countries.bahamas",
                "defaultMessage":"Bahamas"
            },
            "phoneCode": "242"
        },
        {
            "code": "BH",
            "name": {
                "id":"countries.bahrain",
                "defaultMessage":"Bahrain"
            },
            "phoneCode": "973"
        },
        {
            "code": "BD",
            "name": {
                "id":"countries.bangladesh",
                "defaultMessage":"Bangladesh"
            },
            "phoneCode": "880"
        },
        {
            "code": "BB",
            "name": {
            "id":"countries.barbados",
            "defaultMessage":"Barbados"
        },
            "phoneCode": "246"
        },
        {
            "code": "BE",
            "name": {
                "id":"countries.belgium",
                "defaultMessage":"Belgium"
            },
            "phoneCode": "32"
        },
        {
            "code": "BZ",
            "name": {
                "id":"countries.belize",
                "defaultMessage":"Belize"
            },
            "phoneCode": "501"
        },
        {
            "code": "BJ",
            "name": {
                "id":"countries.benin",
                "defaultMessage":"Benin"
            },
            "phoneCode": "229"
        },
        {
            "code": "BM",
            "name": {
                "id":"countries.bermuda",
                "defaultMessage":"Bermuda"
            },
            "phoneCode": "441"
        },
        {
            "code": "BT",
            "name": {
                "id":"countries.bhutan",
                "defaultMessage":"Bhutan"
            },
            "phoneCode": "975"
        },
        {
            "code": "BO",
            "name": {
                "id":"countries.bolivia",
                "defaultMessage":"Bolivia"
            },
            "phoneCode": "591"
        },
        {
            "code": "BA",
            "name": {
                "id":"countries.bosniaAndHerzegovina",
                "defaultMessage":"Bosnia and Herzegovina"
            },
            "phoneCode": "387"
        },
        {
            "code": "BW",
            "name": {
                "id":"countries.botswana",
                "defaultMessage":"Botswana"
            },
            "phoneCode": "267"
        },
        {
            "code": "BR",
            "name": {
                "id":"countries.brazil",
                "defaultMessage":"Brazil"
            },
            "phoneCode": "55"
        },
        {
            "code": "IO",
            "name": {
                "id":"countries.britishIndianOceanTerritory",
                "defaultMessage":"British Indian Ocean Territory"
            },
            "phoneCode": "1"
        },
        {
            "code": "BN",
            "name": {
                "id":"countries.bruneiDarussalam",
                "defaultMessage":"Brunei Darussalam"
            },
            "phoneCode": "673"
        },
        {
            "code": "BG",
            "name": {
                "id":"countries.bulgaria",
                "defaultMessage":"Bulgaria"
            },
            "phoneCode": "359"
        },
        {
            "code": "BF",
            "name": {
                "id":"countries.burkinaFaso",
                "defaultMessage":"Burkina Faso"
            },
            "phoneCode": "226"
        },
        {
            "code": "BI",
            "name": {
                "id":"countries.burundi",
                "defaultMessage":"Burundi"
            },
            "phoneCode": "257"
        },
        {
            "code": "KH",
            "name": {
                "id":"countries.cambodia",
                "defaultMessage":"Cambodia"
            },
            "phoneCode": "855"
        },
        {
            "code": "CM",
            "name": {
                "id":"countries.cameroon",
                "defaultMessage":"Cameroon"
            },
            "phoneCode": "237"
        },
        {
            "code": "CA",
            "name": {
                "id":"countries.canada",
                "defaultMessage":"Canada"
            },
            "phoneCode": "1"
        },
        {
            "code": "CV",
            "name": {
                "id":"countries.capeVerde",
                "defaultMessage":"Cape Verde"
            },
            "phoneCode": "238"
        },
        {
            "code": "KY",
            "name": {
                "id":"countries.caymanIslands",
                "defaultMessage":"Cayman Islands"
            },
            "phoneCode": "345"
        },
        {
            "code": "CF",
            "name": {
                "id":"countries.centralAfricanRepublic",
                "defaultMessage":"Central African Republic"
            },
            "phoneCode": "1"
        },
        {
            "code": "TD",
            "name": {
                "id":"countries.chad",
                "defaultMessage":"Chad"
            },
            "phoneCode": "235"
        },
        {
            "code": "CL",
            "name": {
                "id":"countries.chile",
                "defaultMessage":"Chile"
            },
            "phoneCode": "56"
        },
        {
            "code": "CN",
            "name": {
                "id":"countries.china",
                "defaultMessage":"China"
            },
            "phoneCode": "1"
        },
        {
            "code": "CX",
            "name": {
                "id":"countries.christmasIsland",
                "defaultMessage":"Christmas Island"
            },
            "phoneCode": "1"
        },
        {
            "code": "CO",
            "name": {
                "id":"countries.colombia",
                "defaultMessage":"Colombia"
            },
            "phoneCode": "57"
        },
        {
            "code": "KM",
            "name": {
                "id":"countries.comoros",
                "defaultMessage":"Comoros"
            },
            "phoneCode": "269"
        },
        {
            "code": "CK",
            "name": {
                "id":"countries.cookIslands",
                "defaultMessage":"Cook Islands"
            },
            "phoneCode": "682"
        },
        {
            "code": "CR",
            "name": {
                "id":"countries.costaRica",
                "defaultMessage":"Costa Rica"
            },
            "phoneCode": "506"
        },
        {
            "code": "HR",
            "name": {
                "id":"countries.croatia",
                "defaultMessage":"Croatia"
            },
            "phoneCode": "385"
        },
        {
            "code": "CY",
            "name": {
                "id":"countries.cyprus",
                "defaultMessage":"Cyprus"
            },
            "phoneCode": "1"
        },
        {
            "code": "CZ",
            "name": {
                "id":"countries.czechRepublic",
                "defaultMessage":"Czech Republic"
            },
            "phoneCode": "420"
        },
        {
            "code": "DK",
            "name": {
                "id":"countries.denmark",
                "defaultMessage":"Denmark"
            },
            "phoneCode": "45"
        },
        {
            "code": "DJ",
            "name": {
                "id":"countries.djibouti",
                "defaultMessage":"Djibouti"
            },
            "phoneCode": "253"
        },
        {
            "code": "DM",
            "name": {
                "id":"countries.dominica",
                "defaultMessage":"Dominica"
            },
            "phoneCode": "767"
        },
        {
            "code": "DO",
            "name": {
                "id":"countries.dominicanRepublic",
                "defaultMessage":"Dominican Republic"
            },
            "phoneCode": "809"
        },
        {
            "code": "EC",
            "name": {
                "id":"countries.ecuador",
                "defaultMessage":"Ecuador"
            },
            "phoneCode": "593"
        },
        {
            "code": "EG",
            "name": {
                "id":"countries.egypt",
                "defaultMessage":"Egypt"
            },
            "phoneCode": "20"
        },
        {
            "code": "SV",
            "name": {
                "id":"countries.elSalvador",
                "defaultMessage":"El Salvador"
            },
            "phoneCode": "503"
        },
        {
            "code": "GQ",
            "name": {
                "id":"countries.equatorialGuinea",
                "defaultMessage":"Equatorial Guinea"
            },
            "phoneCode": "240"
        },
        {
            "code": "EE",
            "name": {
                "id":"countries.estonia",
                "defaultMessage":"Estonia"
            },
            "phoneCode": "372"
        },
        {
            "code": "ET",
            "name": {
                "id":"countries.ethiopia",
                "defaultMessage":"Ethiopia"
            },
            "phoneCode": "251"
        },
        {
            "code": "FK",
            "name": {
                "id":"countries.ethiopia",
                "defaultMessage":"Falkland Islands (Malvinas)"
            },
            "phoneCode": "500"
        },
        {
            "code": "FO",
            "name": {
                "id":"countries.faroeIslands",
                "defaultMessage":"Faroe Islands"
            },
            "phoneCode": "298"
        },
        {
            "code": "FI",
            "name": {
                "id":"countries.finland",
                "defaultMessage":"Finland"
            },
            "phoneCode": "358"
        },
        {
            "code": "FR",
            "name": {
                "id":"countries.france",
                "defaultMessage":"France"
            },
            "phoneCode": "33"
        },
        {
            "code": "GF",
            "name": {
                "id":"countries.frenchGuiana",
                "defaultMessage":"French Guiana"
            },
            "phoneCode": "594"
        },
        {
            "code": "PF",
            "name": {
                "id":"countries.frenchPolynesia",
                "defaultMessage":"French Polynesia"
            },
            "phoneCode": "689"
        },
        {
            "code": "GA",
            "name": {
                "id":"countries.gabon",
                "defaultMessage":"Gabon"
            },
            "phoneCode": "241"
        },
        {
            "code": "GM",
            "name": {
                "id":"countries.gambia",
                "defaultMessage":"Gambia"
            },
            "phoneCode": "220"
        },
        {
            "code": "GE",
            "name": {
                "id":"countries.georgia",
                "defaultMessage":"Georgia"
            },
            "phoneCode": "995"
        },
        {
            "code": "DE",
            "name": {
                "id":"countries.germany",
                "defaultMessage":"Germany"
            },
            "phoneCode": "49"
        },
        {
            "code": "GH",
            "name": {
                "id":"countries.ghana",
                "defaultMessage":"Ghana"
            },
            "phoneCode": "233"
        },
        {
            "code": "GI",
            "name": {
                "id":"countries.gibraltar",
                "defaultMessage":"Gibraltar"
            },
            "phoneCode": "350"
        },
        {
            "code": "GR",
            "name": {
                "id":"countries.greece",
                "defaultMessage":"Greece"
            },
            "phoneCode": "30"
        },
        {
            "code": "GL",
            "name": {
                "id":"countries.greenland",
                "defaultMessage":"Greenland"
            },
            "phoneCode": "299"
        },
        {
            "code": "GD",
            "name": {
                "id":"countries.grenada",
                "defaultMessage":"Grenada"
            },
            "phoneCode": "473"
        },
        {
            "code": "GP",
            "name": {
                "id":"countries.guadeloupe",
                "defaultMessage":"Guadeloupe"
            },
            "phoneCode": "590"
        },
        {
            "code": "GT",
            "name": {
                "id":"countries.guatemala",
                "defaultMessage":"Guatemala"
            },
            "phoneCode": "502"
        },
        {
            "code": "GG",
            "name": {
                "id":"countries.guernsey",
                "defaultMessage":"Guernsey"
            },
            "phoneCode": "44"
        },
        {
            "code": "GN",
            "name": {
                "id":"countries.guinea",
                "defaultMessage":"Guinea"
            },
            "phoneCode": "224"
        },
        {
            "code": "GW",
            "name": {
                "id":"countries.guineaBissau",
                "defaultMessage":"Guinea-Bissau"
            },
            "phoneCode": "245"
        },
        {
            "code": "GY",
            "name": {
                "id":"countries.guyana",
                "defaultMessage":"Guyana"
            },
            "phoneCode": "592"
        },
        {
            "code": "HN",
            "name": {
                "id":"countries.honduras",
                "defaultMessage":"Honduras"
            },
            "phoneCode": "504"
        },
        {
            "code": "HK",
            "name": {
                "id":"countries.hongKong",
                "defaultMessage":"Hong Kong"
            },
            "phoneCode": "852"
        },
        {
            "code": "HU",
            "name": {
                "id":"countries.hungary",
                "defaultMessage":"Hungary"
            },
            "phoneCode": "36"
        },
        {
            "code": "IS",
            "name": {
                "id":"countries.iceland",
                "defaultMessage":"Iceland"
            },
            "phoneCode": "354"
        },
        {
            "code": "IN",
            "name": {
                "id":"countries.india",
                "defaultMessage":"India"
            },
            "phoneCode": "91"
        },
        {
            "code": "ID",
            "name": {
                "id":"countries.indonesia",
                "defaultMessage":"Indonesia"
            },
            "phoneCode": "62"
        },
        {
            "code": "IE",
            "name": {
                "id":"countries.ireland",
                "defaultMessage":"Ireland"
            },
            "phoneCode": "353"
        },
        {
            "code": "IM",
            "name": {
                "id":"countries.isleOfMan",
                "defaultMessage":"Isle of Man"
            },
            "phoneCode": "44"
        },
        {
            "code": "IL",
            "name": {
                "id":"countries.israel",
                "defaultMessage":"Israel"
            },
            "phoneCode": "972"
        },
        {
            "code": "IT",
            "name": {
                "id":"countries.italy",
                "defaultMessage":"Italy"
            },
            "phoneCode": "39"
        },
        {
            "code": "JM",
            "name": {
                "id":"countries.jamaica",
                "defaultMessage":"Jamaica"
            },
            "phoneCode": "876"
        },
        {
            "code": "JP",
            "name": {
                "id":"countries.japan",
                "defaultMessage":"Japan"
            },
            "phoneCode": "81"
        },
        {
            "code": "JE",
            "name": {
                "id":"countries.japan",
                "defaultMessage":"Jersey"
            },
            "phoneCode": "44"
        },
        {
            "code": "JO",
            "name": {
                "id":"countries.jordan",
                "defaultMessage":"Jordan"
            },
            "phoneCode": "962"
        },
        {
            "code": "KZ",
            "name": {
                "id":"countries.kazakhstan",
                "defaultMessage":"Kazakhstan"
            },
            "phoneCode": "7"
        },
        {
            "code": "KE",
            "name": {
                "id":"countries.kenya",
                "defaultMessage":"Kenya"
            },
            "phoneCode": "254"
        },
        {
            "code": "KI",
            "name": {
                "id":"countries.kiribati",
                "defaultMessage":"Kiribati"
            },
            "phoneCode": "686"
        },
        {
            "code": "KR",
            "name": {
                "id":"countries.korea",
                "defaultMessage":"Korea, Republic of"
            },
            "phoneCode": "82"
        },
        {
            "code": "KW",
            "name": {
                "id":"countries.kuwait",
                "defaultMessage":"Kuwait"
            },
            "phoneCode": "965"
        },
        {
            "code": "KG",
            "name": {
                "id":"countries.kyrgyzstan",
                "defaultMessage":"Kyrgyzstan"
            },
            "phoneCode": "996"
        },
        {
            "code": "LA",
            "name": {
                "id":"countries.laos",
                "defaultMessage":"Lao People's Democratic Republic"
            },
            "phoneCode": "856"
        },
        {
            "code": "LV",
            "name": {
                "id":"countries.latvia",
                "defaultMessage":"Latvia"
            },
            "phoneCode": "371"
        },
        {
            "code": "LB",
            "name": {
                "id":"countries.lebanon",
                "defaultMessage":"Lebanon"
            },
            "phoneCode": "1"
        },
        {
            "code": "LK",
            "name": {
                "id":"countries.srilanka",
                "defaultMessage":"Sri Lanka"
            },
            "phoneCode": "94"
        },
        {
            "code": "LS",
            "name": {
                "id":"countries.lesotho",
                "defaultMessage":"Lesotho"
            },
            "phoneCode": "266"
        },
        {
            "code": "LI",
            "name": {
                "id":"countries.liechtenstein",
                "defaultMessage":"Liechtenstein"
            },
            "phoneCode": "423"
        },
        {
            "code": "LT",
            "name": {
                "id":"countries.lithuania",
                "defaultMessage":"Lithuania"
            },
            "phoneCode": "370"
        },
        {
            "code": "LU",
            "name": {
                "id":"countries.luxembourg",
                "defaultMessage":"Luxembourg"
            },
            "phoneCode": "352"
        },
        {
            "code": "MO",
            "name": {
                "id":"countries.macao",
                "defaultMessage":"Macao"
            },
            "phoneCode": "853"
        },
        {
            "code": "MK",
            "name": {
                "id":"countries.macedonia",
                "defaultMessage":"Macedonia"
            },
            "phoneCode": "389"
        },
        {
            "code": "MG",
            "name": {
                "id":"countries.madagascar",
                "defaultMessage":"Madagascar"
            },
            "phoneCode": "261"
        },
        {
            "code": "MW",
            "name": {
                "id":"countries.malawi",
                "defaultMessage":"Malawi"
            },
            "phoneCode": "265"
        },
        {
            "code": "MY",
            "name": {
                "id":"countries.malaysia",
                "defaultMessage":"Malaysia"
            },
            "phoneCode": "60"
        },
        {
            "code": "MV",
            "name": {
                "id":"countries.maldives",
                "defaultMessage":"Maldives"
            },
            "phoneCode": "960"
        },
        {
            "code": "ML",
            "name": {
                "id":"countries.mali",
                "defaultMessage":"Mali"
            },
            "phoneCode": "223"
        },
        {
            "code": "MT",
            "name": {
                "id":"countries.malta",
                "defaultMessage":"Malta"
            },
            "phoneCode": "356"
        },
        {
            "code": "MH",
            "name": {
                "id":"countries.marshallIslands",
                "defaultMessage":"Marshall Islands"
            },
            "phoneCode": "692"
        },
        {
            "code": "MR",
            "name": {
                "id":"countries.mauritania",
                "defaultMessage":"Mauritania"
            },
            "phoneCode": "222"
        },
        {
            "code": "MU",
            "name": {
                "id":"countries.mauritius",
                "defaultMessage":"Mauritius"
            },
            "phoneCode": "230"
        },
        {
            "code": "YT",
            "name": {
                "id":"countries.mayotte",
                "defaultMessage":"Mayotte"
            },
            "phoneCode": "262"
        },
        {
            "code": "MX",
            "name": {
                "id":"countries.mexico",
                "defaultMessage":"Mexico"
            },
            "phoneCode": "52"
        },
        {
            "code": "FM",
            "name": {
                "id":"countries.micronesia",
                "defaultMessage":"Micronesia"
            },
            "phoneCode": "691"
        },
        {
            "code": "MD",
            "name": {
                "id":"countries.moldova",
                "defaultMessage":"Moldova"
            },
            "phoneCode": "373"
        },
        {
            "code": "MC",
            "name": {
                "id":"countries.monaco",
                "defaultMessage":"Monaco"
            },
            "phoneCode": "377"
        },
        {
            "code": "MN",
            "name": {
                "id":"countries.mongolia",
                "defaultMessage":"Mongolia"
            },
            "phoneCode": "976"
        },
        {
            "code": "ME",
            "name": {
                "id":"countries.montenegro",
                "defaultMessage":"Montenegro"
            },
            "phoneCode": "382"
        },
        {
            "code": "MS",
            "name": {
                "id":"countries.montserrat",
                "defaultMessage":"Montserrat"
            },
            "phoneCode": "664"
        },
        {
            "code": "MA",
            "name": {
                "id":"countries.morocco",
                "defaultMessage":"Morocco"
            },
            "phoneCode": "212"
        },
        {
            "code": "MZ",
            "name": {
                "id":"countries.mozambique",
                "defaultMessage":"Mozambique"
            },
            "phoneCode": "258"
        },
        {
            "code": "NA",
            "name": {
                "id":"countries.namibia",
                "defaultMessage":"Namibia"
            },
            "phoneCode": "264"
        },
        {
            "code": "NR",
            "name": {
                "id":"countries.nauru",
                "defaultMessage":"Nauru"
            },
            "phoneCode": "674"
        },
        {
            "code": "NP",
            "name": {
                "id":"countries.nepal",
                "defaultMessage":"Nepal"
            },
            "phoneCode": "977"
        },
        {
            "code": "NL",
            "name": {
                "id":"countries.netherlands",
                "defaultMessage":"Netherlands"
            },
            "phoneCode": "31"
        },
        {
            "code": "NC",
            "name": {
                "id":"countries.newCaledonia",
                "defaultMessage":"New Caledonia"
            },
            "phoneCode": "687"
        },
        {
            "code": "NZ",
            "name": {
                "id":"countries.newZealand",
                "defaultMessage":"New Zealand"
            },
            "phoneCode": "64"
        },
        {
            "code": "NI",
            "name": {
                "id":"countries.nicaragua",
                "defaultMessage":"Nicaragua"
            },
            "phoneCode": "505"
        },
        {
            "code": "NE",
            "name": {
                "id":"countries.niger",
                "defaultMessage":"Niger"
            },
            "phoneCode": "227"
        },
        {
            "code": "NG",
            "name": {
                "id":"countries.nigeria",
                "defaultMessage":"Nigeria"
            },
            "phoneCode": "234"
        },
        {
            "code": "NU",
            "name": {
                "id":"countries.niue",
                "defaultMessage":"Niue"
            },
            "phoneCode": "683"
        },
        {
            "code": "NO",
            "name": {
                "id":"countries.norway",
                "defaultMessage":"Norway"
            },
            "phoneCode": "47"
        },
        {
            "code": "OM",
            "name": {
                "id":"countries.oman",
                "defaultMessage":"Oman"
            },
            "phoneCode": "968"
        },
        {
            "code": "PK",
            "name": {
                "id":"countries.pakistan",
                "defaultMessage":"Pakistan"
            },
            "phoneCode": "92"
        },
        {
            "code": "PW",
            "name": {
                "id":"countries.palau",
                "defaultMessage":"Palau"
            },
            "phoneCode": "680"
        },
        {
            "code": "PS",
            "name": {
                "id":"countries.palestine",
                "defaultMessage":"Palestine"
            },
            "phoneCode": "970"
        },
        {
            "code": "PA",
            "name": {
                "id":"countries.panama",
                "defaultMessage":"Panama"
            },
            "phoneCode": "507"
        },
        {
            "code": "PG",
            "name": {
                "id":"countries.papuaNewGuinea",
                "defaultMessage":"Papua New Guinea"
            },
            "phoneCode": "675"
        },
        {
            "code": "PY",
            "name": {
                "id":"countries.paraguay",
                "defaultMessage":"Paraguay"
            },
            "phoneCode": "595"
        },
        {
            "code": "PE",
            "name": {
                "id":"countries.peru",
                "defaultMessage":"Peru"
            },
            "phoneCode": "51"
        },
        {
            "code": "PH",
            "name": {
                "id":"countries.philippines",
                "defaultMessage":"Philippines"
            },
            "phoneCode": "63"
        },
        {
            "code": "PL",
            "name": {
                "id":"countries.poland",
                "defaultMessage":"Poland"
            },
            "phoneCode": "48"
        },
        {
            "code": "PT",
            "name": {
                "id":"countries.portugal",
                "defaultMessage":"Portugal"
            },
            "phoneCode": "351"
        },
        {
            "code": "PR",
            "name": {
                "id":"countries.puertoRico",
                "defaultMessage":"Puerto Rico"
            },
            "phoneCode": "1"
        },
        {
            "code": "QA",
            "name": {
                "id":"countries.qatar",
                "defaultMessage":"Qatar"
            },
            "phoneCode": "974"
        },
        {
            "code": "RE",
            "name": {
                "id":"countries.reunion",
                "defaultMessage":"Réunion"
            },
            "phoneCode": "262"
        },
        {
            "code": "RO",
            "name": {
                "id":"countries.romania",
                "defaultMessage":"Romania"
            },
            "phoneCode": "40"
        },
        {
            "code": "RU",
            "name": {
                "id":"countries.russianFederation",
                "defaultMessage":"Russian Federation"
            },
            "phoneCode": "7"
        },
        {
            "code": "SH",
            "name": {
                "id":"countries.saintHelenaAscensionAndTristanDaCunha",
                "defaultMessage":"Saint Helena, Ascension and Tristan da Cunha"
            },
            "phoneCode": "290"
        },
        {
            "code": "KN",
            "name": {
                "id":"countries.saintKittsAndNevis",
                "defaultMessage":"Saint Kitts and Nevis"
            },
            "phoneCode": "869"
        },
        {
            "code": "LC",
            "name": {
                "id":"countries.saintLucia",
                "defaultMessage":"Saint Lucia"
            },
            "phoneCode": "758"
        },
        {
            "code": "PM",
            "name": {
                "id":"countries.saintPierreAndMiquelon",
                "defaultMessage":"Saint Pierre and Miquelon"
            },
            "phoneCode": "508"
        },
        {
            "code": "VC",
            "name": {
                "id":"countries.saintVincentAndTheGrenadines",
                "defaultMessage":"Saint Vincent and the Grenadines"
            },
            "phoneCode": "784"
        },
        {
            "code": "WS",
            "name": {
                "id":"countries.samoa",
                "defaultMessage":"Samoa"
            },
            "phoneCode": "685"
        },
        {
            "code": "SM",
            "name": {
                "id":"countries.sanMarino",
                "defaultMessage":"San Marino"
            },
            "phoneCode": "378"
        },
        {
            "code": "ST",
            "name": {
                "id":"countries.saoTomeAndPrincipe",
                "defaultMessage":"Sao Tome and Principe"
            },
            "phoneCode": "239"
        },
        {
            "code": "SA",
            "name": {
                "id":"countries.saudiArabia",
                "defaultMessage":"Saudi Arabia"
            },
            "phoneCode": "966"
        },
        {
            "code": "SN",
            "name": {
                "id":"countries.senegal",
                "defaultMessage":"Senegal"
            },
            "phoneCode": "221"
        },
        {
            "code": "RS",
            "name": {
                "id":"countries.serbia",
                "defaultMessage":"Serbia"
            },
            "phoneCode": "381"
        },
        {
            "code": "SC",
            "name": {
                "id":"countries.seychelles",
                "defaultMessage":"Seychelles"
            },
            "phoneCode": "248"
        },
        {
            "code": "SL",
            "name": {
                "id":"countries.sierraLeone",
                "defaultMessage":"Sierra Leone"
            },
            "phoneCode": "232"
        },
        {
            "code": "SG",
            "name": {
                "id":"countries.singapore",
                "defaultMessage":"Singapore"
            },
            "phoneCode": "65"
        },
        {
            "code": "SK",
            "name": {
                "id":"countries.slovakia",
                "defaultMessage":"Slovakia"
            },
            "phoneCode": "421"
        },
        {
            "code": "SI",
            "name": {
                "id":"countries.slovenia",
                "defaultMessage":"Slovenia"
            },
            "phoneCode": "386"
        },
        {
            "code": "SB",
            "name": {
                "id":"countries.solomonIslands",
                "defaultMessage":"Solomon Islands"
            },
            "phoneCode": "677"
        },
        {
            "code": "ZA",
            "name": {
                "id":"countries.southAfrica",
                "defaultMessage":"South Africa"
            },
            "phoneCode": "27"
        },
        {
            "code": "SS",
            "name": {
                "id":"countries.southSudan",
                "defaultMessage":"South Sudan"
            },
            "phoneCode": "2011"
        },
        {
            "code": "ES",
            "name": {
            "id":"countries.spain",
            "defaultMessage":"Spain"
        },
            "phoneCode": "34"
        },
        {
            "code": "SR",
            "name": {
                "id":"countries.suriname",
                "defaultMessage":"Suriname"
            },
            "phoneCode": "597"
        },
        {
            "code": "SZ",
            "name": {
                "id":"countries.swaziland",
                "defaultMessage":"Swaziland"
            },
            "phoneCode": "268"
        },
        {
            "code": "SE",
            "name": {
                "id":"countries.sweden",
                "defaultMessage":"Sweden"
            },
            "phoneCode": "46"
        },
        {
            "code": "CH",
            "name": {
                "id":"countries.switzerland",
                "defaultMessage":"Switzerland"
            },
            "phoneCode": "41"
        },
        {
            "code": "TW",
            "name": {
                "id":"countries.taiwan",
                "defaultMessage":"Taiwan"
            },
            "phoneCode": "886"
        },
        {
            "code": "TJ",
            "name": {
                "id":"countries.tajikistan",
                "defaultMessage":"Tajikistan"
            },
            "phoneCode": "992"
        },
        {
            "code": "TZ",
            "name": {
                "id":"countries.tanzania",
                "defaultMessage":"Tanzania"
            },
            "phoneCode": "255"
        },
        {
            "code": "TH",
            "name": {
                "id":"countries.thailand",
                "defaultMessage":"Thailand"
            },
            "phoneCode": "66"
        },
        {
            "code": "TG",
            "name": {
                "id":"countries.togo",
                "defaultMessage":"Togo"
            },
            "phoneCode": "228"
        },
        {
            "code": "TK",
            "name": {
                "id":"countries.tokelau",
                "defaultMessage":"Tokelau"
            },
            "phoneCode": "690"
        },
        {
            "code": "TO",
            "name": {
                "id":"countries.tonga",
                "defaultMessage":"Tonga"
            },
            "phoneCode": "676"
        },
        {
            "code": "TT",
            "name": {
                "id":"countries.trinidadAndTobago",
                "defaultMessage":"Trinidad and Tobago"
            },
            "phoneCode": "868"
        },
        {
            "code": "TN",
            "name": {
                "id":"countries.tunisia",
                "defaultMessage":"Tunisia"
            },
            "phoneCode": "216"
        },
        {
            "code": "TR",
            "name": {
                "id":"countries.turkey",
                "defaultMessage":"Turkey"
            },
            "phoneCode": "90"
        },
        {
            "code": "TM",
            "name": {
                "id":"countries.turkmenistan",
                "defaultMessage":"Turkmenistan"
            },
            "phoneCode": "993"
        },
        {
            "code": "TC",
            "name": {
                "id":"countries.turksAndCaicosIslands",
                "defaultMessage":"Turks and Caicos Islands"
            },
            "phoneCode": "649"
        },
        {
            "code": "TV",
            "name": {
                "id":"countries.tuvalu",
                "defaultMessage":"Tuvalu"
            },
            "phoneCode": "688"
        },
        {
            "code": "UG",
            "name": {
                "id":"countries.uganda",
                "defaultMessage":"Uganda"
            },
            "phoneCode": "256"
        },
        {
            "code": "UA",
            "name": {
                "id":"countries.ukraine",
                "defaultMessage":"Ukraine"
            },
            "phoneCode": "380"
        },
        {
            "code": "AE",
            "name": {
                "id":"countries.unitedArabEmirates",
                "defaultMessage":"United Arab Emirates"
            },
            "phoneCode": "971"
        },
        {
            "code": "GB",
            "name": {
                "id":"countries.unitedKingdom",
                "defaultMessage":"United Kingdom"
            },
            "phoneCode": "44"
        },
        {
            "code": "US",
            "name": {
                "id":"countries.unitedStates",
                "defaultMessage":"United States"
            },
            "phoneCode": "1"
        },
        {
            "code": "UY",
            "name": {
                "id":"countries.uruguay",
                "defaultMessage":"Uruguay"
            },
            "phoneCode": "598"
        },
        {
            "code": "UZ",
            "name": {
                "id":"countries.uzbekistan",
                "defaultMessage":"Uzbekistan"
            },
            "phoneCode": "998"
        },
        {
            "code": "VU",
            "name": {
                "id":"countries.vanuatu",
                "defaultMessage":"Vanuatu"
            },
            "phoneCode": "678"
        },
        {
            "code": "VN",
            "name": {
                "id":"countries.vietnam",
                "defaultMessage":"Vietnam"
            },
            "phoneCode": "84"
        },
        {
            "code": "WF",
            "name": {
                "id":"countries.wallisAndFutuna",
                "defaultMessage":"Wallis and Futuna"
            },
            "phoneCode": "681"
        },
        {
            "code": "ZM",
            "name": {
                "id":"countries.zambia",
                "defaultMessage":"Zambia"
            },
            "phoneCode": "260"
        }
    ]
}
