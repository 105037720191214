import _findIndex from "lodash/findIndex";
import _every from "lodash/every";

import {
  CART_ADD,
  CART_UPDATE,
  CART_REMOVE,
  CART_CLEAN,
  CART_CHECK_TYPE,
  CART_SET_TYPE,
  CART_NEEDS_BILLING,
} from "../actions/actionTypes";

const cartTypeMiddleware = (store) => (next) => (action) => {
  next(action);

  let actions = [CART_ADD, CART_UPDATE, CART_REMOVE, CART_CLEAN];

  let shouldAct = _findIndex(actions, function (o) {
    return o === action.type;
  });

  if (shouldAct >= 0) {
    store.dispatch({ type: CART_CHECK_TYPE });
  }

  if (action.type === CART_CHECK_TYPE) {
    const data = store.getState();

    const cart_is_digital = _every(data.cart.byHash, (o) => {
      return o.content.is_digital === true;
    });

    store.dispatch({ type: CART_SET_TYPE, value: cart_is_digital });
    store.dispatch({ type: CART_NEEDS_BILLING, value: cart_is_digital });
  }
};

export default cartTypeMiddleware;
