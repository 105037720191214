
/**
 * @description
 * Redux action types
 */
export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';

export const UNDEFINED_ERROR = 'UNDEFINED_ERROR';

export const CART_ADD = 'cart/ADD';
export const CART_ADD_ERROR = 'cart/ADD_ERROR';
export const CART_UPDATE = 'cart/UPDATE';
export const CART_QTY_UPDATE = 'cart/QUANTITY_UPDATE';
export const CART_REMOVE = 'cart/REMOVE';
export const CART_CLEAN = 'cart/CLEAN';
export const CART_EMPTY = 'cart/EMPTY';
export const CART_SET_CURRENCY = 'cart/SET_CURRENCY';
export const CART_SET_CURRENCY_ERROR = 'cart/SET_CURRENCY_ERROR';
export const CART_SHOULD_UPDATE_TOTALS = 'cart/SHOULD_UPDATE_TOTALS';
export const CART_CHECK_TYPE = 'cart/CHECK_TYPE';
export const CART_SET_TYPE = 'cart/SET_TYPE';
export const CART_NEEDS_BILLING = 'cart/NEEDS_BILLING';
export const CART_SET_TOTALS = 'cart/SET_TOTALS';
export const CART_CAN_SUBMIT = 'cart/CART_CAN_SUBMIT';
export const CART_PAY = 'cart/PAY';
export const CART_CONFIRM_PAYMENT = 'cart/CART_CONFIRM_PAYMENT';
export const CART_PAID = 'cart/PAID';
export const CART_PAY_WAITING_START = 'cart/PAY_WAITING_START';
export const CART_PAY_WAITING_END = 'cart/PAY_WAITING_END';
export const CART_PAY_WAITING_ERROR = 'cart/PAY_WAITING_ERROR';
export const CART_PAY_ERROR = 'cart/PAY_ERROR';

export const PERSONAL_SET_COUNTRY = 'personal/PERSONAL_SET_COUNTRY';
export const PERSONAL_SET_COUNTRY_ERROR = 'personal/PERSONAL_SET_COUNTRY_ERROR';
export const PERSONAL_SET_CURRENCY = 'personal/PERSONAL_SET_CURRENCY';
export const PERSONAL_UPDATE_INFO = 'personal/PERSONAL_UPDATE_INFO';

export const ADDRESS_UPDATE_INFO = 'address/ADDRESS_UPDATE_INFO';
export const ADDRESS_OPTIONS_CREATE = 'address/OPTIONS_CREATE';
export const ADDRESS_OPTIONS_UPDATE = 'address/OPTIONS_UPDATE';
export const ADDRESS_OPTIONS_REMOVE = 'address/OPTIONS_REMOVE';
export const ADDRESS_OPTIONS_CLEAN = 'address/OPTIONS_CLEAN';
export const ADDRESS_AVAILABLE_OPTIONS_SET = 'address/AVAILABLE_OPTIONS_SET';
export const ADDRESS_SHOULD_UPDATE_AVAILABLE_OPTIONS = 'address/SHOULD_UPDATE_AVAILABLE_OPTIONS';
export const ADDRESS_OPTIONS_TRACKING = 'address/OPTIONS_TRACKING_NUMBER';

export const TAXES_CALCULATED = 'taxes/CALCULATE';

export const BUNDLE_CREATE = 'bundles/BUNDLE_CREATE';
export const BUNDLE_REMOVE = 'bundles/BUNDLE_REMOVE';
export const AVAILABLE_BUNDLES_SET = 'bundles/AVAILABLE_BUNDLES_SET';

export const LOGIN = 'LOGIN';
export const SIGNIN = 'SIGNIN';

export const UPDATE_STEP = 'UPDATE_STEP';
export const CHANGE_STEP = 'CHANGE_STEP';

export const CARD_UPDATE_INFO = 'CARD_UPDATE_INFO';
export const CARD_ADD = 'CARD_ADD';
export const CARD_ADD_ERROR = 'CARD_ADD_ERROR';
export const CARD_ADDED = 'CARD_ADDED';
export const CARD_REMOVE = 'CARD_REMOVE';
export const STRIPE_REQUIRES_ACTION = 'STRIPE_REQUIRES_ACTION';

export const COUPON_GET = 'coupon/GET';
export const COUPON_ADD = 'coupon/ADD';
export const COUPON_VALIDATE = 'coupon/VALIDATE';
export const COUPON_ADD_ERROR = 'coupon/ADD_ERROR';
export const COUPON_REMOVE = 'coupon/REMOVE';

export const ALERT_OPEN = 'alert/OPEN';
export const ALERT_CLOSE = 'alert/CLOSE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATE_CHANNEL_ID = 'UPDATE_CHANNEL_ID';

export const VENDOR_ADD = 'cart/VENDOR_ADD';
