import _get from "lodash/get";
import _reduce from "lodash/reduce";
import _head from "lodash/head";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import _round from "lodash/round";

import {
    CART_SHOULD_UPDATE_TOTALS,
    CART_SET_TOTALS,
    UNDEFINED_ERROR, UPDATE_STEP
} from '../actions/actionTypes';
import GetProduct from "../services/getProduct";
import calculateTaxes from "../services/calculateTaxes";


const updateTotalsMiddleware = store => next => action => {

    next(action); // pass the original

    if (action.type === CART_SHOULD_UPDATE_TOTALS) {

        const state = store.getState();

        try{
            const cart = state.cart;
            let totals = state.cart.totals;
            totals.showTax = state.vendor.taxes && state.vendor.taxes.module ? state.vendor.taxes.module : false;
            const currency = state.personal.currency_code;
            const coupons = state.coupon;
            const coupon_id = _head(coupons.byId);
            let coupon = null;
            if(coupon_id)
            {
                coupon = coupons.byHash[coupon_id].content;
            }
            totals.discount = 0;
            if (coupon)
            {
                totals.discount = _reduce(cart.byHash, function(sum, n) {
                    let CurrencyIndex = _findIndex(n.content.currencies, ['currency_code', currency]);
                    let result = _filter(coupon.discounted_variants, o => o == n.id); //double equal on purpose as ids are coming as int & string
                    if (!_isEmpty(result)){
                        const qty = _get(cart, 'qtyByHash['+ n.id +']', 1);
                        return sum + (n.content.currencies[CurrencyIndex].price * qty) * coupon.percentage / 100
                    }
                    return sum
                }, 0);
            }

            totals.shipping = 0;
            totals.shipping_lines = [];
            const selected_option = state.shipping.selectedOption;
            if (selected_option > 0) {
                const option = _find(state.shipping.availableOptions, ['id', selected_option]);
                const priceIndex = _findIndex(option.prices, ['currency_code', currency]);
                totals.shipping_lines = [{
                    "price": option.prices[priceIndex].price,
                    "name": option.name
                }];
            }

            totals.shipping = _reduce(totals.shipping_lines, (sum, n) => sum + n.price, 0);

            totals.subtotal = _reduce(cart.byHash, function(sum, n) {
                let CurrencyIndex = _findIndex(n.content.currencies, ['currency_code', currency]);
                const qty = _get(cart, 'qtyByHash['+ n.id +']', 1);

                return sum + _round((n.content.currencies[CurrencyIndex].price) * qty)
            }, 0);

            if (
                ((!state.common.isDigital && state.common.stepReady.shippingAddress) || (state.common.isDigital && state.common.stepReady.billingAddress))
                &&
                ((!state.common.isDigital && state.common.stepReady.shippingOptions) || state.common.isDigital)
                &&
                ( state.vendor.taxes && state.vendor.taxes.module ? state.vendor.taxes.module : false)
                // &&
                // !state.common.stepReady.taxTotal
            ){
                calculateTaxes.calculate(state).then((data) => {

                    console.log(data);

                    if (data.status === 400){
                        store.dispatch({type: UPDATE_STEP, key: 'taxTotal', value: false});
                        return;
                    }

                    totals.tax = data.tax.taxAmount;
                    const taxType = state.vendor?.taxes?.type || null;
                    totals.total = _round(totals.subtotal + (totals.shipping ? totals.shipping : 0) - totals.discount);

                    if (taxType === 'exclusive') {
                        totals.total += _round(totals.tax ? totals.tax : 0);
                    }

                    totals.weight = _reduce(cart.byHash, function (sum, n) {
                        const qty = _get(cart, 'qtyByHash[' + n.id + ']', 1);
                        return sum + ((n.content.weight) * qty);
                    }, 0);

                    store.dispatch({ type: CART_SET_TOTALS, payload: totals });
                    store.dispatch({type: UPDATE_STEP, key: 'taxTotal', value: true})
                }).catch(error => {
                    console.log(error);
                });

            }

            totals.total = _round(totals.subtotal + (totals.shipping ? totals.shipping : 0) - totals.discount + (totals.tax ? totals.tax : 0));

            totals.weight = _reduce(cart.byHash, function (sum, n) {
                const qty = _get(cart, 'qtyByHash[' + n.id + ']', 1);
                return sum + ((n.content.weight) * qty);
            }, 0);

            store.dispatch({ type: CART_SET_TOTALS, payload: totals });
        }catch(error) {
            store.dispatch({type: UNDEFINED_ERROR, error: 'Error calculating total for these products'});
        }


    }
};

export default updateTotalsMiddleware
