import _find from 'lodash/find';
import _gte from 'lodash/gte';
import _lte from 'lodash/lte';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';

export default (shippingCountry, shippingOptions, props) => _filter(_map(_get(shippingCountry, 'options',[]), (shippingOption) => {
    const option = _find(shippingOptions, o => o.id === shippingOption);

    let weightRules = (_isArray(option.rules)) ? _find(option.rules, ['type', 'weight_range']) : option.rules;

    if (weightRules.type === 'FixedPrice') return true;
    return _gte(props.cart.totals.weight, weightRules.conditions.minWeight)
    && _lte(props.cart.totals.weight, weightRules.conditions.maxWeight) ? option : false;
}));
