import React from 'react';
import PropTypes from 'prop-types';

import { ListItem } from 'material-ui/List';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import FlatButton from 'material-ui/FlatButton';
import Currency from 'react-currency-formatter';

import ProductImage from './elements/productImage';

const style = {
    // padding: '16px 56px 16px 0px'
};

const Qty = ({
                 qty, is_digital, styles, onQtyChange, onRemove,
             }) => (
    <div className="product__qty">
        {(!is_digital)
        && (
            <div>
                <span className="product__qty__label">Quantity</span>
                <span className="product__qty__label">{qty}</span>
                {/* <SelectField */}
                {/* value={qty} */}
                {/* style={styles.quantity} */}
                {/* onChange={onQtyChange} */}
                {/* className="product__qty__select" */}
                {/* > */}
                {/* <MenuItem value={1} primaryText="1" /> */}
                {/* <MenuItem value={2} primaryText="2" /> */}
                {/* <MenuItem value={3} primaryText="3" /> */}
                {/* <MenuItem value={4} primaryText="4" /> */}
                {/* <MenuItem value={5} primaryText="5" /> */}
                {/* <MenuItem value={6} primaryText="6" /> */}
                {/* <MenuItem value={7} primaryText="7" /> */}
                {/* <MenuItem value={8} primaryText="8" /> */}
                {/* <MenuItem value={9} primaryText="9" /> */}
                {/* <MenuItem value={10} primaryText="10" /> */}
                {/* <MenuItem value={11} primaryText="11" /> */}
                {/* <MenuItem value={12} primaryText="12" /> */}
                {/* <MenuItem value={13} primaryText="13" /> */}
                {/* <MenuItem value={14} primaryText="14" /> */}
                {/* <MenuItem value={15} primaryText="15" /> */}
                {/* </SelectField> */}
                {/* <FlatButton label="Remove" secondary onClick={onRemove} className="removebtn" style={styles.remove} /> */}
            </div>
        )
        }
        {/* {(is_digital) */}
        {/* && <FlatButton label="Remove" secondary onClick={onRemove} className="removebtn" style={styles.remove} /> */}
        {/* } */}
    </div>
);

const Description = ({ name, price, currency }) => (
    <div className="product__description">
        <span className="product__title">{name}</span>
        <span className="product__price"><Currency quantity={price} currency={currency} /></span>
    </div>
);

const bundleList = (is_bundle, variants) => (is_bundle && variants
    ? variants.map(variant => (
        <ListItem
            key={variant.id}
            className="list__product__sub"
            primaryText={<span className="product__title">{variant.name}</span>}
            leftIcon={<ProductImage image={variant.image} />}
            disabled
        />
    ))
    : []);

const Product = ({
                     onRemove, onQtyChange, name, is_digital, is_bundle, image, variants, seller, price, currency, qty, styles,
                 }) => (
    <ListItem
        className="list__product"
        style={style}
        disabled
        primaryText={<Description name={name} price={price} currency={currency} onRemove={onRemove} styles={styles} />}
        secondaryText={<Qty qty={qty} is_digital={is_digital} styles={styles} onQtyChange={onQtyChange} onRemove={onRemove} />}
        leftIcon={<ProductImage image={image} />}
        nestedItems={bundleList(is_bundle, variants)}
        initiallyOpen
    />
);

Product.propTypes = {
    onRemove: PropTypes.func.isRequired,
    onQtyChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    is_digital: PropTypes.bool.isRequired,
    image: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    qty: PropTypes.number.isRequired,
};

export default Product;
