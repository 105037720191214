import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

const PatriciaThankYou = ({styles}) => (
    <div className="thank-you">{``}
        <div className="mainDesktop__container">
            <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png" alt=""/>
            <p><strong>Your payment has been processed successfully and you will receive an order confirmation email shortly.</strong></p>
            <p>To access the The Break course just login below using the same email and password you just created.</p>
            <div>
                <img className="logo-top" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/break-logo.png" alt=""/>
            </div>
            <a href="https://members.thebreakplatform.com/">
                <RaisedButton
                    type="submit"
                    label="Login"
                    style={styles.button}
                    primary
                />
            </a>

            <p className="genflow-badge"><img src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/powered.png" alt=""/></p>
        </div>
    </div>
);

export default PatriciaThankYou;
