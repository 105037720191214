import axios from 'axios';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

export default {
    requestCheckout(encodedJSON) {
        const url = `${process.env.REACT_APP_API}api/v4/checkout/${encodedJSON}`;

        return request(url, v3Transformer);
    },
};

const request = (url, callback) => {
    const instance = axios.create({
        timeout: 120000,
    });
    return instance.get(url).then(callback);
};

const v3Transformer = (response) => {

    if (!_isEmpty(response.data.errors)){
        throw (response.data.errors);
    };

    const data = _get(response, 'data.data', null);
    return {
        ...data,
        items: data.items.map(item => ({
            id: item.is_bundle ? `${item.variant_id.toString()}-${_get(item, 'bundle_variants', '')}` : item.variant_id.toString(),
            content: {
                is_bundle: item.is_bundle,
                is_digital: item.is_digital,
                weight: _get(item, 'weight', 0),
                bundleVariants: _get(item, 'bundle_variants', null),
                name: item.name,
                image: _get(item, 'image.src', item.background_image),
                currencies: item.currencies,
                variants: variantListTransformer(_get(item, 'variants', null)),
            },
            product_id: item.id,
            seller_id: item.seller_id,
            seller_name: item.seller_name,
            stripeId: item.stripeId,
            shipping_zones: data.shipping_zones,
            qty: item.qty,
        })),
    };
};

const variantListTransformer = variants => _map(variants, variant => ({
        name: variant.name,
    image: _get(variant, 'image.src', variant.background_image),
}));
