import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import {FormattedMessage} from 'react-intl';

const ShonaThankYou = ({styles}) => (
    <div className="thank-you">{``}
        <div className="mainDesktop__container">
            <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png" alt=""/>
            <p><strong>Your payment has been processed successfully and you will receive an order confirmation email shortly</strong></p>
            <p>If you have purchased a fitness programme, just use the same email and password you created to login below.</p>
            <div>
                <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/vm-icon.png" alt=""/>
            </div>
            <a href="https://members.shonavertue.com/">
                <RaisedButton
                    type="submit"
                    label="Login"
                    style={styles.button}
                    primary
                />
            </a>

            <br/>
                <h3>
                    <FormattedMessage
                        id="checkout.merchandise"
                        defaultMessage="Merchandise"
                    />
                </h3>
                <p>
                    <FormattedMessage
                        id="checkout.purchasedMerch"
                        defaultMessage="If you purchased some merch, please check your email for your order confirmation and shipping times."
                    />
                </p>
                <br/>
                <p className="problem-line">
                <FormattedMessage
                    id="checkout.needHelp"
                    defaultMessage="Need Help? Visit > "
                />
                <a href="http://support.shonavertue.com/">
                    <FormattedMessage
                        id="checkout.needHelpLink"
                        defaultMessage="Customer Support"
                    />
                </a>
            </p>
            <p className="genflow-badge"><img src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/powered.png" alt=""/></p>
        </div>
    </div>
);

export default ShonaThankYou;
