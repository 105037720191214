import {LOGIN_USERNAME_PASSWORD, SIGNUP, SOCIAL_CONNECTION, RECOVER_PASSWORD, RESET_PASSWORD} from "./types";
import {loginError, handleLogin, signUpError, loginUsernamePassword} from './actions';
import {ALERT_OPEN} from "../../actions/actionTypes";
import {
    LOGIN_INVALID,
    REGISTER_INVALID,
    REGISTER_LOGIN_INVALID,
    REGISTER_CHANGE_PASSWORD,
    REGISTER_CHANGE_PASSWORD_ERROR,
} from "../../lang/alerts";
import {bugsnagClient} from "../../services/bugSnag";

export default ({auth}) => {

    return (store) => (next) => async (action) => {

        if (action.type === LOGIN_USERNAME_PASSWORD) {
            try {
                const authResult = await auth.login(action.username, action.password, action.options);

                authResult.expiresAt = authResult.expiresIn ? authResult.expiresIn + Math.floor(Date.now() / 1000) : authResult.expiresAt;

                next(handleLogin(authResult));

                ////if (action.redirect) {
                ////  return store.dispatch(action.redirect);
                ////}

                return next(action);
            } catch (error) {
                console.log(error);
                bugsnagClient.notify(error, { context: "Trying to login"});
                if (action.options.from === 'register') {
                    store.dispatch({type: ALERT_OPEN, message: REGISTER_LOGIN_INVALID});
                    return next(loginError(error));
                }
                store.dispatch({type: ALERT_OPEN, message: LOGIN_INVALID});
                return next(loginError(error));
            }
        }

        if (action.type === SIGNUP) {
            try {
                const authResult = await auth.signup(action);

                next(handleLogin(authResult));

                //if (action.redirect) {
                //  return store.dispatch(action.redirect);
                //}

                return next(action);

            } catch (error) {
                //If SignUp fails because user exists we try to login using the user/password combination
                console.log(error);
                bugsnagClient.notify(error, { context: "Trying to signup", metaData: { user: action.username} });
                if (error.code === 'UsernameExistsException') {

                    return store.dispatch(loginUsernamePassword({
                        username: action.username,
                        password: action.password,
                        options: {vendor: store.getState().vendor}
                    }));
                }
                store.dispatch({type: ALERT_OPEN, message: REGISTER_INVALID});
                return next(signUpError(error));
            }
        }

        if (action.type === RECOVER_PASSWORD) {
            try {
                const authType = store.getState().vendor.authType;
                await auth.recoverPassword(action.email, action.options);
            } catch (error) {
                console.log(error);
                store.dispatch({type: ALERT_OPEN, message: REGISTER_CHANGE_PASSWORD_ERROR});
            }
        }

        if (action.type === RESET_PASSWORD) {


            const loginData = await auth.resetPassword(action.email, action.code, action.newPassword, action.vendor);

            store.dispatch(loginUsernamePassword(loginData));
        }

        return next(action);
    };
};
