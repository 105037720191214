import React, {Component} from 'react';
import {connect} from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';
import {FormattedMessage} from 'react-intl';

import ShonaThankYou from './customThankYou/shonaThankYou';
import JessicaThankYou from './customThankYou/jessicaThankYou';
import MikedThankYou from './customThankYou/mikedThankYou';
import PatriciaThankYou from './customThankYou/patriciaThankYou';
import PineappleThankYou from './customThankYou/pineappleThankYou';
import KosmoFitMethodThankYou from './customThankYou/kosmoFitMethodThankYou';
import OhoneyThankYou from './customThankYou/ohoneyThankYou';
import GabbyThankYou from './customThankYou/gabbyThankYou';
import HannaThankYou from './customThankYou/hannaThankYou';
import KateThankYou from './customThankYou/kateThankYou';
import ThrstThankYou from './customThankYou/thrstThankYou';
import MarinoThankYou from './customThankYou/marinoThankYou';
import FrstplaceThankYou from './customThankYou/frstplaceThankYou'
import JustmerkThankYou from './customThankYou/justmerkThankYou'
import AldoThankYou from './customThankYou/aldoThankYou'
import TiffanyThankYou from './customThankYou/tiffanyThankYou'
import StrongandsxyThankYou from './customThankYou/strongandsxyThankYou'
import SxyfitThankYou from './customThankYou/sxyfitThankYou'
import MadfitThankYou from './customThankYou/madfitThankYou'
import GadzhiThankYou from './customThankYou/gadzhiThankYou'
import You1stThankYou from './customThankYou/you1stThankYou'
import AnnieOpenshawThankYou from './customThankYou/annieOpenshawThankYou'

import _get from 'lodash/get';
import _has from 'lodash/has';

class ThankYou extends Component {

    render() {
        const props = this.props;

        //Send Tapfiliate conversion if it's set
        try {
            const tapfiliateId = _get(this.props, 'vendor.tapfiliateId', null);
            const orderId = _get(this.props, 'common.order.order_id', null);
            const total = _get(this.props, 'common.order.total_order', null);

            if (window.tap
                && tapfiliateId
                && orderId
                && total
            ) {
                // tapfiliate
                window.tap('create', tapfiliateId)
                window.tap(
                    'conversion',
                    `${orderId}`,
                    total / 100
                )
            }
        } catch (error) {
            console.log(error);
        }

        //First, customised thankyou pages

        const vendorUuid = _get(props, 'vendor.id', null);

        switch (vendorUuid) {
            case 'b445a1c7-3a93-11e9-bfcd-0ac0203a1ad8':
                return <ShonaThankYou styles={props.muiTheme.product}/>
            case 'b4458f5d-3a93-11e9-bfcd-0ac0203a1ad8':
                return <JessicaThankYou styles={props.muiTheme.product}/>
            case 'b444c8a5-3a93-11e9-bfcd-0ac0203a1ad8':
                return <MikedThankYou styles={props.muiTheme.product}/>
            case 'b445b262-3a93-11e9-bfcd-0ac0203a1ad8':
                return <PatriciaThankYou styles={props.muiTheme.product}/>
            case 'b44591be-3a93-11e9-bfcd-0ac0203a1ad8':
                return <PineappleThankYou styles={props.muiTheme.product}/>
            case '0f16f8b3-9dca-11e9-bfcd-0ac0203a1ad8':
                return <KosmoFitMethodThankYou styles={props.muiTheme.product}/>
            case '31f5f3b2-9dca-11e9-bfcd-0ac0203a1ad8':
                return <OhoneyThankYou styles={props.muiTheme.product}/>
            case 'b445b861-3a93-11e9-bfcd-0ac0203a1ad8':
                return <GabbyThankYou styles={props.muiTheme.product}/>
            case 'b4456214-3a93-11e9-bfcd-0ac0203a1ad8':
                return <HannaThankYou styles={props.muiTheme.product}/>
            case 'b44596df-3a93-11e9-bfcd-0ac0203a1ad8':
                return <KateThankYou styles={props.muiTheme.product}/>
            case '29cede06-952e-4d7e-9184-9f18490b9a4e':
                return <ThrstThankYou styles={props.muiTheme.product}/>
            case '05bba9c3-4d03-4225-b054-358ce54f2fdd':
                return <MarinoThankYou styles={props.muiTheme.product}/>
            case 'ca30f0cf-655d-4ba7-9ac3-14be6e15b812':
                return <FrstplaceThankYou styles={props.muiTheme.product}/>
            // case '15238df4-08e9-4e65-898a-589f3c4ccee8':
            //     return <JustmerkThankYou styles={props.muiTheme.product}/>
            case '573f24ff-d378-4e61-a066-2baa3472e74b':
                return <AldoThankYou styles={props.muiTheme.product}/>
            case 'b144601f-8414-4bb7-935b-1271a9bde478':
                return <TiffanyThankYou styles={props.muiTheme.product}/>
            case 'b445063a-3a93-11e9-bfcd-0ac0203a1ad8':
                return <StrongandsxyThankYou styles={props.muiTheme.product}/>
            case '0afdf6b7-1234-4e3b-a7af-252a245d9527':
                return <SxyfitThankYou styles={props.muiTheme.product}/>
            case 'b3baaca4-203c-4a76-8065-76355d5bf366':
                return <MadfitThankYou styles={props.muiTheme.product}/>
            case 'c6aee1ce-9fe0-47a2-86df-8377c7ea6294':
                return <GadzhiThankYou styles={this.props.muiTheme.product}/>
            case '26572537-7e1f-4876-98b8-7c1828d429a1':
                return <You1stThankYou styles={this.props.muiTheme.product}/>
            case 'ccf5ded6-100a-4072-856c-ba412e380369':
                return <AnnieOpenshawThankYou styles={this.props.muiTheme.product}/>
        }

        //If not, then regular thank you page

        const backgroundColour = _has(props, 'vendor.theme.primaryColour') ? _get(props, 'vendor.theme.primaryColour') : '#FFFFFF';

        return (
            <div className="thank-you">{``}
                <div className="mainDesktop__container">
                    <div style={{backgroundColor: backgroundColour}} >
                        <img className="logo-top" src={props.vendor.logo} alt=""/>
                    </div>

                    <div><h1>
                        <FormattedMessage
                            id="checkout.thankYou"
                            defaultMessage="Thank you!"
                        />
                    </h1></div>
                    <br/>
                    <p><strong>
                        <FormattedMessage
                            id="checkout.paymentProcessed"
                            defaultMessage="Your payment has been processed successfully."
                        />
                    </strong></p>
                    <p><strong>
                        <FormattedMessage
                            id="checkout.receiveConfirmationEmail"
                            defaultMessage="You will receive an order confirmation email with all the details."
                        />
                    </strong></p>
                    <p>
                        <FormattedMessage
                            id="checkout.checkSpam"
                            defaultMessage="Please, check your spam or junk folders if it's not in your inbox."
                        />
                    </p>
                    <br/>
                    {_get(props, 'vendor.thankYou.membersArea', null) &&
                    <div>
                        <p><strong>Members Area</strong></p>
                        <p>If you purchased a course or content you can now return to the website and login to the
                            members area with the same account you just made to access the content.</p>
                        <br/>
                    </div>
                    }
                    {_get(props, 'vendor.thankYou.downloads', null) &&
                    <div>
                        <p><strong>Downloads</strong></p>
                        <p>If you purchased downloadable content, please check your email for download links from products included in your order.</p>
                        <br/>
                    </div>
                    }
                    {_get(props, 'vendor.thankYou.merchandise', null) &&
                    <div>
                        <p><strong>Products</strong></p>
                        <p>If you purchased some physical products, please check your email for your order confirmation
                            and shipping times.</p>
                        <br/>
                    </div>
                    }
                    {_get(props, 'vendor.thankYou.aflete', null) &&
                    <div>
                        <p><strong>AFLETE APP</strong></p>
                        <p>If you purchased a workout guide that includes AFLETE, you can access it by downloading the
                            AFLETE App from the app store and logging in with the same account you just made.</p>
                        <p className="download-btn">
                            <a href="https://ad.apps.fm/C_3Cp5A6vOTfPzkCqbV8BfE7og6fuV2oOMeOQdRqrE2SqQj2mi01hG-wmUMDFadTiZifbdzPixPWLIc5IjGwLEkmlJMaGSIl3pdXa_4waD0">
                                <img src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/apple-badge.png"
                                     alt=""/></a>
                            <a href="https://ad.apps.fm/wgTNScPYjOB6UfctBtbeK15KLoEjTszcQMJsV6-2VnHFDLXitVHB6BlL95nuoNYf-XtGAYT-wjXD3OMs344Pbz4e4LysVtcrAMDcjw5TZ40EmEHTgDKO8HFEabO5ZwtT">
                                <img
                                    src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/android-badge.png"
                                    alt=""/></a>
                        </p>
                        <br/>
                    </div>
                    }
                    {_get(props, 'vendor.thankYou.support', null) &&
                    <p className="problem-line">
                        <FormattedMessage
                            id="checkout.needHelp"
                            defaultMessage="Need Help? Visit > "
                        />
                        <a href={props.vendor.routes.support}>
                            <FormattedMessage
                                id="checkout.needHelpLink"
                                defaultMessage="Customer Support"
                            />
                        </a>
                    </p>
                    }
                </div>
            </div>
        );
    }
}

export default muiThemeable()(connect(state => (state), null)(ThankYou));
