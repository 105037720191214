import axios from "axios";

export default {
    calculate: function(data) {
        const taxData = {
            channelId: data.common.channelId,
            vendorUuid: data.vendor.id,
            shippingAddress: data.address.shipping.zip ? data.address.shipping : data.address.billing,
            shippingContact: data.personal,
            shippingAmount: data.cart.totals.shipping,
        }
        return axios.put(`${process.env.REACT_APP_TAXES_API}taxes/address`, taxData)
            .then(response => {
                console.log("TAXES API",response);
                return {
                    tax: response.data
                }
            }).catch(err => {
                if (err.response) {
                    console.log(err.response)
                    return err.response;
                } else if (err.request) {
                    console.log(err.request)
                    return err.request;
                } else {
                    console.log(err)
                    return err;
                }})
    },
}
