import {
    COUPON_ADD,
    COUPON_REMOVE,
    COUPON_ADD_ERROR,
    CART_CLEAN
} from '../actions/actionTypes';

import {
    COUPON_NOT_VALID
} from '../lang/alerts';

import _uniq from 'lodash/uniq';

const defaultState = {
        byId: [],
        byHash: {},
        error: null
    };

export default (state = defaultState,  action = {}) => {
    switch (action.type) {
        case COUPON_ADD:
            return {
                ...state,
                byId: _uniq([ ...state.byId, action.id]),
                byHash: {
                    ...state.byHash,
                    [action.id]: action.payload
                }
            };
        case COUPON_REMOVE: {
            const prunedIds = state.byId.filter(item => {
                return item !== action.id // return all the items not matching the action.id
            });
            delete state.byHash[action.id]; // delete the hash associated with the action.id

            return {
                ...state,
                byId: prunedIds,
                byHash: state.byHash
            }
        }
        case COUPON_ADD_ERROR:
            return {
                ...state,
                error: COUPON_NOT_VALID
            };
        case CART_CLEAN: {
            return {
                ...state,
                byId: [],
                byHash: {},
                error: null
            }
        }
        default:
            return state;
    }
};
