

import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';

//Middleware
import reactBlockUiMiddleware from 'react-block-ui/lib/reduxMiddleware';
import loggerMiddleware from './middleware/logger';
//import currenciesMiddleware from './middleware/currencies';
import updateTotalsMiddleware from './middleware/updateTotals';
import cartTypeMiddleware from './middleware/cartType';
import cartVersionMiddleware from './middleware/cartVersion';
import shippingMiddleware from './middleware/shipping';
import paymentMiddleware from './middleware/payment';
import couponMiddleware from './middleware/coupon';
import taxesMiddleware from './middleware/taxes';
import stepsMiddleware from './middleware/steps';
import gaEvents from './middleware/gaEvents';
import reactPixelMiddleware from './middleware/reactPixel';

//Reducers
import common from './reducers/common';
import personal from './reducers/personal';
import address from './reducers/address';
import cart from './reducers/cart';
import shipping from './reducers/shipping';
import bundles from './reducers/bundles';
import card from './reducers/card';
import coupon from './reducers/coupon';
import taxes from './reducers/taxes';
import vendor from './reducers/vendor';

//state from localStorage
import { loadState } from './services/localStorageService';

//Auth Module
import authModule from './Modules/auth';

import version from './AppVersion';

const { middleware: authMiddleware, reducer: auth } = authModule();


//Combine reducers
const reducers = combineReducers({
    common,
    auth,
    personal,
    address,
    cart,
    shipping,
    bundles,
    card,
    coupon,
    // taxes,
    vendor
});

//Load state from LocalStorage
const persistedState = loadState();

let state;

//Add version to cart
if (persistedState && persistedState.hasOwnProperty('cart')){
    state = {
        ...persistedState,
        cart : {
            ...persistedState.cart,
            version,
        },
    };
}

//Combine Middleware
const getMiddleware = () => applyMiddleware(
        thunk,
        gaEvents,
        authMiddleware,
        reactBlockUiMiddleware,
        //currenciesMiddleware,
        updateTotalsMiddleware,
        loggerMiddleware,
        paymentMiddleware,
        cartTypeMiddleware,
        cartVersionMiddleware,
        shippingMiddleware,
        couponMiddleware,
        // taxesMiddleware,
        stepsMiddleware,
    reactPixelMiddleware,
    );

let composeEnhancers = compose;
if (process.env.NODE_ENV !== 'production') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

//Create Store
const store = createStore(reducers, state, composeEnhancers(getMiddleware()));

export default store;
