import axios from "axios";

export default {
    create: function(vendor, data) {
        return axios.post(`${process.env.REACT_APP_API}api/v4/sellers/${vendor}/coupons/orders/create`, data)
            .then(response => {
                return {
                    id: response.data.data.id.toString(),
                    content: response.data.data
                }
            })
    },
    validate: function(vendor, data) {
        return axios.post(`${process.env.REACT_APP_API}api/v4/sellers/${vendor}/coupons/orders/validate`, data)
            .then(response => {
                return {
                    id: response.data.data.id.toString(),
                    content: response.data.data
                }
            })
    },
    removeDiscount: function(vendor, data) {
        return axios.post(`${process.env.REACT_APP_API}api/v4/sellers/${vendor}/coupons/orders/remove-discount`, data)
            .then(response => {
                return {
                    content: response.data.data
                }
            })
    },
}
