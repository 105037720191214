import Logger from 'we-js-logger';

export default new Logger({
    name: 'Frontend Checkout',
    environment: process.env.REACT_APP_ENV,
    level: 'info',
    codeVersion: process.env.SHA_VERSION,
    logentriesToken: process.env.REACT_APP_LOGENTRIES,
    rollbarToken: null
});
