import React, {Fragment} from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import {FormattedMessage} from 'react-intl';

const marinoThankYou = () => (
    <div className="thank-you">{``}
        <div className="mainDesktop__container">
            <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png"
                 alt=""/>
            <div><h1>
                <FormattedMessage
                    id="checkout.thankYou"
                    defaultMessage="Thank you!"
                />
            </h1></div>
            <p><strong>
                <FormattedMessage
                    id="checkout.paymentProcessed"
                    defaultMessage="Your payment has been processed successfully."
                />
            </strong></p>
            <p><strong>
                <FormattedMessage
                    id="checkout.receiveConfirmationEmail"
                    defaultMessage="You will receive an order confirmation email with all the details and download links for any
                        PDF’s included in your order."
                />
            </strong></p>
            <p>
                <FormattedMessage
                    id="checkout.checkSpam"
                    defaultMessage="Please, check your spam or junk folders if it's not in your inbox."
                />
            </p>
            <br/>
            <Fragment>
            <div>
                <img className="thrst-logo" src="https://marinokatsouris.co.uk/images/logo-black.png" alt=""/>
            </div>
            <h3>
                    <FormattedMessage
                        id="checkout.workoutGuides"
                        defaultMessage="Workout Guides, Programs and Plans"
                    />
                </h3>
                <p>
                    <FormattedMessage
                        id="checkout.purchasedAccessApp"
                        defaultMessage="If you purchased a workout guide you can access it by downloading the AFLETE App from the app store and
                            logging in with the same account you just made."
                    />
                </p>
                <br/>
                <h3>
                    <FormattedMessage
                        id="checkout.appDownloadLinks"
                        defaultMessage="App Download Links."
                    />
                </h3>
                <p className="download-btn">
                    <a href="https://ad.apps.fm/C_3Cp5A6vOTfPzkCqbV8BfE7og6fuV2oOMeOQdRqrE2SqQj2mi01hG-wmUMDFadTiZifbdzPixPWLIc5IjGwLEkmlJMaGSIl3pdXa_4waD0">
                        <img src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/apple-badge.png"
                             alt=""/></a>
                    <a href="https://ad.apps.fm/wgTNScPYjOB6UfctBtbeK15KLoEjTszcQMJsV6-2VnHFDLXitVHB6BlL95nuoNYf-XtGAYT-wjXD3OMs344Pbz4e4LysVtcrAMDcjw5TZ40EmEHTgDKO8HFEabO5ZwtT">
                        <img src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/android-badge.png"
                             alt=""/></a>
                </p>
                <br/>
            </Fragment>
            <p className="problem-line">
                <FormattedMessage
                    id="checkout.needHelp"
                    defaultMessage="Need Help? Visit > "
                />
                <a href="https://support.marinokatsouris.co.uk/hc/en-us/requests/new">
                    <FormattedMessage
                        id="checkout.needHelpLink"
                        defaultMessage="Customer Support"
                    />
                </a>
            </p>
            <p className="genflow-badge"><img
                src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/powered.png" alt=""/></p>
        </div>
    </div>
);

export default marinoThankYou;
