import axios from "axios";

import _get from 'lodash/get';
import _head from 'lodash/head';
import _assign from 'lodash/assign';

import cartTransformer from '../transformers/cart';

import {getToken} from '../Modules/payment/transformers';
import _has from "lodash/has";

export default {
    create: function (props) {
        let data = {
            currency: props.personal.currency_code,
            from: 'Web',
            channel_id: props.common.channelId,
            items: cartTransformer.toOrder(props.cart),
            bundle_id: _get(props, 'bundles.selectedBundle.id', null),
            coupon: _head(props.coupon.byId),
            payment: getToken(props.card),
            billing_address: {
                line_1: props.address.billing.line_1,
                line_2: props.address.billing.line_2,
                city: props.address.billing.city,
                state: props.address.billing.state,
                postcode: props.address.billing.zip,
                country_code: props.address.billing.country,
            },
        };

        if (!props.common.isDigital) {
            data = _assign(data, {
                shipping_address: {
                    first_name: props.address.shipping.first_name,
                    last_name: props.address.shipping.last_name,
                    line_1: props.address.shipping.line_1,
                    line_2: props.address.shipping.line_2,
                    city: props.address.shipping.city,
                    state: props.address.shipping.state,
                    postcode: props.address.shipping.zip,
                    country_code: props.address.shipping.country,
                },
                zone_option: props.shipping.selectedOption,
            });
        }

        if (!props.common.isDigital && props.shipping.trackingPhone.number) {
            data = _assign(data, {
                tracking_phone: {
                    code: '',
                    number: props.shipping.trackingPhone.number,
                },
            });
        }

        if (!props.common.isDigital && !props.common.needs_billing) {
            data = _assign(data, {
                billing_address: {
                    line_1: props.address.shipping.line_1,
                    line_2: props.address.shipping.line_2,
                    city: props.address.shipping.city,
                    state: props.address.shipping.state,
                    postcode: props.address.shipping.zip,
                    country_code: props.address.shipping.country,
                },
            });
        }

        let header = {
            timeout: 120000, // 2 minutes
            headers: {'Authorization': `Bearer ${props.auth.idToken}`}
        };

        // const authData = await Auth.currentAuthenticatedUser();

        // const header = {
        //     timeout: 120000, // 2 minutes
        //     headers: {Authorization: `Bearer ${authData.signInUserSession.idToken.jwtToken}`},
        // };
        //
        // const {email, given_name: first_name, family_name: last_name} = authData.attributes;
        // data = _assign(data, {
        //     user: {
        //         first_name,
        //         last_name,
        //         email,
        //     },
        // });

        if (props.personal.first_name || props.personal.last_name || props.personal.email) {
            data = _assign(data, {
                "user": {
                    "first_name": props.personal.first_name,
                    "last_name": props.personal.last_name,
                    "email": props.personal.email,
                }
            })
        }

        const instance = axios.create(header);
        return instance.post(`${process.env.REACT_APP_API}api/v4/checkout/create-transaction`, data)
            .then(response => ({
                message: response.data.message,
            }))
            .catch( error => true )
            .then(() => ({message: 'ok'}))
    },
    checkStatus: function (props) {
        let header = {
            timeout: 30000, // 30 seconds
            headers: {'Authorization': `Bearer ${props.auth.idToken}`}
        };

        const instance = axios.create(header);
        let data = {
            "channel_id": props.common.channelId,
        };

        return instance.post(`${process.env.REACT_APP_API}api/v4/checkout/check_order_completed`, data)
            .then(
                response => {
                    return true;
                })
    },
    confirmPayment: function (props) {
        let header = {
            timeout: 120000, // 2 minutes
            headers: {'Authorization': `Bearer ${props.auth.idToken}`}
        };
        const instance = axios.create(header);
        let data = {
            "channel_id": props.common.channelId,
            "payment_intent_id": props.card.paymentIntentId
        };

        return instance.post(`${process.env.REACT_APP_API}api/v4/checkout/confirm_payment_intent`, data)
            .then(
                response => {
                    return {
                        message: response.data.message
                    }
                },
                error => {
                    if (_has(error, 'response.data.data')) {
                        return error.response.data.data;
                    } else {
                        return Promise.reject(error);
                    }
                })
    }
}
