import React, {Component} from 'react';
import GoogleAnalytics from 'react-ga';
import MatomoTracker from 'matomo-tracker';


const trackers = [
    {
        trackingId: 'UA-75905953-3'
    }
];

let trackersList = [];

switch (window.location.host) {
    case 'checkout.ownuapp.com':
        trackers.push({
            trackingId: 'UA-112183143-1',
            gaOptions: {
                name: 'hannaoeberg',
            }
        });
        trackersList = ['hannaoeberg'];
        break;
    case 'checkout.shreddy.com':
        trackers.push({
            trackingId: 'UA-149342411-1',
            gaOptions: {
                name: 'shreddy',
            }
        });
        trackersList = ['shreddy'];
        break;
}



GoogleAnalytics.initialize(trackers);

const withTracker = (WrappedComponent, options = {}) => {

    //Google Analytics
    const trackPage = page => {
        GoogleAnalytics.set({
            page,
            ...options,
        });
        GoogleAnalytics.pageview(page, trackersList);
    };

    //Matomo


    return class extends Component {
        componentDidMount() {
            const page = this.props.location.pathname;
            trackPage(page);
        }

        componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };
};

export default withTracker;
