import ReactPixel from 'react-facebook-pixel';
import axios from 'axios';
import {bugsnagClient} from "./bugSnag";

let pixelId, pixelInitialised = false;

// need to fetch pixel ids
axios.get('https://s3.eu-west-2.amazonaws.com/aflete-checkout/fbpixels.json')
    .then(function (response) {
        pixelId = response.data[window.location.host];
        ReactPixel.init(pixelId, {}, { debug: false, autoConfig: false });
        ReactPixel.pageView();
        pixelInitialised = !!pixelId;
    }).catch(function (error) {
    bugsnagClient.notify(error, { context: "Error fetching fb pixel" });
        console.log(error);
    });


export { ReactPixel, pixelInitialised };
