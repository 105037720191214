import React, {Fragment} from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import {FormattedMessage} from 'react-intl';

const AldoThankYou = ({styles}) => (
    <div className="thank-you thank-you-grace">{``}
        <div className="mainDesktop__container">
            <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png"
                 alt=""/>
            <p><strong>Your payment has been processed successfully and you will receive an order confirmation email
                shortly.</strong></p>
            <p>To access members area just login below using the same email and password you just created.</p>
            <div>
                <img className="logo-top" src="https://assets.aflete.com/checkout/expedition-fit-logo.png" alt=""/>
            </div>
            <a href="https://members.aldokane.com/">
                <RaisedButton
                    type="submit"
                    label="Login"
                    style={styles.button}
                    primary
                />
            </a>
            <p className="problem-line">
                <FormattedMessage
                    id="checkout.needHelp"
                    defaultMessage="Need Help? Visit > "
                />
                <a href="https://support.aldokane.com/hc/en-us">
                    <FormattedMessage
                        id="checkout.needHelpLink"
                        defaultMessage="Customer Support"
                    />
                </a>
            </p>
            <p className="genflow-logo"><img
                src="https://assets.aflete.com/checkout/genflow-powered-black.png" alt=""/></p>
        </div>
    </div>
);

export default AldoThankYou;
