import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';

const PatriciaThankYou = ({styles}) => (
    <div className="thank-you">{``}
        <div className="mainDesktop__container">
            {/* <img className="check-box" src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/checkbox.png" alt=""/> */}
            <img alt="" className="brand-logo" src="https://ohoneydiary.com/img/logo-2.png"/>
            <img alt="" className="honey-photo" src="https://scontent-waw1-1.cdninstagram.com/vp/bb415368787b702a4abfe5cfcb332a7c/5D861855/t51.2885-15/e35/57052410_228272721465997_2124727753446950585_n.jpg?_nc_ht=scontent-waw1-1.cdninstagram.com"/>
            <h2>Thank You!</h2>
            <p>Please note this is a pre-order.  It will take 6 weeks for your order to be dispatched.  We will send you an email once your order is on its way.</p>
            <p>You will receive an order confirmation email with all of your order details.  It can take up to 24 hours for your order confirmation to arrive.  Please, check your spam or junk folders if it's not in your inbox after this time.</p>
            <p>Need Help? Visit > <a href="https://support.ohoneydiary.com/hc/en-us/requests/new">Customer Support</a> </p>
            <p><a href="https://ohoneydiary.com/">
                <RaisedButton
                    type="submit"
                    label="Back to homepage"
                    style={styles.button}
                    primary
                />
            </a></p>
        </div>
    </div>
);

export default PatriciaThankYou;