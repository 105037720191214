import {
    APP_LOAD,
    UPDATE_STEP,
    CHANGE_STEP,
    CART_NEEDS_BILLING,
    CART_PAY_WAITING_START,
    CART_PAY_WAITING_END,
    CART_CAN_SUBMIT,
    CART_PAID,
    CART_SET_TYPE,
    ALERT_OPEN,
    ALERT_CLOSE,
    UPDATE_CHANNEL_ID,
} from '../actions/actionTypes';

import { getChannelId } from '../services/messaging';

const defaultState = {
    stepIndex: 0,
    stepReady: {
        personal: false,
        shippingAddress: false,
        shippingOptions: false,
        billingAddress: false,
        taxTotal: false,
        card: false,
    },
    isFetching: false,
    isDigital: true,
    needs_billing: true,
    channelId: getChannelId(),
    payWaiting: false,
    error: null,
    alertOpen: false,
    alertMessage: 'There has been a problem, please contact with support',
    canSubmit: false,
    submitted: false,
    loginState: 0,
    visited: [],
    order: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case APP_LOAD:
            return {
                ...state,
                appLoaded: true,
            };
        case CART_SET_TYPE:
            return {...state, isDigital: action.value};
        case CART_NEEDS_BILLING:
            return {...state, needs_billing: action.value};
        case UPDATE_STEP:
            return {
                ...state,
                stepReady: {...state.stepReady,[action.key] : action.value},
            };
        case CHANGE_STEP:
            return {
                ...state,
                stepIndex: action.payload,
            };
        case CART_CAN_SUBMIT:
            return { ...state, canSubmit: true };
        case CART_PAY_WAITING_START:
            return {...state, payWaiting: true };
        case CART_PAY_WAITING_END:
            return {...state, payWaiting: false };
        case CART_PAID:
            return {
                ...state,
                submitted: true,
                order: action.payload
            };
        case ALERT_OPEN:
            return {
                ...state,
                alertOpen: true,
            alertMessage: action.message,
            };
        case ALERT_CLOSE:
            return {
                ...state,
                alertOpen: false,
            alertMessage: 'There has been a problem, please contact with support',
        };
    case UPDATE_CHANNEL_ID:
        return {
            ...state,
            channelId: action.channelId,
            };
        default:
            return state;
    }
};
