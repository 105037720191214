import { ReactPixel, pixelInitialised } from '../services/reactPixel';

import { CHANGE_STEP, CART_PAID, CART_NEEDS_BILLING } from "../actions/actionTypes";

const reactPixelMiddleware = store => next => action => {

    next(action);

    if (pixelInitialised) {
        let pixelState = store.getState();
        const stage = pixelState.common.stepIndex + 1;
        const stageDesc = {
            1: "InitiateCheckout",
            2: "CompleteRegistration",
            3: "AddPaymentInfo",
        }

        switch (action.type) {
            case CART_NEEDS_BILLING:
                let address_obj = pixelState.cart.byHash
                let address_items_in_cart = []
                for (const property in address_obj) {
                    let address_itemId = address_obj[property].product_id;
                    let address_item_name = address_obj[property].content.name.split(" ").join("")
                    let address_is_digital = address_obj[property].content.is_digital
                    let address_price = address_obj[property].content.currencies[0].price
                    let address_quantity = address_obj[property].content.qty
                    let address_full_item = {id: address_itemId, name: address_item_name, digital: address_is_digital, price: address_price, quantity: address_quantity}
                    address_items_in_cart.push(address_full_item)
                }
                ReactPixel.fbq('trackCustom', 'Checkout Address', {
                    value: (pixelState.cart.totals.total / 100).toFixed(2),
                    currency: pixelState.personal.currency_code,
                    contents: address_items_in_cart
                });
                break;
            case CART_PAID:
                let obj = pixelState.cart.byHash
                let items_in_cart = []
                for (const property in obj) {
                    let itemId = obj[property].product_id;
                    let item_name = obj[property].content.name.split(" ").join("")
                    let is_digital = obj[property].content.is_digital
                    let price = obj[property].content.currencies[0].price
                    let quantity = obj[property].content.qty
                    let full_item = {id: itemId, name: item_name, digital: is_digital, price: price, quantity: quantity}
                    items_in_cart.push(full_item)
                }
                ReactPixel.fbq('trackCustom', 'Order Completed', {
                    value: (pixelState.cart.totals.total / 100).toFixed(2),
                    currency: pixelState.personal.currency_code,
                    contents: items_in_cart
                });
                break;
            case CHANGE_STEP:
                ReactPixel.fbq('trackCustom', stageDesc[stage]);
                if (stageDesc[stage] == "CompleteRegistration") {
                    let address_obj = pixelState.cart.byHash
                    let address_items_in_cart = []
                    for (const property in address_obj) {
                        let address_itemId = address_obj[property].product_id;
                        let address_item_name = address_obj[property].content.name.split(" ").join("")
                        let address_is_digital = address_obj[property].content.is_digital
                        let address_price = address_obj[property].content.currencies[0].price
                        let address_quantity = address_obj[property].content.qty
                        let address_full_item = {id: address_itemId, name: address_item_name, digital: address_is_digital, price: address_price, quantity: address_quantity}
                        address_items_in_cart.push(address_full_item)
                    }
                    ReactPixel.fbq('trackCustom', 'Checkout registration complete', {
                        value: (pixelState.cart.totals.total / 100).toFixed(2),
                        currency: pixelState.personal.currency_code,
                        contents: address_items_in_cart
                    });
                }

                break;
            default:
        }
    }
};

export default reactPixelMiddleware;
