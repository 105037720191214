import PropTypes from 'prop-types';

import _map from 'lodash/map';
import _get from 'lodash/get';

export default {
    toOrder: (props) => {
        //Validate props
        PropTypes.checkPropTypes(toOrderPropTypes, props, 'prop', 'toOrder');

        return _map(props.byHash, (item) => {
                let bundleVariants = null;
                let is_bundle = _get(item, 'content.is_bundle', false);
                if (is_bundle === true) {
                    bundleVariants = item.content.bundleVariants
                }
                return {
                    'variant': item.id,
                    'qty': ( props.hasOwnProperty('qtyByHash') ) ? props.qtyByHash[item.id] : 1,
                    'is_bundle': is_bundle,
                    'bundleVariants': bundleVariants
                }
            }
        )
    }
}

const toOrderPropTypes = {
    byId: PropTypes.array,
    byHash: PropTypes.object,
    totals: PropTypes.object
};