import {
    CART_CLEAN,
    BUNDLE_CREATE,
    BUNDLE_REMOVE,
    AVAILABLE_BUNDLES_SET
} from '../actions/actionTypes';

import _uniq from 'lodash/uniq';

const defaultState = {
    byId: [],
    byHash: {},
    selectedBundle: []
};

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case BUNDLE_CREATE: {
            return {
                ...state,
                byId: _uniq([...state.byId, action.payload.id]),
                byHash: {
                    ...state.byHash,
                    [action.payload.id]: action.payload
                }
            };
        }
        case BUNDLE_REMOVE: {
            const prunedIds = state.byId.filter(item => {
                return item !== action.id // return all the items not matching the action.id
            });
            delete state.byHash[action.id]; // delete the hash associated with the action.id

            return {
                ...state,
                byId: prunedIds,
                byHash: state.byHash
            }
        }
        case AVAILABLE_BUNDLES_SET: {
            return {
                ...state,
                selectedBundle: {...action.payload}
            };
        }
        case CART_CLEAN: {
            return {
                ...state,
                byId: [],
                byHash: {}
            }
        }

        default:
            return state;
    }
};