import React from 'react';

import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';

export const bugsnagClient = bugsnag({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    appVersion: process.env.REACT_APP_BUGSNAG_APP_VERSION
});

export const ErrorBoundary = bugsnagClient.use(createPlugin(React));