import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import validator from 'validator';
import _forOwn from 'lodash/forOwn';


export const validateField = (name, rules, value, value2 = null) => {

    //Trim
    const trimmedValue = value.trim();

    const validations = [
        { rule: 'required', validator: () => !validator.isEmpty(trimmedValue) },
        { rule: 'isEmail', validator: () => validator.isEmail(trimmedValue) },
        { rule: 'equals', validator: () => validator.equals(trimmedValue, value2) },
        { rule: 'validPassword', validator: () => validator.isLength(trimmedValue, { min: 8, max: undefined }) },
        { rule: 'maxCharacters', validator: () => validator.isLength(trimmedValue, { min: 0, max: 35 }) },
    ];

    //Will return false is no errors
    let msg = false;

    _forEach(rules, (error, name) => {
        const rule = _find(validations, ['rule', name]);
        if (!rule.validator()) {
            msg = error;
            return false;
        }
        return true;
    });
    return msg;
};

export const validateForm = (errors) => {

    let is_valid = true;

    _forOwn(errors, (value, key) => {
        if (value !== false) {
            is_valid = false;
            return false;
        }
    });

    return is_valid;

};
