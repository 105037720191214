// @flow

import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';

import { LayoutProps, LayoutState } from "../TypeDefinitions";

import Login from './forms/login';
import Register from './forms/register';

import RaisedButton from 'material-ui/RaisedButton';

import {tokenIsValid} from "../helpers";
import {logout, alreadyLogged} from "../actions";
import muiThemeable from "material-ui/styles/muiThemeable";
import { FormattedMessage } from 'react-intl';

class AuthLayout extends Component <LayoutProps, LayoutState> {

    state = {
        loggedIn: false,
        atLogin: false
    };

    logoutAction = (e) => {
        e.preventDefault();
        this.props.logout();
    };

    goToLogin = () => {
        this.setState(prevState => ({
            atLogin: true,
        }));
    };

    goToRegister = () => {
        this.setState(prevState => ({
            atLogin: false,
        }));
    };

    async componentDidMount() {
        //Update auth status when component loads
        if ( this.props.auth.idToken ) {
            const result = await tokenIsValid();
            result ? this.props.alreadyLogged(result) : this.props.logout();
        }
    }

    componentWillReceiveProps(newProps) {
        //Check if logged & token is not expired when Component receives new props
        const isLoggedIn = !!newProps.auth.idToken;
        this.setState(prevState => ({loggedIn: isLoggedIn}));
    }

    render() {
        if (this.state.loggedIn) {
            return (
                <div>
                    <p><span role='img' aria-label='party' className='inline_emoji_left'>🎉 </span> Congratulations! you're logged in.<br/><a href="/logout" onClick={this.logoutAction}>Log out</a></p>

                </div>
            )
        }

        if (this.state.atLogin) {
            return (
                <Fragment>
                    <div>
                        <span>I don't have a user. </span>
                        <RaisedButton
                            label="Register here"
                            primary={true}
                            className="loginBtn"
                            style={this.props.muiTheme.personal.buttons.yes}
                            onClick={this.goToRegister}
                        />
                    </div>
                    <Login/>
                </Fragment>
            )
        }else {
            return (
                <Fragment>
                    <div>
                        <span>
                            <FormattedMessage
                                id="checkout.alreadyUser"
                                defaultMessage="Already have an account? "
                            />
                        </span>
                        <RaisedButton
                            label={
                                <FormattedMessage
                                    id="checkout.login"
                                    defaultMessage="Login"
                                />
                            }
                            primary={true}
                            className="loginBtn"
                            style={this.props.muiTheme.personal.buttons.yes}
                            onClick={this.goToLogin}
                        />
                    </div>
                    <Register/>
                </Fragment>
            )
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout()),
    alreadyLogged: (result) => dispatch(alreadyLogged(result))
});

const mapStateToProps = state => (state);

export default muiThemeable()(connect(mapStateToProps, mapDispatchToProps)(AuthLayout));
