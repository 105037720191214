import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _head from 'lodash/head';
import _find from 'lodash/find';

import equal from 'deep-equal';

import {
    CART_ADD,
    CART_UPDATE,
    CART_REMOVE,
    CART_CLEAN,
    ADDRESS_AVAILABLE_OPTIONS_SET,
    ADDRESS_UPDATE_INFO,
    ADDRESS_SHOULD_UPDATE_AVAILABLE_OPTIONS,
    ADDRESS_OPTIONS_CLEAN,
    PERSONAL_SET_COUNTRY,
    PERSONAL_SET_CURRENCY,
    CART_SHOULD_UPDATE_TOTALS,
} from '../actions/actionTypes';

import Currencies from '../services/currencies';

import validateShippingOptions from '../utils/validateShippingOptions';

const getAvailableShippingOptions = (store, state) => {
    const shippingCountry = _find(state.shipping.fetchedShippingZones.countries, o => o.code == state.personal.country);
    return shippingCountry ? validateShippingOptions(shippingCountry, _get(state, 'shipping.fetchedShippingZones.options'), state) : [];
};

const shippingMiddleware = store => next => action => {

    next(action);

    let actions = [
        CART_ADD,
        CART_UPDATE,
        CART_REMOVE,
        CART_CLEAN,
        ADDRESS_SHOULD_UPDATE_AVAILABLE_OPTIONS,
        PERSONAL_SET_COUNTRY,
        PERSONAL_SET_CURRENCY
    ];

    let shouldAct = _findIndex(actions, function (o) {
        return o === action.type;
    });

    if (shouldAct >= 0) {
        const state = store.getState();
        const oldShippingOptions = {...state.shipping.availableOptions};
        const shippingOptions = getAvailableShippingOptions(store, state);

        if (!equal(oldShippingOptions, shippingOptions) || oldShippingOptions === []) {
            store.dispatch({
                type: ADDRESS_AVAILABLE_OPTIONS_SET,
                payload: {
                    availableOptions: shippingOptions,
                },
            });
        }

    }

    if (action.type === ADDRESS_UPDATE_INFO) {
        //If address country changes we should change country in common, change currency, change available shipping options, maybe remove selected shipping options
        if (action.address_type === 'shipping' && action.key === 'country') {
            //Change country
            store.dispatch({type: PERSONAL_SET_COUNTRY, payload: action.value});
            //Change currency (products can override this)
            //const countryCurrency = Currencies.getCurrencyByCountry(action.value);
            //const currency = _head(countryCurrency.currencies);
            //store.dispatch({type: PERSONAL_SET_CURRENCY, currency_code: currency.code, forced:false});
            //Change shipping options
            store.dispatch({type: ADDRESS_SHOULD_UPDATE_AVAILABLE_OPTIONS});
            // Change totals
            store.dispatch({ type: CART_SHOULD_UPDATE_TOTALS });
        }
    }
};

export default shippingMiddleware
