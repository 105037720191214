import _uniq from 'lodash/uniq';
import {
    CART_CLEAN,
    ADDRESS_OPTIONS_CREATE,
    ADDRESS_OPTIONS_REMOVE,
    ADDRESS_OPTIONS_CLEAN,
    ADDRESS_AVAILABLE_OPTIONS_SET,
    ADDRESS_OPTIONS_TRACKING,
} from '../actions/actionTypes';


const defaultState = {
    selectedOption: 0,
    availableOptions: [],
    fetchedShippingZones: [],
    trackingPhone: {
        code: '',
        number: '',
    },
};

export default (state = defaultState, action = {}) => {
    switch (action.type) {
        case ADDRESS_OPTIONS_CREATE: {
            return {
                ...state,
                selectedOption: action.payload,
            };
        }
        case ADDRESS_OPTIONS_REMOVE: {
            return {
                ...state,
                selectedOption: 0,
            };
        }
        case ADDRESS_AVAILABLE_OPTIONS_SET: {
            return {
                ...state,
                selectedOption: 0,
                ...action.payload
            };
        }
        case ADDRESS_OPTIONS_TRACKING: {
            return { ...state, trackingPhone: { ...state.trackingPhone, [action.key]: action.value } };
        }
        case CART_CLEAN:
        case ADDRESS_OPTIONS_CLEAN:
        {
            return {
                ...state,
                ...defaultState,
            };
        }

        default:
            return state;
    }
};
