import React from 'react'

/**
 * @description
 * Redux action types
 */
export const API_VARIANTS_TIMEOUT = 'Your checkout has timed out, please refresh your page and try again.';
export const API_VARIANTS_NOT_FOUND = 'The product you are trying to purchase is either not available or not allowed.';
export const API_VARIANTS_NO_STOCK = 'Unfortunately, the product is not in stock right now';
export const API_VARIANTS_UNDEFINED = 'There was an error in loading this product. Please contact our support so we can fix this.';
export const API_VARIANTS_CURRENCY_NO_PRICE = 'This product is not available in your currency.';

export const COUNTRY_API_TIMEOUT = 'We cannot locate your location so the prices will be shown in GBP';

export const LOGIN_INVALID = ['The password is incorrect, please try again. ', <br />, 'If you need help, please contact us on the Support service'];
export const LOGIN_TIMEOUT = 'There has been a problem with the login. Use your email and password in the register form';

export const REGISTER_INVALID = ['There was an error with your registration', <br />, 'If you need help, please contact us on the Support service'];
export const REGISTER_LOGIN_INVALID = ['It seems you have already an account, but your password is not correct', <br />, "Click on 'I already have a user at GENFLOW' to change your password."];
export const REGISTER_CHANGE_PASSWORD = 'If the email exists, you will receive an email with instructions to change your password';
export const REGISTER_CHANGE_PASSWORD_INVALID_EMAIL = 'Sorry, your email is not valid or is empty.';
export const REGISTER_CHANGE_PASSWORD_ERROR = 'The process to recover the password has failed. Please contact us on the Support service';
export const REGISTER_CHANGE_PASSWORD_VALIDATION_ERROR = 'The email or the new password is not valid';
export const REGISTER_CHANGE_PASSWORD_SUCCESS = 'Your password has been reset to your new password. Please log in.';

export const CURRENCY_NOT_AVAILABLE = 'This product is not available in your currency. Price is shown in GBP';

export const STRIPE_TIMEOUT = 'Your payment timed out as you took too long. Please refresh your page and try again.';
export const STRIPE_CARD_INVALID = 'You entered the wrong card details, please check and try again.';
export const STRIPE_CARD_DECLINED = 'Your card has been declined. This could be because your bank is stopping the transaction. Contact with your bank and try again, please.';
export const STRIPE_CARD_UNDEFINED = 'You entered the wrong card details, please check and try again.';

export const BRAINTREE_DECLINED = 'Paypal did not authorise the payment. Please try a different payment method.';
export const BRAINTREE_UNDEFINED = 'Paypal is not available right now. We could not charge you. Please try a different Payment method.';

export const API_ORDER_TIMEOUT = ['Your order could not be completed. Please try again or contact our support center', <a target="_blank" rel="noopener noreferrer" href="https://support.aflete.com">https://support.aflete.com</a>,'if it fails again.'];
export const API_ORDER_INVALID = 'There has been an error processing your order, please try again and contact us if this persists';
export const API_ORDER_FAILED_VALIDATION = 'Seems like there was an error when completing your order. Please, try again.';
export const API_ORDER_NOT_CREATED = 'There has been an error processing your order. Please try again.';
export const API_ORDER_NOT_COMPLETED = ['We have created the order and made the charge, but the order is not completed, Please contact us at ', <a target="_blank" rel="noopener noreferrer" href="https://support.aflete.com">https://support.aflete.com</a>];
export const API_ORDER_ERROR_WAITING = 'Your order has failed. The payment has been declined by your bank.';

export const COUPON_NOT_VALID =  'Coupon not valid';

export const SHIPPING_OPTION_NOT_VALID = ['Shipping method for one of your products is not available, If you need help, please go to ', <a target="_blank" rel="noopener noreferrer" href="https://support.aflete.com">https://support.aflete.com</a>];
