// @flow

import Amplify, { Auth } from 'aws-amplify'
import axios from 'axios';
import _defaultTo from 'lodash/defaultTo';
import _has from 'lodash/has';
import _get from 'lodash/get';

export default class Cognito {

    constructor () {
        Amplify.configure({
            Auth: {
                userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USER_USER_POOL_ID,
                region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,
                userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
                identityPoolId: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
                mandatorySignIn: process.env.REACT_APP_AMPLIFY_AUTH_MANDATORY_SIGN_IN,
                authenticationFlowType: process.env.REACT_APP_AMPLIFY_AUTH_AUTHENTICATION_FLOW_TYPE,
            },
        });
    }

    getUser = () => Auth.currentAuthenticatedUser();

    getToken = () => Auth.currentSession();

    _getUserName = (email: string, vendor: any) => {

        const vendorUuid = _get(vendor, 'cognitoId', _get(vendor, 'id', null))

        if (email && vendorUuid){
            return `${email.toLowerCase()}#${vendorUuid}`;
        }else{
            throw "Username is not valid"
        }

    }

    login = async (username: string, password: string, options: any):any => {

        const vendorUserName = this._getUserName(username, options.vendor);
        const result = await Auth.signIn(
            vendorUserName,
            password
        );

        if (_has(result, 'signInUserSession')){

        return {
            accessToken: result.signInUserSession.accessToken.jwtToken,
            idToken: result.signInUserSession.idToken.jwtToken,
            expiresAt: result.signInUserSession.idToken.payload.exp
        };
        }

        return false;
    };



    signup = async (data: any) => {
        const {firstName, lastName, username, password} = data;
        const email = username.toLowerCase();
        const result = await Auth.signUp({
            username: this._getUserName(email, data.options.vendor),
            password: password,
            attributes: {
                email: email,
                given_name: firstName,
                family_name: lastName,
            },
            clientMetadata: {
                password: password
            },
        });

        if (result) {
            return await this.login(username, password, {vendor:data.options.vendor})
        }
    };

    checkCurrentSession = async () => {
        const result = await Auth.currentSession();
        return {
            accessToken: result.accessToken.jwtToken,
            idToken: result.idToken.jwtToken,
            expiresAt: result.idToken.payload.exp
        };
    };

    recoverPassword = async (email: string, options: any) => {
        const basePasswordResetURL = _defaultTo( process.env.REACT_APP_COGNITO_PASSWORD_RESET_URL, '');

        axios({
            method: 'post',
            url: `${basePasswordResetURL}/reset-account-password`,
            data: {
                username: this._getUserName(email, options.vendor),
                onlyCode: true
            }
        }).then(response => {
            return response;
        }).catch(error => {
            return error.response;
        })
    };

    resetPassword = async (email: string, code: string, newPassword: string, vendor: any) => {
        const basePasswordResetURL = _defaultTo( process.env.REACT_APP_COGNITO_PASSWORD_RESET_URL, '');

        const username = this._getUserName(email, vendor);

        const result = await axios({
            method: 'post',
            url: `${basePasswordResetURL}/reset-account-password`,
            data: {
                username: username,
                resetCode: code,
                password: newPassword
            }
        });

        return {
            username: email,
            password: newPassword,
            options: {vendor}
        };
    };

    logout = async () => {
        await Auth.signOut();
    };
}
