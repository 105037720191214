import uuidv1 from 'uuid/v1';


export function getChannelId() {
    //let channelId = localStorage.getItem('channelId');
    //return channelId ? channelId : generateChannelId();
    return generateChannelId();
}

function generateChannelId(){
    //const channelId = uuidv1();
    //localStorage.setItem('channelId', channelId);
    //return channelId;
    return uuidv1();
}