import React, {Component} from 'react';
import { connect } from 'react-redux';

import {signUp, signUpData} from '../../actions';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

import muiThemeable from "material-ui/styles/muiThemeable";
import {validateField, validateForm} from "../../../../services/validate";

import { FormattedMessage } from 'react-intl';

class Register extends Component {

    state = {
        values: {
            first_name: '',
            last_name: '',
            email: '',
            email_confirm: '',
            password: '',
            password_confirm: '',
        },
        errors: {
            first_name: null,
            last_name: null,
            email: null,
            email_confirm: null,
            password: null,
            password_confirm: null,
        },
        rules: {
            first_name: {
                required: 'First name is required'
            },
            last_name: {
                required: 'Last name is required'
            },
            email: {
                required: 'Email is required',
                isEmail: 'Email is not valid'
            },
            email_confirm: {
                required: 'Email confirmation is required',
                equals: 'Email doesn\'t match'
            },
            password: {
                required: 'Password is required',
                validPassword: 'Password should have more than 7 characters'
            },
            password_confirm: {
                required: 'Password confirmation is required',
                equals: 'Password doesn\'t match'
            }
        }
    };

    handleKeyPress(event) {
        event.preventDefault();
    }

    updateField = (e, value) => {
        const name = e.target.name;

        const rules = this.state.rules[name];

        let value2 = null;

        if (name === "email_confirm")
            value2 = this.state.values.email;

        if (name === "password_confirm")
            value2 = this.state.values.password;

        let is_valid = validateField(name, rules, value, value2);

        this.setState(prevState => ({
            values: {...prevState.values, [name]: value},
            errors: {...prevState.errors, [name]: is_valid}
        }));
    };

    registerAction = (e) => {
        e.preventDefault();

        if (!validateForm(this.state.errors)) return false;

        const { email: username, password, first_name :firstName, last_name: lastName } = this.state.values;

        if (username && password) {
            this.props.register({firstName, lastName, username, password, options: {vendor: this.props.vendor}});
        }
    };


    render() {
        return (
            <div>
                <TextField
                    name="first_name"
                    ref="first_name"
                    id="personal_first_name"
                    style={this.props.muiTheme.personal.textField}
                    defaultValue={this.state.values.first_name}
                    hintText={
                        <FormattedMessage
                            id="checkout.firstName"
                            defaultMessage="First name"
                        />
                    }
                    errorText={this.state.errors.first_name}
                    onChange={this.updateField}
                />
                <TextField
                    name="last_name"
                    ref="last_name"
                    id="personal_last_name"
                    style={this.props.muiTheme.personal.textField}
                    defaultValue={this.state.values.last_name}
                    hintText={
                        <FormattedMessage
                            id="checkout.lastName"
                            defaultMessage="Last name"
                        />
                    }
                    errorText={this.state.errors.last_name}
                    onChange={this.updateField}
                />
                <TextField
                    name="email"
                    ref="email"
                    id="personal_email"
                    style={this.props.muiTheme.personal.textField}
                    defaultValue={this.state.values.email}
                    hintText={
                        <FormattedMessage
                            id="checkout.email"
                            defaultMessage="Email"
                        />
                    }
                    type="email"
                    errorText={this.state.errors.email}
                    onChange={this.updateField}
                />
                <TextField
                    name="email_confirm"
                    ref="email_confirm"
                    id="personal_email_confirm"
                    style={this.props.muiTheme.personal.textField}
                    defaultValue={this.state.values.email_confirm}
                    hintText={
                        <FormattedMessage
                            id="checkout.emailConfirm"
                            defaultMessage="Email confirm"
                        />
                    }
                    type="email"
                    errorText={this.state.errors.email_confirm}
                    onChange={this.updateField}
                />
                <TextField
                    name="password"
                    ref="password"
                    id="personal_password"
                    style={this.props.muiTheme.personal.textField}
                    defaultValue={this.state.values.password}
                    hintText={
                        <FormattedMessage
                            id="checkout.password"
                            defaultMessage="Password"
                        />
                    }
                    type="password"
                    errorText={this.state.errors.password}
                    onChange={this.updateField}
                />
                <TextField
                    name="password_confirm"
                    ref="password_confirm"
                    id="personal_password_confirm"
                    style={this.props.muiTheme.personal.textField}
                    defaultValue={this.state.values.password_confirm}
                    hintText={
                        <FormattedMessage
                            id="checkout.passwordConfirm"
                            defaultMessage="Password confirm"
                        />
                    }
                    type="password"
                    errorText={this.state.errors.password_confirm}
                    onChange={this.updateField}
                />
                <RaisedButton
                    label={
                        <FormattedMessage
                            id="checkout.signup"
                            defaultMessage="Signup"
                        />
                    }
                    className="loginBtn-2"
                    primary={true}
                    style={this.props.muiTheme.personal.buttons.yes}
                    onClick={this.registerAction}
                    secondary={true}
                />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    register: ({username, password, firstName, lastName, options}) => {
        dispatch(signUp({firstName, lastName, username, password, options }));
        dispatch(signUpData({username, firstName, lastName}))
    }
});

export default muiThemeable()(connect(state => ({vendor: state.vendor}), mapDispatchToProps)(Register));
