import {
    CARD_UPDATE_INFO,
    CARD_REMOVE,
    STRIPE_REQUIRES_ACTION,
    CART_CONFIRM_PAYMENT
} from '../actions/actionTypes';

const defaultState = {
    token: "",
    paymentType: null,
    paymentIntentId: "",
    paymentIntentSecret: "",
    requiresAction: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CARD_UPDATE_INFO:
            return { ...state,
                [action.key]: action.value,
                paymentType: action.paymentType
            };
        case CARD_REMOVE: {
            return { ...state, token: "" }
        }
        case STRIPE_REQUIRES_ACTION: {
            return { ...state,
                paymentIntentId: action.paymentIntentId,
                paymentIntentSecret: action.paymentIntentSecret,
                requiresAction: true
            };
        }
        case CART_CONFIRM_PAYMENT: {
            return {
                ...state,
                requiresAction: false
            }
        }
        default:
            return state;
    }
};
