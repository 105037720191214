import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

const Alert = ({ handleClose, content, openForm }) => (
  <div>
    <Dialog
      actions={<FlatButton label="Close" primary={true} onClick={handleClose} />}
      modal={false}
      open={openForm}
      onRequestClose={handleClose}
    >
      <div style={{ whiteSpace: "pre-wrap" }}>{content}</div>
    </Dialog>
  </div>
);

Alert.propTypes = {
  openForm: PropTypes.bool.isRequired,
  content: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Alert;
