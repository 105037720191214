import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { addLocaleData, IntlProvider } from "react-intl";
import zh from "react-intl/locale-data/zh";
import "./App.css";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import afleteTheme from "./styles/material-ui";
import { saveState } from "./services/localStorageService";

import store from "./store";

import CartManager from "./components/containers/cartManager";
import withTracker from "./components/containers/analyticsTracker";
import ThankYou from "./components/presentational/pages/thankYou";
import TermsPage from "./components/presentational/pages/terms";
import NotFoundPage from "./components/presentational/pages/notFoundPage";

import setupLanguage from "./lang/setupLanguage";

addLocaleData([...zh]);

const muiTheme = getMuiTheme(afleteTheme);

const lang = new URL(window.location).searchParams.get("lang");
const i18nConfig = setupLanguage(lang);

store.subscribe(() => {
  saveState({
    auth: store.getState().auth,
    cart: store.getState().cart,
    personal: store.getState().personal,
    vendor: store.getState().vendor,
    coupon: {
      byId: store.getState().coupon.byId,
      byHash: store.getState().coupon.byHash,
    },
    bundles: store.getState().bundles,
  });
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <IntlProvider locale={i18nConfig.locale} messages={i18nConfig.messages}>
          <MuiThemeProvider muiTheme={muiTheme}>
            <Router>
              <Switch>
                <Route path="/thank-you" component={withTracker(ThankYou)} />
                <Route exact path={`/terms`} component={withTracker(TermsPage)} />
                <Route exact path="/cart" component={withTracker(CartManager)} />
                <Route path="/cart/v3/:encodedJSON" component={withTracker(CartManager)} />
                <Route path="/cart/bundle/:bundleId/:variantIds" component={withTracker(CartManager)} />
                <Route path="/cart/bundle/:bundleIds" component={withTracker(CartManager)} />
                <Route path="/cart/v2/:cartIds" component={withTracker(CartManager)} />
                <Route path="/cart/:id" component={withTracker(CartManager)} />
                <Route path="*" component={withTracker(NotFoundPage)} />
              </Switch>
            </Router>
          </MuiThemeProvider>
        </IntlProvider>
      </Provider>
    );
  }
}

export default App;
