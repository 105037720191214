import React from 'react'

const TermsPage = () =>
    <div className="terms">
        <div className="mainDesktop__container">
            <h3><b>Terms & Conditions</b></h3>

            <p>You are making a purchase from the Seller who is the legal owner of the domain and business (Website) you
                are visiting. The Website has been developed using the Genflow Software. </p>
            <p>These Terms and Conditions affect your legal rights and obligations. If you do not agree to be bound by
                all of these Terms and Conditions, do not access or use the Website. </p>
            <ol>
                <li>General Terms of Use</li>
                <li>Acceptable Use</li>
                <li>Privacy Policy</li>
                <li>Cookie Policy</li>
            </ol>
            <p>(and together, are the “<b>Terms and Conditions</b>”).</p>
            <h4><b>Contact Seller</b></h4>
            <p>If you have any questions regarding these Terms and Conditions, or if you have any questions, complaints,
                claims or other legal concerns go to the support section of the website and create a help ticket.</p>
            <h4><b>General Terms of Use</b></h4>
            <ol>
                <li>You must create an Account in order to use the Website.</li>
                <li>You must be at least 13 years old to use the Website.</li>
                <li>You must use the Website only in accordance with our Acceptable Use Policy.</li>
                <li>You are responsible for any activity that occurs through your Account and you agree you will not
                    sell, transfer, license or assign your account, followers, username, or any Account rights. With the
                    exception of people or businesses that are expressly authorized to create Accounts on behalf of
                    their employers or clients, Website prohibits the creation of and you agree that you will not create
                    an account for anyone other than yourself. You also represent that all information you provide or
                    provided to Website upon registration and at all other times will be true, accurate, current and
                    complete and you agree to update your information as necessary to maintain its truth and accuracy.
                </li>
                <li>You agree that you will not solicit, collect or use the login credentials of other Website users.
                </li>
                <li>You are responsible for keeping your password secret and secure.</li>
                <li>Any links shared on Website are your responsibility and we take no responsibility for any third
                    party links shared on the Website. If we find that any third party links you have posted do not
                    comply with our Terms and Conditions, you will be removed from the Website.
                </li>
                <li>You must not defame, stalk, bully, abuse, harass, threaten, impersonate or intimidate people or
                    entities and you must not post private or confidential information via the Website. If found doing
                    so we would delete your account and report you to the relevant authorities.
                </li>
                <li>You may not use the Website for any illegal or unauthorized purpose.</li>
                <li>You are solely responsible for your conduct and Content that you submit, post or display on or via
                    the Website.
                </li>
                <li>You must not change, modify, adapt or alter the Website or change, modify or alter another website
                    so as to falsely imply that it is associated with the Website or Website
                </li>
            </ol>
            <p>You must not create or submit unwanted email, comments, likes or other forms of commercial or harassing
                communications (a/k/a “spam”) to any Website users.</p>

            <h4><b>RETURNS</b></h4>

            <h5><b>Refund and Returns Policy</b></h5>

            <p>This Refund and Returns Policy applies for all products purchased through the Website.</p>
            <p>You acknowledge that Website provides a platform for users to purchase Products from a Seller, and that a
                sales contract is formed between you and a Seller directly; </p>
            <p>If you purchase a Product from the Website checkout, you understand that:</p>
            <ol>
                <li>you are not buying a Product from a 3rd party, but from the Seller;</li>
                <li>you may contact Website directly about your purchase; and</li>
                <li>delivery of a Product is handled by a third party;</li>
                <li>On all digital content you will lose your 14 day right to cancel as you can instantly see the
                    content upon purchase.
                </li>
            </ol>
            <p>After making a purchase, you will be sent confirmation of the sales contract between the Seller and
                yourself via email promptly after the conclusion of the contract. </p>
            <p>You acknowledge that Products purchased through the Website software are deemed to fall within the low
                value off-premises contract exemption to The Consumer Contracts (Information, Cancellation and
                Additional Charges) Regulations 2013.</p>
            <p>You may be requested to provide proof of purchase when requesting a refund.</p>
            <p>If you have any questions, please email contact the Sellers support.</p>
            <h5><b>RETURNS FROM THE POSTAL Website</b></h5>
            <p>If your order is returned to us due to non-delivery or incorrect address, it will be automatically
                cancelled and refunded and you will need to create another order again. You will not be refunded for the
                postage as we have incurred the cost sending you the order.</p>
            <h5><b>RETURNS BY CUSTOMER</b></h5>
            <p>Any returned parcels sent by the customer without acknowledgment from a member of the support team will
                not be returned/exchanged or refunded.</p>
            <h4><b>DIGITAL PRODUCTS</b></h4>
            <p>You acknowledge that the Seller is not obliged to provide a refund on digital Products (which includes
                all guides) purchased through the Website Software.</p>
            <p>Once a download of a digital product has started, you do not have a right to cancel the purchase and to
                request a refund as per Gov UK rules.</p>
            <p>Refunds for digital products shall only be given in the following limited circumstances:</p>
            <ol>
                <li>If there was a technical issue and the content was never received by the buyer.</li>
                <li>If the transaction was not a genuine transaction (e.g. card fraud etc)</li>
                <li>If there was a duplicate order or payment due to a technical fault. Genuine multiple orders by the
                    same person are not eligible for a refund.
                </li>
            </ol>
            <h4><b>PHYSICAL PRODUCTS </b></h4>
            <p>You acknowledge that the Seller is not obliged to provide a refund on physical Products purchased through
                the Website Software.</p>
            <p>Refunds for physical Products shall only be given in the following circumstances:</p>
            <ol>
                <li>If the item was ripped or broken which made it non-functioning.</li>
                <li>If the transaction was not a genuine transaction (card fraud etc)</li>
                <li>If there was a duplicate order or payment due to a technical fault. Genuine multiple orders by the
                    same person are not eligible for a refund.
                </li>
            </ol>
            <h4><b>FAULTY PRODUCTS </b></h4>
            <p>If you have received a faulty product and it's within 60 days from purchase please send in a picture of
                the faulty product and we can exchange this but unfortunately, there will be no refunds.</p>
            <h4><b>Delivery of Products</b></h4>
            <p>The Website uses Stripe and PayPal as a third party payment providers. Website does not hold any payment
                details of users.</p>
            <p>All Products purchased through the Website are delivered via Royal Mail or another courier. All deliver
                information is listed on the website prior to purchase and a customer’s delivery information is listed
                in the confirmation email.</p>
            <p>When your item is dispatched you will receive an email with information on how long the delivery will
                take and what Website was used. If the delivery was tracked a tracking number and link will also be
                provided.</p>
            <p>You also may receive a text message once your order is dispatched however this is dependent on you adding
                your phone number when completing your order and if your mobile carrier supports our Website.</p>
            <p>All delivery of products is handled by a third party company.</p>
            <h4><b>In Stock Items</b></h4>
            <p>At the time of purchase, you will be informed via email from Website regarding the anticipated dates of
                your delivery.</p>
            <h4><b>Pre-Order Items</b></h4>
            <p>For all pre-order sales (which shall be clearly marked as such), delivery dates are estimates only as the
                Product is still yet to be manufactured. You agree that you are purchasing a Product from the Seller
                which will only be delivered once it has been produced and that you will be notified via email when your
                Product has been dispatched.</p>
            <h4><b>Acceptable Use Policy</b></h4>
            <p>This acceptable use policy sets out the terms between you and us under which you may access the Website.
                This acceptable use policy applies to all users of, and visitors to, the Website.</p>
            <p>Your use of our site means that you accept, and agree to abide by, all the policies in this acceptable
                use policy, which form part of our Terms and Conditions.</p>
            <h4><b>Prohibited uses</b></h4>
            <p>You may use our site only for lawful purposes. You may not use our site:</p>
            <ul className="small">
                <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
                <li>For the purpose of harming or attempting to harm minors in any way.</li>
                <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with
                    our content standards.
                </li>
                <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional
                    material or any other form of similar solicitation (spam).
                </li>
                <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses,
                    worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar
                    computer code designed to adversely affect the operation of any computer software or hardware.
                </li>
            </ul>
            <p>You also agree:</p>
            <ul className="small">
                <li>Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions
                    of our General Terms of Use.
                </li>
                <li>Not to access without authority, interfere with, damage or disrupt:</li>
                <li>any part of our site;</li>
                <li>any equipment or network on which our site is stored;</li>
                <li>any software used in the provision of our site; or</li>
                <li>any equipment or network or software owned or used by any third party.</li>
                <li>Not to abuse our staff through emails and social media. Any form of abuse will result in your
                    account being blocked.
                </li>
            </ul>
            <p><br/></p>
            <p>We will provide clear information to you about the kind of Website offered, if it is moderated and what
                form of moderation is used (including whether it is human or technical).</p>
            <p>We will do our best to assess any possible risks for users (and in particular, for children) from third
                parties when they use any interactive Website provided on our site, and we will decide in each case
                whether it is appropriate to use moderation of the relevant Website (including what kind of moderation
                to use) in the light of those risks. However, we are under no obligation to oversee, monitor or moderate
                any interactive Website we provide on our site, and we expressly exclude our liability for any loss or
                damage arising from the use of any interactive Website by a user in contravention of our content
                standards, whether the Website is moderated or not.</p>
            <p>The use of any of our interactive Websites by a minor is subject to the consent of their parent or
                guardian. We advise parents who permit their children to use an interactive Website that it is important
                that they communicate with their children about their safety online, as moderation is not foolproof.
                Minors who are using any interactive Website should be made aware of the potential risks to them. <b>Content
                    standard.</b></p>
            <p>These content standards apply to any and all material which you contribute to our site
                (<b>contributions</b>), and to any interactive Websites associated with it.</p>
            <p>You must comply with the spirit and the letter of the following standards. The standards apply to each
                part of any contribution as well as to its whole.</p>
            <p>Contributions must:</p>
            <ul className="small">
                <li>Be accurate (where they state facts).</li>
                <li>Be genuinely held (where they state opinions).</li>
                <li>Comply with applicable law in the UK and in any country from which they are posted.</li>
            </ul>
            <p>Contributions must not:</p>
            <ul className="small">
                <li>Contain any material which is defamatory of any person.</li>
                <li>Contain any material which is obscene, offensive, hateful or inflammatory.</li>
                <li>Promote sexually explicit material.</li>
                <li>Promote violence.</li>
                <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or
                    age.
                </li>
                <li>Infringe any copyright, database right or trademark of any other person.</li>
                <li>Be likely to deceive any person.</li>
                <li>Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of
                    confidence.
                </li>
                <li>Promote any illegal activity.</li>
                <li>Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless
                    anxiety.
                </li>
                <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
                <li>Be used to impersonate any person, or to misrepresent your identity or affiliation with any
                    person.
                </li>
                <li>Give the impression that they emanate from us, if this is not the case.</li>
                <li>Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement
                    or computer misuse.
                </li>
            </ul>
            <h4><b>Suspension and termination</b></h4>

            <p>We will determine, in our discretion, whether there has been a breach of this acceptable use policy
                through your use of our site. When a breach of this policy has occurred, we may take such action as we
                deem appropriate.</p>
            <p>Failure to comply with this acceptable use policy constitutes a material breach of our Terms and
                Conditions upon which you are permitted to use our site, and may result in our taking all or any of the
                following actions:</p>
            <ul className="small">
                <li>Immediate, temporary or permanent withdrawal of your right to use our site.</li>
                <li>Immediate, temporary or permanent removal of any posting or material uploaded by you to our site.
                </li>
                <li>Issue of a warning to you.</li>
                <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but
                    not limited to, reasonable administrative and legal costs) resulting from the breach.
                </li>
                <li>Further legal action against you.</li>
                <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.
                </li>
            </ul>
            <p>We exclude liability for actions taken in response to breaches of this acceptable use policy. The
                responses described in this policy are not limited, and we may take any other action we reasonably deem
                appropriate.</p>
            <h4><b>Changes to the acceptable use policy</b></h4>
            <p>We may revise this acceptable use policy at any time by amending this page. You are expected to check
                this page from time to time to take notice of any changes we make, as they are legally binding on you.
                Some of the provisions contained in this acceptable use policy may also be superseded by provisions or
                notices published elsewhere on our site.</p>
            <h4><b>Contact</b></h4>
            <p>If you have any questions regarding these Terms and Conditions, or if you have any questions, complaints,
                claims or other legal concerns relating to Website or its business, please contact the Seller’s
                support.</p>
        </div>
    </div>

export default TermsPage
