import _head from 'lodash/head';
import _get from 'lodash/get';

import couponService from '../services/validateCoupon';

import {
    COUPON_GET,
    COUPON_ADD,
    COUPON_ADD_ERROR,
    COUPON_REMOVE,
    CART_SHOULD_UPDATE_TOTALS,
    CART_ADD
} from '../actions/actionTypes';

const couponMiddleware = store => next => action => {

    next(action);

    if (action.type === COUPON_GET || action.type === CART_ADD || action.type === COUPON_REMOVE) {
        const state = store.getState();
        const channelId = state.common.channelId;
        const cart = _get(state, 'cart.byHash', []);
        const vendor = _get(state, 'vendor.id', undefined);
        let variants = [];

        for (let key in cart) {
            variants.push({
                variant: key,
                is_bundle: cart[key].content.is_bundle
            });
        }

        if (action.type === COUPON_GET) {
            const state = {
                'code': action.code,
                'channelId': channelId,
                'variants': variants
            };
            store.dispatch(
                addCoupon(vendor,state)
            ).then(() =>
                store.dispatch({ type: CART_SHOULD_UPDATE_TOTALS })
            );
        }

        if (action.type === CART_ADD) {
            const id = _head(store.getState().coupon.byId);

            if (id){
                const state = {
                    'id': id,
                    'variants': variants
                };
                store.dispatch(
                    validateCoupon(vendor,state)
                ).then(() =>
                    store.dispatch({ type: CART_SHOULD_UPDATE_TOTALS })
                );
            }
        }
        if (action.type === COUPON_REMOVE) {
            const state = {
                'channelId': channelId,
            };
            store.dispatch(
                removeCouponDiscount(vendor,state)
            ).then(() =>
                store.dispatch({ type: CART_SHOULD_UPDATE_TOTALS })
            );
        }
    }
};

function addCoupon(vendor,state) {
    return function (dispatch) {
        return couponService.create(vendor,state).then(
            data => dispatch({type: COUPON_ADD, id: data.id, payload: data}),
            error => dispatch({type: COUPON_ADD_ERROR, error})
        );
    };
}

function removeCouponDiscount(vendor,state) {
    return function () {
        return couponService.removeDiscount(vendor,state);
    };
}

function validateCoupon(vendor,state) {
    return function (dispatch) {
        return couponService.validate(vendor,state).then(
            data => dispatch({type: COUPON_ADD, id: data.id, payload: data}),
            error => {
            dispatch({type: COUPON_REMOVE, id: state.id});
            dispatch({type: COUPON_ADD_ERROR, error});
        }
        );
    };
}

export default couponMiddleware

