import supportedLanguages from './supportedLanguages';

const setupLanguage = (lang) => {
    let i18nConfig;

    switch (lang) {
        case 'zh-TW': 
            i18nConfig = supportedLanguages["zh-TW"];
            break;
        default:
            i18nConfig = {
                locale: "en"
            }
            break;
    }

    return i18nConfig;
}

export default setupLanguage;