import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import currenciesService from '../../../services/currencies';

class CountrySelector extends Component {

    transformCountries = (country) => (
        <FormattedMessage id={country.name.id} defaultMessage={country.name.defaultMessage} key={country.code}>
            {(name) => <option key={country.code} value={country.code}>{name}</option>}
        </FormattedMessage>
    );

    handleChange = (e) => {
        this.props.onChange('country', e.target.value);
    };

    render() {
        let selected = (this.props.value !== "")? "browser-default selected" : "browser-default";
        return (
                <div className="mdl-selectfield">
                    <label>Standard Select</label>
                    <select className={selected} value={this.props.value} onChange={this.handleChange} >
                            {
                                <FormattedMessage id="checkout.chooseCountry" defaultMessage="Choose your country">
                                    {(name) => <option key="" value="" disabled>{name}</option>}
                                </FormattedMessage>
                            }
                        {currenciesService.listCountries(this.transformCountries, false)}
                        <option key="3453434" value="null" disabled>-----------</option>
                        {currenciesService.listCountries(this.transformCountries, true)}
                    </select>
                </div>
        )
    }
}

CountrySelector.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CountrySelector