import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {Elements, StripeProvider} from 'react-stripe-elements';
import { FormattedMessage } from 'react-intl';

import ErrorBoundary from 'react-error-boundary';

import {StripeCardForm} from "./StripeCardForm";
import {StripePaymentRequest} from "./StripePaymentRequest";

import Paypal from "./Paypal";

import TextField from 'material-ui/TextField';

//Actions
import {
    CARD_UPDATE_INFO,
    UPDATE_STEP,
    CARD_ADD_ERROR,
    CART_PAY,
    CART_CONFIRM_PAYMENT,
    UNDEFINED_ERROR
} from '../../../actions/actionTypes';

const locale = new URL(window.location).searchParams.get('lang') || 'en';

const mapStateToProps = state => (state);

const mapDispatchToProps = dispatch => ({
    onHandleToken: (token, paymentType) => {
        dispatch({type: CARD_UPDATE_INFO, key: 'token', value: token, paymentType: paymentType});
        dispatch({type: UPDATE_STEP, key: 'card', value: true});
        dispatch({type: CART_PAY});
    },
    onConfirmPayment: () => {

        dispatch({type: CART_CONFIRM_PAYMENT});
    },
    onHandleCardError: (error) => {
        dispatch({type: CARD_ADD_ERROR, error: error});
    },
    onHandleUndefinedError: (error) => {
        dispatch({type: UNDEFINED_ERROR, error: error});
    }

});

class Payment extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            token: null,
            nameOnCard: null
        };
    }

    handleNameOnCard = (e, value) => this.setState({nameOnCard: value});

    handleToken = (token, paymentType) => {
        const newToken = token === null ? this.props.card.token : token;
        this.props.onHandleToken(newToken, paymentType);
    };

    handleConfirmPayment = () => {
        this.props.onConfirmPayment();
    };

    handleError = (error) => this.props.onHandleCardError(error);

    canPay = () => {
        const steps = this.props.common.stepReady;
        return steps.personal &&
            (this.props.common.isDigital || (!this.props.common.isDigital && this.props.common.needs_billing) ? steps.billingAddress : true) &&
            ((!this.props.common.isDigital) ? steps.shippingAddress && steps.shippingOptions : true)
    };

    isPaying = () => {
        return !!this.props.card.token;
    };

    stripeRequiresAction = () => {
        return false;
    };

    errorHandler = (error: Error, componentStack: string) => {
        this.props.onHandleUndefinedError(componentStack);
    };

    errorFallbackComponent = ({ componentStack, error }) => (
        <div/>
    );

    render() {
        const canPay = this.canPay();
        return (
            <div className="Checkout clear">
                <div className="paymentTitle">
                    <p className="subtitle">
                        <FormattedMessage
                            id="checkout.cardOptions"
                            defaultMessage="Review your order & Make Payment"
                        />
                    </p>
                </div>
                {canPay && <div>
                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE} stripeAccount={this.props.vendor.stripeId}>
                        <Fragment>
                            {/* Pay with apple / chrome disabled as it's not creating payment intents */}
                            {/*<ErrorBoundary FallbackComponent={this.errorFallbackComponent} onError={this.errorHandler}>*/}
                            {/*    <Elements>*/}
                            {/*        <StripePaymentRequest*/}
                            {/*            handleToken={this.handleToken}*/}
                            {/*            handleError={this.handleError}*/}
                            {/*            currency={this.props.personal.currency_code}*/}
                            {/*            country={this.props.personal.country}*/}
                            {/*            amount={this.props.cart.totals.total}*/}
                            {/*            canPay={this.canPay()}*/}
                            {/*        />*/}
                            {/*    </Elements>*/}
                            {/*</ErrorBoundary>*/}
                            <ErrorBoundary FallbackComponent={this.errorFallbackComponent} onError={this.errorHandler}>
                                <Paypal
                                    handleToken={this.handleToken}
                                    handleError={this.handleError}
                                    currency={this.props.personal.currency_code}
                                    amount={this.props.cart.totals.total}
                                    canPay={this.canPay()}
                                />
                            </ErrorBoundary>
                            <div className="paymentTitle">
                                <p className="subtitle">
                                    <FormattedMessage
                                        id="checkout.payByCard"
                                        defaultMessage="Pay by Card"
                                    />
                                </p>
                                <img className="cardsImg" alt=""
                                     src="https://s3.eu-west-2.amazonaws.com/assets.aflete.com/checkout/cards.png"/>
                            </div>
                            <TextField
                                name="nameOnCard"
                                ref="name"
                                id="stripe_card_name"
                                className="formLines"
                                defaultValue={this.state.nameOnCard}
                                hintText={
                                    <FormattedMessage
                                        id="checkout.nameOnCard"
                                        defaultMessage="Name as written on card"
                                    />
                                }
                                onChange={this.handleNameOnCard}
                            />
                            <Elements locale={locale}>
                                <StripeCardForm
                                    nameOnCard={this.state.nameOnCard}
                                    handleToken={this.handleToken}
                                    handleConfirmPayment={this.handleConfirmPayment}
                                    handleError={this.handleError}
                                    canPay={this.canPay()}
                                    isPaying={this.isPaying()}
                                    requiresAction={this.props.card.requiresAction}
                                    paymentIntentSecret={this.props.card.paymentIntentSecret}
                                />
                            </Elements>
                        </Fragment>
                    </StripeProvider>
                </div>
                }
                {!canPay && <div style={{margin: '0 auto', width: '100px'}}><br/></div>}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
