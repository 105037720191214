import {
    PERSONAL_SET_COUNTRY,
    PERSONAL_SET_CURRENCY,
    PERSONAL_UPDATE_INFO,
    CART_CLEAN
} from '../actions/actionTypes';

import { types as AuthTypes } from '../Modules/auth';

import localStorageService from '../services/localStorageService';

const localCurrency = localStorageService.getCurrency();
const localCountry = localStorageService.getCountry();

const defaultState = {
    first_name: '',
    last_name: '',
    email: '',
    subscribe: '',
    country: localCountry || 'GB',
    currency_code: localCurrency || 'GBP',
    currency_forced: false
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case PERSONAL_SET_COUNTRY:
            return { ...state, country: action.payload};
        case PERSONAL_SET_CURRENCY:
            return {
                ...state,
                currency_code: action.currency_code,
                currency_forced: action.forced,
            };
        case PERSONAL_UPDATE_INFO:
            return { ...state, [action.key]: action.value };
        case AuthTypes.SIGNUP_DATA:
            return { ...state, ...action.payload };
        case AuthTypes.LOGIN_USERNAME_PASSWORD: //manual login, better we remove previous register data
            {
            return {
                ...state,
            first_name: '',
            last_name: '',
            email: '',
        };
        }
        case CART_CLEAN:
            return {
                ...state,
            country: localCountry || 'GB',
            currency_code: localCurrency || 'GBP',
            currency_forced: false
            };
        default:
            return state;
    }
};
