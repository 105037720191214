import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import store from "../../store";

import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

import Currency from "react-currency-formatter";

//Lodash
import _map from "lodash/map";
import _filter from "lodash/filter";
import _findIndex from "lodash/findIndex";
import _isEmpty from "lodash/isEmpty";
// import { max } from "lodash";

const specialCountries = ["US", "CA"]; // countries with special shipping rules

const getOptions = ({ availableOptions, fetchedShippingZones }, cart) => {
  const { country, state } = store.getState().address.shipping;

  const { weight } = cart.totals;

  if (specialCountries.includes(country)) {
    if (fetchedShippingZones?.countries?.length) {
      const countryZone = fetchedShippingZones.countries.filter((item) => item.code === country)[0] || null;

      if (countryZone) {
        let newOptions = [];
        const countryOptions = fetchedShippingZones.options.filter((item) => countryZone.options.includes(item.id));

        countryOptions.map((item) => {
          if (!Array.isArray(item.rules)) return; //  item.rules can be an Array or an object. Discard if object

          const ruleWeight = item.rules.filter((value) => value.type === "weight_range");

          const a = ruleWeight.filter((i) => {
            const { maxWeight, minWeight } = i.conditions;

            if (maxWeight >= weight && minWeight <= weight) {
              return true;
            }
            return;
          });

          if (!a.length) return;

          const ruleStates = item.rules.filter((value) => value.type === "states");

          if (!ruleStates.length) {
            // old shipping process -> without states rule
            newOptions.push(item);
          }

          if (ruleStates.length === 1) {
            if (!_get(ruleStates, "[0].conditions.states.length") || !_get(ruleStates, "[0].conditions.country")) {
              newOptions.push(item);
            }
          }

          ruleStates.map((i) => {
            if (i.conditions.states.includes(state)) {
              newOptions.push(item);
            }
          });
        });

        return newOptions;
      }

      return availableOptions;
    } else {
      return availableOptions;
    }
  } else {
    return availableOptions;
  }
};

const labelRadioOption = (name, price, currency) => {
  return (
    <span>
      {name} - <Currency quantity={price / 100} currency={currency} />
    </span>
  );
};

const createRadioOption = (item, index, currency, forceChangeCurrency) => {
  let priceIndex = _findIndex(item.prices, ["currency_code", currency]);

  // set default to GBP
  if (priceIndex < 0 || !item.prices[priceIndex]) {
    return false;
  }

  return (
    <RadioButton
      key={index}
      value={item.id}
      label={labelRadioOption(item.name, item.prices[priceIndex].price, currency)}
      style={{ marginBottom: 16 }}
    />
  );
};

const ShippingOption = ({ onSelect, currency, shippingOptions, selected, cart }) => {
  const options = getOptions(shippingOptions, cart);
  const { country, state } = store.getState().address.shipping;

  if (specialCountries.includes(country) && !state) {
    return null;
  }

  return (
    <div className="shippingOption">
      {options && (
        <RadioButtonGroup name="zoneOptions" valueSelected={selected} onChange={(e, v) => onSelect(v)}>
          {_filter(_map(options, (item, index) => createRadioOption(item, index, currency, null)))}
        </RadioButtonGroup>
      )}
      {_isEmpty(options) && <b>Unfortunately, we currently do not ship to your region</b>}
    </div>
  );
};

ShippingOption.propTypes = {
  currency: PropTypes.string.isRequired,
  shippingOptions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
};

export default ShippingOption;
