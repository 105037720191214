import React, {Component} from 'react';

//Lodash
import _head from 'lodash/head';

//High Order Objects
import {connect} from 'react-redux';
import muiThemeable from 'material-ui/styles/muiThemeable';

import Coupon from '../presentational/coupon';

//Actions
import {
    COUPON_GET,
    COUPON_REMOVE
} from '../../actions/actionTypes';

const mapStateToProps = state => {
    return {
        coupon: state.coupon,
        coupon_code: state.common.coupon_code,
    }
};

const mapDispatchToProps = dispatch => ({
    onCouponAdd: code => {
        dispatch({type: COUPON_GET, code: code});
    },
    onCouponRemove: id => {
        dispatch({type: COUPON_REMOVE, id: id});
    }
});

class Coupons extends Component {
    constructor(props, context) {
        super(props, context);
        this.getStyles = () => {
            return this.props.muiTheme.product;
        };

        this.state = {
            code: null
        };

        this.getCoupon = () => {
            let id = _head(this.props.coupon.byId);
            if (id) {
                return this.props.coupon.byHash[id].content;
            } else {
                return null;
            }
        };
    }

    handleSendCode = (name, value) => this.props.onCouponAdd(this.state.code);
    handleRemoveCode = (name, value) => this.props.onCouponRemove(_head(this.props.coupon.byId));
    handleCode = (name, value) => this.setState({code: value});

    render() {
        const styles = this.getStyles();
        const coupon = this.getCoupon();
        return (
            <Coupon handleSendCode={this.handleSendCode}
                    handleCode={this.handleCode}
                    handleRemoveCode={this.handleRemoveCode}
                    styles={styles}
                    coupon={coupon}
                    error={this.props.coupon.error}
            />
        )
    }
}

export default muiThemeable()(connect(mapStateToProps, mapDispatchToProps)(Coupons));