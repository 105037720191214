import React from 'react'

const NotFoundPage = () =>

    <div className="thank-you">{``}
        <div className="mainDesktop__container">
            <div className="error-title"><h1>404</h1></div>
            <h3>Page not found..</h3>
        </div>
    </div>

export default NotFoundPage
