import _findIndex from "lodash/findIndex";
import _get from "lodash/get";
import _head from "lodash/head";
import _find from "lodash/find";

import equal from "deep-equal";

import {
  CART_SET_TOTALS,
  UPDATE_STEP,
  TAXES_CALCULATED,
  CART_SHOULD_UPDATE_TOTALS,
  ADDRESS_UPDATE_INFO,
} from "../actions/actionTypes";

import Currencies from "../services/currencies";
import calculateTaxes from "../services/calculateTaxes";
import _round from "lodash/round";
import _reduce from "lodash/reduce";

const taxesMiddleware = (store) => (next) => (action) => {
  next(action);

  let actions = [UPDATE_STEP];

  let shouldAct = _findIndex(actions, function (o) {
    return o === action.type;
  });

  if (shouldAct >= 0) {
    const state = store.getState();

    if (
      !(action.type === UPDATE_STEP && action.key === "taxTotal") &&
      ((!state.common.isDigital && state.common.stepReady.shippingAddress) ||
        (state.common.isDigital && state.common.stepReady.billingAddress)) &&
      ((!state.common.isDigital && state.common.stepReady.shippingOptions) || state.common.isDigital) &&
      (state.vendor.taxes && state.vendor.taxes.module ? state.vendor.taxes.module : false) &&
      !state.taxes.validTaxes
    ) {
      calculateTaxes
        .calculate(state)
        .then((data) => {
          console.log(data);

          store.dispatch({ type: TAXES_CALCULATED, totalTaxes: data.taxAmount });
          store.dispatch({ type: UPDATE_STEP, key: "taxTotal", value: true });
          store.dispatch({ type: CART_SHOULD_UPDATE_TOTALS });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  if (action.type === ADDRESS_UPDATE_INFO) {
  }
};

export default taxesMiddleware;
